import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { debounce } from "throttle-debounce";   
import shortid from 'shortid';
import api from 'api';
import Util from 'utility';
import Loading from 'components/Loading/index';

import { 
    openModal, 
    closeModal,
    showMessage,
    addFood,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        addFood: data => dispatch(addFood(data)),
    };
};

class FoodSearch extends Component {
    constructor(props) {
        super(props);

        let initialSources = ["0"];
        if(props.match.params.action == 'me') initialSources = ["1"];

        let initialFilter = {
            q: '', 
            sources: initialSources,
            category: [], 
            selection: 0,
            sortBy: '',
            sortOrder: '',
            lastSortIsCustom: false,
            customSortAttribute: '',
            viewMode: 'list',
            nutritionCalcMode: 'serving',
        };

        let fsLocal = localStorage.getItem('zof_foodsearch_settings');
        if(fsLocal) {
            fsLocal = JSON.parse(fsLocal);
            if(typeof fsLocal === 'object') {
                if(typeof fsLocal.q !== 'undefined') initialFilter.q = fsLocal.q;
                if(typeof fsLocal.sources !== 'undefined') initialFilter.sources = fsLocal.sources;
                if(typeof fsLocal.category !== 'undefined') initialFilter.category = fsLocal.category;
                if(typeof fsLocal.sortBy !== 'undefined') initialFilter.sortBy = fsLocal.sortBy;
                if(typeof fsLocal.sortOrder !== 'undefined') initialFilter.sortOrder = fsLocal.sortOrder;
                if(typeof fsLocal.lastSortIsCustom !== 'undefined') initialFilter.lastSortIsCustom = fsLocal.lastSortIsCustom;
                if(typeof fsLocal.customSortAttribute !== 'undefined') initialFilter.customSortAttribute = fsLocal.customSortAttribute;
                if(typeof fsLocal.viewMode !== 'undefined') initialFilter.viewMode = fsLocal.viewMode;
                if(typeof fsLocal.nutritionCalcMode !== 'undefined') initialFilter.nutritionCalcMode = fsLocal.nutritionCalcMode;
            }
        }

        /*
        if(props.isDrinkRegime) {
            initialFilter.category = [14];
        }
        */

        this.state = {
            filter: initialFilter,
            total: 0,
            page: 0,
            perPage: 30,
            foods: {},
            selectedFoodIds: [],
            addedFoodIds: [],
        }
        this.updateFilter = this.updateFilter.bind(this);
        this.updateSort = this.updateSort.bind(this);
        this.setViewMode = this.setViewMode.bind(this);
        this.setNutritionCalcMode = this.setNutritionCalcMode.bind(this);
        this.changePage = this.changePage.bind(this);
        this.loadFoods = this.loadFoods.bind(this);
        this.selectFood = this.selectFood.bind(this);
        this.loadFoodsDebounced = debounce(250, this.loadFoods);
        this.handleFoodSelect = this.handleFoodSelect.bind(this);
        this.setSelectedAsFavorite = this.setSelectedAsFavorite.bind(this);
        this.setSelectedAsUndesirable = this.setSelectedAsUndesirable.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.createDuplicate = this.createDuplicate.bind(this);
        this.saveLocalSettings = this.saveLocalSettings.bind(this);
        this.addToRecipe = this.addToRecipe.bind(this);
    }

    componentDidMount() {
        this.loadFoods();
    }

    loadFoods() {
        let profileId = Util.getActiveProfileId();

        if(typeof this.props.data.appModeData !== 'undefined') {
            if(typeof this.props.data.appModeData['profileId'] !== 'undefined') {
                profileId = this.props.data.appModeData['profileId'];
            }
        }

        api('foods2/list', { post: true, data: { page: this.state.page, filter: this.state.filter, profileId: profileId }}).then((res) =>{
            this.setState({
                total: res.data.total,
                foods: res.data.foods,
                loading: false,
            });
        });
    }

    selectFood(e, food) {
        let sf = this.state.selectedFoodIds;
        sf = sf.filter(id => id !== food.id);
        if(e.target.checked) sf.push(food.id);
        this.setState({
            selectedFoodIds: sf
        });
    }

    setSelectedAsFavorite() {
        const profileId = Util.getFirstProfileId();
        let that = this;
        api('foods2/mass-set-favorite', { post: true, data: { profile_id: profileId, ids: this.state.selectedFoodIds.join(',') }}).then((res) =>{
            that.loadFoods();
        });
    }

    setSelectedAsUndesirable() {
        const profileId = Util.getFirstProfileId();
        let that = this;
        api('foods2/mass-set-undesirable', { post: true, data: { profile_id: profileId, ids: this.state.selectedFoodIds.join(',') }}).then((res) =>{
            that.loadFoods();
        });
    }

    selectAll() {
        let ids = [];
        this.state.foods.map(f => {
            ids.push(f.id);
        });
        this.setState({
            selectedFoodIds: ids
        });
    }

    selectNone() {
        this.setState({
            selectedFoodIds: []
        });
    }

    createDuplicate(e, id) {
        api('myfoods/duplicate/' + id, {post: true, data: {id: id}}).then(res => {
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Duplikát byl vytvořen.'
            });                           
            this.loadFoods();
        });
    }

    updateFilter(e) {
        let filter = this.state.filter;
        let page = this.state.page;
        const v = e.target.value;
        switch(e.target.name) {
            case 'q':
                filter.q = e.target.value;
                break;
            case 's':
                filter.sources = [v];
                page = 0;

                if(typeof this.props.isEmbedded === 'undefined') {
                    if(v == 0) {
                        this.props.history.push('/food');
                    } else {
                        this.props.history.push('/food/me');
                    }
                }

                /*
                if(filter.sources.includes(v)) {
                    filter.sources = filter.sources.filter(item => item !== v);                    
                } else {
                    filter.sources.push(v);
                }
                */
                break;        
            case 'sel':
                filter.selection = v;
                page = 0;
                break;        
            case 'c':
                if(filter.category.includes(v)) {
                    filter.category = filter.category.filter(item => item !== v);                    
                } else {
                    filter.category.push(v);
                }
                page = 0;
                break;
            default:
                break;
        }


        this.saveLocalSettings(filter);

        this.setState({
            filter: filter,
            page: page
        }, () => {
            this.loadFoodsDebounced();
        });
    }

    updateSort(e) {
        let filter = this.state.filter;
        let newSortBy = e.currentTarget.getAttribute('data-sort');
        if(newSortBy == 'custom') {
            if(e.currentTarget.getAttribute('data-change-order-only') == '1') {
                newSortBy = filter.sortBy;
                filter.sortOrder = filter.sortOrder == 'asc' ? 'desc' : 'asc';
            } else {
                newSortBy = e.target.value;
                filter.customSortAttribute = newSortBy;
                filter.sortBy = newSortBy;
                filter.sortOrder = 'desc';
            }
            filter.lastSortIsCustom = true;
        } else {
            filter.lastSortIsCustom = false;
            if(newSortBy == filter.sortBy) {
                switch(filter.sortOrder) {
                    case 'asc':
                        filter.sortOrder = 'desc';
                        break;
                    case 'desc':
                        filter.sortOrder = '';
                        filter.sortBy = '';
                        break;
                    default:
                        break;
                }
            } else {
                filter.sortBy = newSortBy;
                filter.sortOrder = 'asc';
            }
        }

        this.saveLocalSettings(filter);

        this.setState({
            page: 0,
            filter: filter
        }, () => {
            this.loadFoodsDebounced();
        });
    }

    saveLocalSettings(filter) {
        localStorage.setItem('zof_foodsearch_settings', JSON.stringify(filter));
    }

    setViewMode(mode) {
        let filter = this.state.filter;
        filter.viewMode = mode;
        this.setState({
            filter: filter
        });
    }

    setNutritionCalcMode(mode) {
        let filter = this.state.filter;
        filter.nutritionCalcMode = mode;
        this.setState({
            filter: filter
        }, () => {
            this.loadFoodsDebounced();
        });
    }

    changePage(e, page) {
        this.setState({
            loading: true,
            page: page,
            changingPageOnly: true
        }, () => {
            this.loadFoods();
        });
    }

    handleFoodSelect(e, food) {
        const name = e.target.name;
        let url = name == 'favorite' ? 'food/set-favorite' : 'food/set-undesirable';
        const state = e.target.checked ? 1 : 0;

        let foods = this.state.foods;
        foods.map(f => {
            if(f.id == food.id) {
                if(state == 0) {
                    f.u = 0;
                } else {
                    if(name == 'favorite') {
                        f.u = 3;
                    } else {
                        f.u = 2;
                    }
                }
            }
            return f;
        });

        this.setState({
            foods: foods
        }, () => {
            api(url + '?state=' + state + '&id='+ food.id + '&profileId=' + Util.getActiveProfileId()).then((result) =>{
            });
        });
    }

    addToRecipe(foodId, groupId) {
        let img_src;
        if(this.props.data.globals.myFoodIds.includes(foodId)) {
            img_src = 'https://www.zofapp.cz/data/zof/item/' + foodId + '.svg?r=' + Math.random();
        } else {
            img_src = 'https://www.zofapp.cz/data/zof/item/' + foodId + '.svg';
        }

        if(typeof groupId === 'undefined') groupId = '';
        let food = {
            id: shortid(),
            food_id: foodId,
            group_id: groupId,
            image: img_src,
            title: this.props.data.globals.itemData[foodId]['title'],
            weight: this.props.data.globals.itemData[foodId]['weight'],
            is_drink: 0
        };
        this.props.addFood(food);

        let addedIds = this.state.addedFoodIds;
        addedIds.push(foodId);
        this.setState({
            addedFoodIds: addedIds
        });

        this.props.showMessage({
            show: true,
            type: 'success',
            message: 'Potravina vložena.'
        });               

        Util.doLoadEvaluation();
    }

    render() {
        const { state } = this;
        const { globals } = this.props.data;
        const { filter, foods } = state;

        let sortSymbols = {};
        if(filter.sortBy != '') {
            if(filter.sortOrder == 'asc') {
                sortSymbols[filter.sortBy] = <i className="fa fa-angle-up"></i>;
            } else {
                sortSymbols[filter.sortBy] = <i className="fa fa-angle-down"></i>;
            }
        }

        let sortSymbolCustom = false;
        if(filter.lastSortIsCustom) {
            if(filter.sortOrder == 'asc') {
                sortSymbolCustom = <a style={{paddingLeft:'.5em'}} onClick={this.updateSort} data-sort="custom" data-change-order-only="1"><i className="fa fa-angle-up"></i></a>;
            } else {
                sortSymbolCustom = <a style={{paddingLeft:'.5em'}} onClick={this.updateSort} data-sort="custom" data-change-order-only="1"><i className="fa fa-angle-down"></i></a>;
            }
        }

        let sortIgnore = [
            'weight', 'weight_total', 'amount', 'drink_amount', 'energy_output_kcal', 'energy_output_kj',
            'proteins', 'carbohydrates', 'fats', 'fats_omega3_dha_epa', 'energy_kcal', 'energy_kj', 'energy_balance_kcal', 'energy_balance_kj',
            'proteins_ratio', 'fats_saturated_unsaturated_ratio', 'fats_monounsaturated_polyunsaturated_ratio', 'fats_omega3_omega6_ratio',
            'fiber_ratio', 'calcium_magnesium_ratio', 'potassium_sodium_ratio', 'biotin',
            // 'energy', 'digestibility', 'acidobasicity', 'thermic_effect', 'biotin',
        ];
        let customSortOptions = [];
        customSortOptions.push(
            <option value={''} key={''}>Další...</option>
        );
        for(let attr in globals.attributes_sorted) {
            if(sortIgnore.includes(attr)) continue;
            customSortOptions.push(
                <option value={attr} key={attr}>{globals.attributes[attr]}</option>
            );
        }

        let foodsListHeader = (
            <div className="recipes-header">
                <div className="recipe-item">
                    <div className="check">
                        {'\u00A0'}
                    </div>
                    <div className="picture">
                        Foto
                    </div>
                    <div className="title">
                        <h3>Název</h3>
                    </div>
                    <div className="recipe-nutrition-data">
                        <div>Množství</div>
                        <div><a onClick={this.updateSort} data-sort="energy_kcal">Kalorie {sortSymbols['energy_kcal'] || false}</a></div>
                        <div><a onClick={this.updateSort} data-sort="proteins">Bílkoviny {sortSymbols['proteins'] || false}</a></div>
                        <div><a onClick={this.updateSort} data-sort="carbohydrates">Sacharidy {sortSymbols['carbohydrates'] || false}</a></div>
                        <div><a onClick={this.updateSort} data-sort="fats">Tuky {sortSymbols['fats'] || false}</a></div>
                        <div>
                            <select name="custom_sort" onChange={this.updateSort} data-sort="custom" style={{fontSize:'11px',width:'6em'}} value={filter.customSortAttribute}>
                                {customSortOptions}
                            </select>
                            {sortSymbolCustom}
                        </div>
                    </div>
                </div>
            </div>
        );

        let foodsList = [];
        if(foods.length > 0) {
            Object.keys(foods).map(key => {
                const food = foods[key];

                let nutrData = [];
                if(food.nutritionData != '') {

                    let customValue = parseFloat(food[filter.customSortAttribute]).toFixed(2);
                    let customValueUnit = globals.attribute_units[filter.customSortAttribute]                        
                    if(isNaN(customValue)) {
                        customValue = '';
                        customValueUnit = '';
                    }

                    if(['digestibility','acidobasicity','energy','thermic_effect'].includes(filter.customSortAttribute)) {
                        let ta = filter.customSortAttribute;
                        if(ta == 'energy') ta = 'energytendency';
                        if(ta == 'thermic_effect') ta = 'thermiceffect';

                        let keys = Object.keys(globals.evaluationTexts[ta]).map(a => a*1);
                        const limitMin = Math.min(...keys);
                        const limitMax = Math.max(...keys);

                        let fval = Math.round(customValue);
                        if(fval < limitMin) fval = limitMin;
                        if(fval > limitMax) fval = limitMax;

                        let cv = globals.evaluationTexts[ta][fval];
                        customValue = cv;
                    }

                    if(food.weight_unit == 'mg') {
                        //food.weight /= 1000;
                    }

                    if(food.weight_unit == 'mcg') {
                        //food.weight /= 1000000;
                    }

                    food.weight = parseFloat(food.weight);

                    nutrData.push(<div>{food.weight} {food.weight_unit}</div>);
                    nutrData.push(<div>{Math.ceil(food.energy_kcal)} {globals.attribute_units['energy_kcal']}</div>);
                    nutrData.push(<div>{parseFloat(food.proteins).toFixed(2)} {globals.attribute_units['proteins']}</div>);
                    nutrData.push(<div>{parseFloat(food.carbohydrates).toFixed(2)} {globals.attribute_units['carbohydrates']}</div>);
                    nutrData.push(<div>{parseFloat(food.fats).toFixed(2)} {globals.attribute_units['fats']}</div>);
                    nutrData.push(<div>{customValue} {customValueUnit}</div>);
                }

                let foodOptions = false;
                if(typeof this.props.isEmbedded === 'undefined') {
                    foodOptions = (
                        <p className="recipe-options">
                            <input type="checkbox" checked={food.u == 3} name="favorite" value={food.id} id={`fav_${food.id}`} onChange={(e) => this.handleFoodSelect(e, food)} />
                            <label title="Oblíbená potravina" className="label-favorite" htmlFor={`fav_${food.id}`}>
                                <i className="fa fa-heart"></i>
                            </label>
                            <input type="checkbox" checked={food.u == 2 || food.u == 1} name="undesirable" value={food.id} id={`und_${food.id}`} onChange={(e) => this.handleFoodSelect(e, food)} />
                            <label title="Nežádoucí potravina" className="label-undesirable" htmlFor={`und_${food.id}`}>
                                <i className="fa fa-ban"></i>
                            </label>
                            <Link title="Vytvořit kopii" to={`/myfood/copy/${food.id}`}><i className="fa fa-plus"></i></Link>
                        </p>
                    );
                    if(food.ownership == 'user') {
                        foodOptions = (
                            <p className="recipe-options">
                                <input type="checkbox" checked={food.u == 3} name="favorite" value={food.id} id={`fav_${food.id}`} onChange={(e) => this.handleFoodSelect(e, food)} />
                                <a style={{textDecoration:'underline'}} onClick={(e) => this.createDuplicate(e, food.id)}>duplikovat</a>
                                <Link to={`/myfood/edit/${food.id}`} className="primary">upravit</Link>
                                <Link to={`/myfood/delete/${food.id}`}>smazat</Link>
                            </p>
                        );    
                    }
                }

                // Is this food undesirable for lecture task profile?

                let undesirableFoodForProfiles = [];
                for(let profileId in this.props.data.globals.undesirableFoods) {
                    profileId = profileId * 1;
                    // if(!mealProfiles.includes(profileId)) continue;
                    let arr = this.props.data.globals.undesirableFoods[profileId];
                    if(arr.includes(food.id)) {
                        undesirableFoodForProfiles.push(profileId);
                    }
                }

                let undesirableInfo = false;
                if(false && typeof this.props.data.appModeData !== 'undefined') {
                    if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                        if(this.props.data.appModeData.undesirableFoods.includes(food.id)) {
                            for(let profileId in this.props.data.globals.undesirableFoods) {
                                profileId = profileId * 1;
                                undesirableInfo = <p className="item-info-undesirable"><span>Nevhodná potravina</span></p>;
                            }
                        }
                    }
                }


                let undesirableFoodWarning = false;
                undesirableFoodForProfiles.map(profileId => {
        
                    let profile = this.props.data.globals.profiles[profileId];
                    let profileColor = profile.profile_color;
                    if (profileColor === '' || !profileColor) profileColor = '999999';
                    let profileStyle = {
                        backgroundColor: '#' + profileColor,
                        color: '#fff'
                    };
                    //undesirableFoodWarning.push(
                    //    <span key={profile.name} style={profileStyle} className="undesirable-food-warning-profile">{profile.name}</span>
                    //);

                    undesirableFoodWarning = <span style={{backgroundColor:'#f00',color:'#fff',fontSize:'.8em',padding:'2px'}}>Nevhodná potravina</span>;
                });

                // Alergen info

                let alergenInfo = [];
                let alergensOk = [];
                let alergensForbidden = [];

                let watchAlergens = [];
                if(typeof this.props.data.appModeData.watchAlergens === 'object') {
                    watchAlergens = this.props.data.appModeData.watchAlergens;
                }
                watchAlergens = watchAlergens.map(a => +a);


                let alergenList = [];
                
                if(typeof this.props.data.globals.foodAlergens[food.id] === 'object') {

                    let alSorted = this.props.data.globals.foodAlergens[food.id].sort();

                    alSorted.map(id => {
                        if(watchAlergens.includes(id*1)) {
                            alergenList.push(<b style={{color:'#f00'}}>{this.props.data.globals.itemAlergens[id]}</b>);
                            alergenList.push(<span>, </span>);
                        } else {
                            alergenList.push(<span>{this.props.data.globals.itemAlergens[id]}</span>);
                            alergenList.push(<span>, </span>);
                        }
                    });
                }
                if(alergenList.length > 1) {

                    alergenList.pop();

                    /*
                    if (undesirableInfo !== false) {
                        alergenInfo = <p style={{color:'#f00',fontWeight:'bold',fontSize:'.8em',margin:'7px 0 0'}}>Alergeny: {alergenInfo.join(', ')}</p>;
                    } else {
                        alergenInfo = <p style={{fontSize:'.8em',margin:'7px 0 0'}}>Alergeny: {alergenInfo.join(', ')}</p>;
                    }
                    */
                    alergenInfo = <p style={{fontSize:'.8em',margin:'7px 0 0'}}>Alergeny: {alergenList}</p>;
                }


                let foodImage = `https://www.zofapp.cz/data/zof/item/${food.id}.svg`;
                if(typeof food.custom_photo !== 'undefined' && food.custom_photo == '1') {
                    foodImage = `https://www.zofapp.cz/data/hra/icon/png/${food.id}.png`;
                }

                // <p className="recipe-ownership-info"><span>{food.ownership == 'user' ? 'Moje potravina' : 'ZOF potravina'}</span></p>

                let foodCheckBox = (
                    <div className="check">
                        <input type="checkbox" checked={state.selectedFoodIds.includes(food.id)} onClick={(e) => this.selectFood(e, food)} />
                    </div>
                );

                if(typeof this.props.isEmbedded !== 'undefined') foodCheckBox = false;

                let addToRecipeButton = false;
                if(typeof this.props.groupId === 'string') {
                    if(state.addedFoodIds.includes(food.id)) {
                        addToRecipeButton = <a onClick={() => this.addToRecipe(food.id, this.props.groupId  )} className="recipe-add-to-recipe-btn"><i className="fa fa-plus"></i><span><i className="fa fa-check"></i></span></a>;
                    } else {
                        addToRecipeButton = <a onClick={() => this.addToRecipe(food.id, this.props.groupId  )} className="recipe-add-to-recipe-btn"><i className="fa fa-plus"></i></a>;
                    }
                }

                let foodTitle = <h3>{food.title}</h3>;
                if(food.has_description === 1) {
                    foodTitle = <h3 style={{textDecoration:'underline',cursor:'pointer'}} onClick={(e) => this.props.onClick(food.id, e)}>{food.title}</h3>;
                }

                foodsList.push(
                    <div key={food.id} className="recipe-item">
                        {foodCheckBox}
                        <div className="picture" style={{ backgroundSize:'contain', backgroundImage: `url(${foodImage})` }}>
                        </div>
                        <div className="title">
                            {foodTitle}
                            <p className="recipe-ownership-info"><span>{food.category_title}</span></p>
                            {alergenInfo}
                            {foodOptions}
                            {undesirableInfo}
                            {undesirableFoodWarning}
                        </div>
                        {addToRecipeButton}
                        <div className="recipe-nutrition-data">
                            {nutrData}
                        </div>
                    </div>
                );
            });
        }

        let pages = [];
        let numPages = Math.ceil(this.state.total / this.state.perPage);
        let pageMin = state.page - 5;
        let pageMax = state.page + 5;
        if(pageMin < 0) pageMin = 0;
        if(pageMax > numPages) pageMax = numPages;
        for(let p=pageMin; p<pageMax; p++) {
            if(p == this.state.page) {
                pages.push(
                    <strong>{(p+1)}</strong>
                );
            } else {
                pages.push(
                    <a onClick={(e) => this.changePage(e, p)}>{(p+1)}</a>
                );
            }
        }

        if(pageMin > 1) {
            pages = (
                <>
                <a onClick={(e) => this.changePage(e, 0)}>1</a>
                <span>...</span>
                {pages}
                </>
            )
        }

        if(pageMax < numPages) {
            pages = (
                <>
                {pages}
                <span>...</span>
                <a onClick={(e) => this.changePage(e, numPages-1)}>{numPages}</a>
                </>
            )
        }

        /*
        let filter1 = (
            <span className="button-list">
                Zobrazení:
                <a className={'' + filter.viewMode == 'list' ? 'active' : ''} onClick={() => this.setViewMode('list')}><i className="fa fa-list"></i></a>
                <a className={'' + filter.viewMode == 'gallery' ? 'active' : ''} onClick={() => this.setViewMode('gallery')}><i className="fa fa-th"></i></a>
            </span>
        );
        */

        let filter1 = false;

        let filter2 = (
            <span className="button-list">
                Nutriční hodnoty:
                <a className={'' + filter.nutritionCalcMode == 'serving' ? 'active' : ''} onClick={() => this.setNutritionCalcMode('serving')}>porce</a>
                <a className={'' + filter.nutritionCalcMode == '100g' ? 'active' : ''} onClick={() => this.setNutritionCalcMode('100g')}>100 g</a>
            </span>
        );

        if(filter.viewMode == 'gallery') filter2 = false;


        let selectedFoodsOptions = false;
        if(state.selectedFoodIds.length > 0) {
            selectedFoodsOptions = (
                <>
                Nastavit označené jako: 
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.setSelectedAsFavorite}>Oblíbené</a>
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.setSelectedAsUndesirable}>Nežádoucí</a>
                </>
            );
        }

        let selectedFoodsSelectionOptions = (
            <p>Vybrat: 
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.selectAll}>Vše</a>
                <a style={{marginLeft:'.5em',display:'inline-block',padding:'.3em .7em',background:'#888',color:'#fff',borderRadius:'3px'}} onClick={this.selectNone}>Nic</a>
                {'\u00A0'}
                {selectedFoodsOptions}
            </p>
        );


        if(typeof this.props.isEmbedded !== 'undefined') {
            selectedFoodsSelectionOptions = false;
        }

        if(this.state.loading) foodsList = <Loading />;

        let filterSelection = (
            <>
            <div class="filters-advanced-row">
                <p>Výběr</p>
                <div className="filters-advanced-item">
                    {Object.keys(globals.foodFilters.selection).map(key => {
                        const selectionTitle = globals.foodFilters.selection[key];
                        return <label key={key}><input type="radio" name="sel" value={key} checked={filter.selection == key} onChange={this.updateFilter} /><span></span> {selectionTitle}</label>
                    })}
                </div>
            </div>
            </>
        );

        let foodTabs = (
            <div className="page-tabs">
                {Object.keys(globals.foodFilters.sources).map(key => {
                    return <label key={key} className={'' + (filter.sources.includes(key) ? 'active' : '')}><input type="checkbox" name="s" value={key} onChange={this.updateFilter} defaultChecked={filter.sources.includes(key)} /><span></span> {globals.foodFilters.sources[key]}</label>
                })}
            </div>
        );


        let headerContent = (
            <div className="page-header">
                <h1>Potraviny</h1>
            </div>
        );


        if(this.props.data.appMode == 'LECTURE') {
            filterSelection = false;
            foodTabs = false;
            headerContent = false;
        }

        let content = (
            <div className="page page-recipes">
                {headerContent}
                {foodTabs}
                <div className="page-content page-content-noborder">
                    <div class="container">
                        <input type="checkbox" id="cb-filters-advanced" />
                        <div className="filters" style={{background:'#fff',paddingBottom:0}}>
                            <div className="box">
                                <div className="search-input with-button">
                                    <i className="fa fa-search"></i>
                                    <input className="search" type="text" name="q" placeholder="Hledat v potravinách..." value={filter.q} onChange={this.updateFilter} />
                                    <label htmlFor="cb-filters-advanced"><i className="fa fa-cog"></i> filtr</label>
                                </div>
                            </div>
                        </div>
                        <div className="filters-advanced"><div style={{padding:'1em'}}>
                            <div class="filters-advanced-row">
                                <p>Kategorie</p>
                                <div className="filters-advanced-item">
                                    {Object.keys(globals.foodFilters.categories).map(key => {
                                        const category = globals.foodFilters.categories[key];
                                        return <label key={key}><input type="checkbox" defaultChecked={filter.category.includes('' + category.id)} name="c" value={category.id} onChange={this.updateFilter} /><span></span> {category.title}</label>
                                    })}
                                </div>
                            </div>
                            {filterSelection}
                            <div className="filters-inline">
                                {filter1}
                                {filter2}
                            </div>                                
                        </div></div>
                        <div className="panel list">
                            <div className="box">
                                {foodsListHeader}
                                {foodsList}
                                {selectedFoodsSelectionOptions}
                                <div className="pages">{pages}</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );

        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FoodSearch));
