import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility.js';

import { 
    addMeal, 
    addTraining,
    setLectureTaskData,
    setProfileData
} from 'actions/index';


const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        addMeal: data => dispatch(addMeal(data)),
        addTraining: data => dispatch(addTraining(data)),
        setLectureTaskData: data => dispatch(setLectureTaskData(data)),
        setProfileData: data => dispatch(setProfileData(data)),
    };
};

class LectureTaskLoad extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lectureInfo: false
        };    
        this.initTask = this.initTask.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Úkol');
        const id = this.props.match.params.id;
        const lectureId = this.props.data.appModeData.lectureId;
        api('lecturetask/info/' + lectureId).then(result => {
            if(result.data.profile !== false) {
                this.props.setProfileData({
                    id: result.data.profile.id,
                    data: result.data.profile
                });
            }
            this.setState({
                lectureInfo: result.data,
                loading: false
            }, () => {
                this.initTask();

                api("lecturetask/load/" + id, { post: true, data:{lectureId:lectureId} }).then((res) => {
                    this.props.history.push('/plan');        
                });        
        

            });
            // this.props.setLectureTaskData(result.data);
        });
    }

    initTask() {
        const data = this.state.lectureInfo.lecture_data;

        // Prepare meals, if they are a part of the task
        if(Array.isArray(data.meal_types)) {
            data.meal_types.map(m => {
                let t = m.time.split(':');
                const params = {
                    mealId: m.type * 1,
                    mealLength: 0,
                    startHour: t[0] * 1,
                    startMinute: t[1] * 1,
                    profiles: [Util.getActiveProfileId()]
                };
                this.props.addMeal(params);
            });
        }


        // Prepare trainings, if they are a part of the task
        if(Array.isArray(data.activity_types)) {
            data.activity_types.map(a => {
                if(a.time == "") return;
                if(a.length == "") return;
                let t = a.time.split(':');
                const params = {
                    start: t[0] + ':' + t[1],
                    length: a.length,
                    description: a.title,
                    profiles: [Util.getActiveProfileId()]
                };
                this.props.addTraining(params);
            });
        }

/*
        const currentDate = new Date('2000-01-01');
        let saveData = {};
        saveData.titles = JSON.stringify(this.props.data.titles);
        saveData.meals = JSON.stringify(this.props.data.meals);
        saveData.foods = JSON.stringify(this.props.data.foods);
        saveData.groups = JSON.stringify(this.props.data.foodGroups);
        saveData.modifications = JSON.stringify(this.props.data.modifications);
        saveData.activities = JSON.stringify(this.props.data.activities);
        saveData.trainings = JSON.stringify(this.props.data.trainings);
        saveData.title = this.state.saveTitle;
        saveData.dayTimestamp = Math.floor(currentDate.getTime() / 1000);
        saveData.energyOutputSettings = JSON.stringify(this.props.data.energyOutputSettings);
        saveData = JSON.stringify(saveData);

        api('save/dayplan', { post: true, data: saveData }).then(result => {
            this.props.history.push('/plan/task');
        });
*/
    }

    render() {
        return [];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LectureTaskLoad);
