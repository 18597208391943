import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Util from 'utility';
import Loading from 'components/Loading/index';
import { Editor, EditorState, ContentState, RichUtils, convertFromHTML } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import QRCode from 'qrcode.react';
import sha1 from 'js-sha1';
import api from 'api';

import FoodRowSimple from 'components/Recipe/RecipeEditor/FoodRowSimple';
import EvaluationPanelSimple from 'components/EvaluationPanel/EvaluationPanelSimple';
import PanelPurchaseCookbook from 'components/PanelPurchaseCookbook';

import { 
    setFoodGroupDescription,
    openModal,
    closeModal,
    showMessage,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
    };
};


class RecipeDetail extends Component { 
    constructor(props) {
        super(props);

        let shareEditorValue = localStorage.getItem('shareMenuDescription');
        if(!shareEditorValue) shareEditorValue = '';
        const blocksFromHTML = convertFromHTML(shareEditorValue);
        const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML));

        this.state = {
            recipe: {},
            tab: 'description',
            shareDestinationProfile: 'personal',
            activeDescriptionGroup: '',
            newPicture: false,
            showFullPicture: false,
            loading: true,
            confirmDelete: false,
            customTags: {},
            newTagTitle: '',
            tagRenameId: 0,
            renameTagTitle: '',
            tagDeleteId: 0,
            savingChanges: false,
            shareEditorState: editorState,
            shareRecipeClientId: 0,
            exportPdfLink: false,
            exportSharingPageLink: false,
        };    

        this.switchTab = this.switchTab.bind(this);
        this.openBuyCookbook = this.openBuyCookbook.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.shareEditorChange = this.shareEditorChange.bind(this);
        this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
        this.onEditChange = this.onEditChange.bind(this);
        this.addCustomTag = this.addCustomTag.bind(this);
        this.renameTag = this.renameTag.bind(this);
        this.doRenameTag = this.doRenameTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.doDeleteTag = this.doDeleteTag.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.shareRecipeToClient = this.shareRecipeToClient.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.exportSharingPage = this.exportSharingPage.bind(this);
        this.saveTags = this.saveTags.bind(this);
    }

    componentDidMount() {
        api('recipes/detail/' + this.props.id).then((res) =>{

            let firstGroupId = 0;
            Object.keys(res.data.groups).map(g => {
                const group = res.data.groups[g];
                if(firstGroupId == 0) firstGroupId = group.id;
            });

            this.setState({
                loading: false,
                recipe: res.data,
                customTags: res.data.customTags,
                activeDescriptionGroup: firstGroupId,
            });
        });
    }

    switchTab(tab) {
        this.setState({
            tab: tab
        });
    }

    openBuyCookbook() {
        this.props.closeModal();
        this.props.openModal({
            'title': 'ZOF kuchařka - koupit plný přístup', 
            'class': 'dialog-flexible',
            'content': (
                <PanelPurchaseCookbook onDone={this.props.closeModal} />
            )
        });
    }

    showDeleteConfirm() {
        this.setState({
            confirmDelete: true
        });
    }

    cancelDelete() {
        this.setState({
            confirmDelete: false
        });
    }

    doDelete() {
        this.props.beginReload();
        api('recipes/delete', {post: true, data: {id: this.state.recipe.id}}).then((result) => {
            this.props.closeModal();
            this.props.showMyRecipes();    
            this.props.endReload();
        });
    }

    addCustomTag() {
        const title = this.state.newTagTitle;
        if(title.trim() == '') {
            alert("Zadejte prosím název kategorie.");
            return;
        }
        api('recipes/add-custom-tag', {post: true, data: {title: title}}).then((result) => {
            this.setState({
                customTags: result.data
            });
        });
    }

    renameTag(e, id) {
        this.setState({
            tagRenameId: id,
            tagDeleteId: 0,
            renameTagTitle: this.state.customTags[id]
        });
    }

    doRenameTag() {
        api('recipes/rename-custom-tag', {post: true, data: {id: this.state.tagRenameId, title: this.state.renameTagTitle}}).then((result) => {
            this.setState({
                customTags: result.data,
                tagRenameId: 0,
                renameTagTitle: ''
            });
        });
    }

    deleteTag(e, id) {
        this.setState({
            tagDeleteId: id,
            tagRenameId: 0
        });
    }

    doDeleteTag() {
        api('recipes/delete-custom-tag', {post: true, data: {id: this.state.tagDeleteId}}).then((result) => {
            this.setState({
                customTags: result.data,
                tagDeleteId: 0
            });
        });
    }

    saveTags() {
        this.props.beginReload();
        api('recipes/update-tags', {
            post: true, 
            data: {
                id: this.state.recipe.id, 
                tags: this.state.recipe.tags,
                use_in_generator: this.state.recipe.profi_use_in_generator
            }
        }).then((result) => {
            this.props.endReload();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Štítky byly uloženy.'
            });     
        });
    }

    shareEditorChange(editorState) {
        this.setState({
            shareEditorState: editorState
        }, () => {
            localStorage.setItem('shareMenuDescription', stateToHTML(this.state.shareEditorState.getCurrentContent()))
        });
    }

    toggleInlineStyle (event) {
        event.preventDefault();
        let style = event.currentTarget.getAttribute('data-style');
        this.setState({
          shareEditorState: RichUtils.toggleInlineStyle(this.state.shareEditorState, style)
        });
    }

    onEditChange(e) {
        let n = e.target.name;
        let v = e.target.value;
        let recipe = this.state.recipe;
        let shareDestinationProfile = this.state.shareDestinationProfile;
        let newPicture = this.state.newPicture;
        let newTagTitle = this.state.newTagTitle;
        let renameTagTitle = this.state.renameTagTitle;
        let activeDescriptionGroup = this.state.activeDescriptionGroup;
        let shareRecipeClientId = this.state.shareRecipeClientId;
        let tagId;
        switch(n) {
            case 'title':
            case 'gastro_ingredients_origin':
            case 'gastro_grammage':
            case 'gastro_price':
                recipe.lang[n] = v;
                break;
            case 'description_group':
                activeDescriptionGroup = v;
                break;
            case 'description':
                Object.keys(recipe.groups).map(g => {
                    if(recipe.groups[g].id == activeDescriptionGroup) {
                        recipe.groups[g].description = v;
                    }
                });
                break;
            case 'picture':
                newPicture = e.target.files[0];
                break;
            case 'gastro_is_on_menu':
                if(recipe.gastro_is_on_menu === null) recipe.gastro_is_on_menu = 0;
                recipe.gastro_is_on_menu = 1 - recipe.gastro_is_on_menu;
                break;
            case 'gastro_dont_show_description':
                if(recipe.gastro_dont_show_description === null) recipe.gastro_dont_show_description = 0;
                recipe.gastro_dont_show_description = 1 - recipe.gastro_dont_show_description;
                break;
            case 'rename_tag_title':
                renameTagTitle = v;
                break;
            case 'new_tag_title':
                newTagTitle = v;
                break;
            case 'tag':
                tagId = e.target.getAttribute('data-id') * 1;
                recipe.tags = [];
                if(e.target.checked) {
                    recipe.tags.push(tagId);
                }
                break;
            case 'rtag':
                tagId = e.target.getAttribute('data-id') * 1;
                recipe.tags = recipe.tags.filter(t => t !== tagId);
                if(e.target.checked) {
                    recipe.tags.push(tagId);
                }
                break;    
            case 'share_destination_profile':
                shareDestinationProfile = v;
                break;
            case 'clientshare_client_id':
                shareRecipeClientId = v;
                break;
            case 'use_in_generator':
                recipe.profi_use_in_generator = e.target.checked ? 1 : 0;
                break;
        }

        this.setState({
            recipe: recipe,
            shareDestinationProfile: shareDestinationProfile,
            activeDescriptionGroup: activeDescriptionGroup,
            newPicture: newPicture,
            newTagTitle: newTagTitle,
            renameTagTitle: renameTagTitle,
            shareRecipeClientId: shareRecipeClientId,
        });
    }

    saveChanges() {
        const recipe = this.state.recipe;
        const recipeJson = JSON.stringify(recipe);
        let fd = new FormData();

        fd.append('picture', this.state.newPicture);
        fd.append('recipe', recipeJson);

        this.setState({
            savingChanges: true
        }, () => {
        
            api('recipes/update', {post:true, data:fd}).then((res) =>{
                res = res.data;
                if(typeof res.error !== 'undefined') {
                    if(res.error == 'invalid_image_format') {
                        alert("Neplatný formát obrázku (nahrávejte pouze JPG nebo PNG soubory)");
                        return;
                    }
                } else {
                    this.setState({
                        loading: true
                    }, () => {
                        api('recipes/detail/' + this.props.id).then((res) =>{
                            let firstGroupId = 0;
                            Object.keys(res.data.groups).map(g => {
                                const group = res.data.groups[g];
                                if(firstGroupId == 0) firstGroupId = group.id;
                            });
                            this.setState({
                                loading: false,
                                savingChanges: false,
                                recipe: res.data,
                                activeDescriptionGroup: firstGroupId,
                            });
                        });
                    });
                }
            });

        });
    }

    shareRecipeToClient() {
        let clientId = this.state.shareRecipeClientId * 1;
        if(clientId == 0) {
            alert("Vyberte uživatele, kterému chcete recept nasdílet.");
            return;
        }
        api('clients/share-recipe-to', {post: true, data: {clientId: clientId, recipeId: this.state.recipe.id}}).then(res => {
            this.props.closeModal();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Recept byl sdílen uživateli.'
            });     
        });
    }

    
    exportPDF() {
        this.setState({
            exportPdfLink: false,
        }, () => {
            api('recipes/export-pdf', {post: true, data: {id: this.state.recipe.id}}).then(result => {
                this.setState({
                    exportPdfLink: 'https://www.zofapp.cz/data/zofapp/recipe-pdf/' + result.data.filename + '.pdf'
                });
            });
        });
    }

    exportSharingPage() {
        this.setState({
            exportSharingPageLink: false,
        }, () => {
            api('recipes/export-sharing-page', {post: true, data: {id: this.state.recipe.id}}).then(result => {
                this.setState({
                    exportSharingPageLink: 'https://www.zofapp.cz/r/' + result.data.url
                });
            });
        });
    }


    render() {
        const { state } = this;
        const { recipe } = this.state;
        const { data } = this.props;
        const { globals } = data;
        const isMyRecipe = recipe.user_id === data.globals.user.id;

        if(state.loading === true) return <Loading />;

        let editButton = false;
        if(isMyRecipe) {
            editButton = <a className={'' + state.tab == 'edit' ? 'active' : ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('edit')}>Upravit</a>
        }

        let qrButton = false;
        let exportButton = false;
        if(Util.userHasPurchased('ZOF_GASTRO') && recipe.user_id === data.user.id) {
            qrButton = <a className={'' + state.tab == 'qr' ? 'active' : ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('qr')}>QR kód</a>
            exportButton = <a className={'' + state.tab == 'export' ? 'active' : ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('export')}>Data export</a>
        }

        let shareButton = <a className={'' + state.tab == 'share_export' ? 'active': ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('share_export')}>Sdílení/export</a>

        let navbarAside = false;
        if(isMyRecipe) {
            if(!this.state.confirmDelete) {
                navbarAside = (
                    <aside>
                        <Link to={`/recipe/open/${recipe.id}`}>Otevřít</Link>
                        <a onClick={this.showDeleteConfirm}><i className="fa fa-trash"></i> Smazat</a>
                    </aside>
                );
            } else {
                navbarAside = (
                    <aside>
                        <a onClick={this.doDelete} className="btn-warning"><i className="fa fa-exclamation-triangle"></i> Potvrdit smazání</a>
                        <a onClick={this.cancelDelete} className="btn-cancel"><i className="fa fa-undo"></i> Nemazat</a>
                    </aside>
                );
            }
        } else {
            let navbarAsideLinks = [];

            navbarAsideLinks.push(<Link to={`/recipe/open/${recipe.id}`}>Otevřít recept</Link>);
            navbarAside = (
                <aside>
                    {navbarAsideLinks}
                </aside>
            );
        }

        let recipePreparation = false;
        let navBar = (
            <div className="recipe-detail-navbar">
                <div className="tabs" style={{flex:'1',display:'flex',alignItems:'center'}}>
                    <a className={'' + state.tab == 'description' ? 'active' : ''} style={{marginRight:'.5em'}} onClick={() => this.switchTab('description')}>Detail receptu</a>
                    {editButton}
                    {shareButton}
                    {qrButton}
                    {exportButton}
                </div>
                {navbarAside}
            </div>
        );

        const folder = Math.floor(recipe.id / 1000);
        const picUrl = `https://www.zofapp.cz/data/zof/${recipe.picture}?${recipe.picture_lastmod}`;
        let description = [];
        let numberOfGroups = Object.keys(recipe.groups).length;
        Object.keys(recipe.groups).map(g => {
            const group = recipe.groups[g];
            if(numberOfGroups > 1) {
                description.push('*** ' + group.title + "\n" + group.description + "\n");
            } else {
                description.push(group.description);
            }
        })
        description = description.join("\n");

        let buyCookbookLink = false;
        if(!isMyRecipe && !Util.userHasPurchased('ZOF_COOKBOOK')) {
            description = 'Postup přípravy receptu včetně množství použitých surovin se zobrazí po zakoupení ZOF kuchařky.';
            buyCookbookLink = <p style={{marginTop:'1.5em'}}><a style={{background:'#f90'}} className="button-default" onClick={this.openBuyCookbook}>Koupit ZOF kuchařku</a></p>;

            navBar = (
                <div className="recipe-detail-navbar">
                    <div className="actions">
                    </div>
                </div>
            );
    
        }


        let tabContent = [];
        if(this.state.showFullPicture) {
            tabContent.push(
                <div className="recipe-detail-description">
                    <div className="col-picture">
                        <div className="picture picture-full" onClick={() => this.setState({showFullPicture:false})}>
                            <img src={picUrl} alt="" />
                            <span><i className="fa fa-search-minus"></i></span>
                        </div>
                    </div>
                </div>
            );
        } else {
            tabContent.push(
                <div className="recipe-detail-description">
                    <div className="col-picture">
                        <div className="picture" onClick={() => this.setState({showFullPicture:true})}>
                            <img src={picUrl} alt="" />
                            <span><i className="fa fa-search-plus"></i></span>
                        </div>
                        <div className="recipe-foods" style={{flexGrow:1}}>
                            <h3>Ingredience:</h3>
                            {Object.keys(recipe.ingredients).map(f => {
                                let food = recipe.ingredients[f];
                                if(typeof food.group_id === 'undefined' || food.group_id == '' || food.group_id == '0') {
                                    return <FoodRowSimple food={food} />
                                }
                            })}
                        </div>
                    </div>
                    <div>
                        <h3>Postup přípravy:</h3>
                        {Util.parseRecipeDescription(description)}
                        {buyCookbookLink}
                        <h3>Nutriční hodnoty</h3>
                        <EvaluationPanelSimple recipe={recipe} opened={true} style={{marginLeft:'0'}} />

                    </div>
                </div>
            );
        }

        tabContent.push(
            <div className="recipe-detail-contents" style={{marginTop:'1em'}}>
                <input type="checkbox" id="cb-panel-evaluation" />
            </div>
        );

        if(this.state.tab == 'share_export') {

            // Share to client - only for ZOF_PROFI

            let shareToClient = false;
            if(state.recipe.user_has_clients > 0 && isMyRecipe) {
                let clientShareOptions = [];
                clientShareOptions.push(<option value={0}>- Vyberte -</option>);
                this.props.data.globals.clients.map(client => {
                    clientShareOptions.push(<option value={client.id}>{client.name}</option>);
                });
                shareToClient = (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                        <div className="form-generic">
                            <h2>Sdílet recept uživateli</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Vyberte uživatele:</td>
                                        <td><select name="clientshare_client_id" onChange={this.onEditChange} >{clientShareOptions}</select></td>
                                    </tr>                
                                    <tr>
                                        <td></td>
                                        <td><button onClick={() => this.shareRecipeToClient()}>Poslat</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            }


            // Share to my profile

            let chooseProfileToShareTo = false;
            if(Util.userHasPurchased('ZOF_GASTRO')) {
                chooseProfileToShareTo = (
                    <div className="share-choose-profile">
                        <span>Sdílet na:</span>
                        <label>
                            <input checked={state.shareDestinationProfile == 'personal'} onChange={this.onEditChange} type="radio" name="share_destination_profile" value="personal" /> osobní profil
                        </label>
                        <label>
                            <input checked={state.shareDestinationProfile == 'gastro'} onChange={this.onEditChange} type="radio" name="share_destination_profile" value="gastro" /> Gastro profil
                        </label>
                    </div>
                );
            }
            let shareToProfile = (
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <div className="form-generic">
                        <h2>Sdílet recept na svůj profil</h2>
                        {chooseProfileToShareTo}
                        <p style={{fontSize:'.9em',color:'#444'}}>Napište komentář:</p>
                        <div style={{width:'100%',maxWidth:'640px'}}>
                            <p style={{margin:'0 0 2px 0',fontSize:'.9e'}}>
                                <input type="button" value="B" data-style="BOLD" onMouseDown={this.toggleInlineStyle} />
                                <input type="button" value="I" data-style="ITALIC" onMouseDown={this.toggleInlineStyle} style={{fontStyle:'italic'}} />
                            </p>
                            <div style={{border:'1px solid #ccc',padding:'4px'}}>
                                <Editor editorState={state.shareEditorState} onChange={this.shareEditorChange} />
                            </div>
                        </div>
                        <p style={{margin:'1.5em 0',textAlign:'center'}}><Link className="button-default" to={`/recipe/share/${this.state.recipe.id}/${this.state.shareDestinationProfile}`}>Sdílet recept</Link></p>
                    </div>
                </div>
            );

            // Export PDF and recipe sharing page

            let pdfDownloadLink = false;
            if(state.exportPdfLink) {
                pdfDownloadLink = <p style={{padding:'0 0 2em 0',margin:'0',fontWeight:'bold'}}><a style={{color:'#4ebe55',textDecoration:'none',padding:'.35em 1em',border:'1px solid #4ebe55',borderRadius:'3px'}} target="_blank" href={state.exportPdfLink}><i className="fa fa-file-pdf"></i> Stáhnout PDF</a></p>
            }

            let exportSharingPageLink = false;
            if(state.exportSharingPageLink) {
                exportSharingPageLink = <p style={{padding:'0 0 2em 0',margin:'0',fontWeight:'bold'}}><a target="_blank" style={{color:'#4ebe55',textDecoration:'none',padding:'.35em 1em',border:'1px solid #4ebe55',borderRadius:'3px'}} target="_blank" href={state.exportSharingPageLink}><i className="fa fa-file-pdf"></i> Otevřít stránku pro sdílení receptu</a></p>
            }

            let exportPdfAndSharingPage = false;
            if(Util.userHasPurchased('ZOF_PROFI') || Util.userHasPurchased('ZOF_COOKBOOK') || isMyRecipe) {
                exportPdfAndSharingPage = (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                        <div className="form-generic">
                            <h2>Export PDF</h2>
                            <p>Kliknutím na tlačítko se vytvoří PDF dokument s receptem.</p>
                            {pdfDownloadLink}
                            <p><button className="btn-primary" onClick={this.exportPDF}>Vytvořit PDF</button></p>
                            <div style={{margin:'2em 0'}}>
                                <h2>Vytvořit stránku s receptem</h2>
                                <p>Kliknutím na tlačítko se vytvoří veřejná stránka s receptem, kterou můžete sdílet. Stránka bude obsahovat:</p>
                                <ul>
                                    <li>jméno autora receptu</li>
                                    <li>fotografii receptu</li>
                                    <li>ingredience včetně gramáží</li>
                                    <li>postup přípravy</li>
                                    <li>seznam alergenů</li>
                                    <li>nutriční vyhodnocení receptu</li>
                                </ul>
                                {exportSharingPageLink}
                                <p><button className="btn-primary" onClick={this.exportSharingPage}>Vytvořit stránku pro sdílení receptu</button></p>
                            </div>
                        </div>
                    </div>
                );            
            }

            tabContent = (
                <>
                <div style={{margin:'1em 0'}}>{exportPdfAndSharingPage}</div>
                <div style={{margin:'1em 0'}}>{shareToClient}</div>
                <div style={{margin:'1em 0'}}>{shareToProfile}</div>
                </>
            );
        }

        if(this.state.tab == 'edit') {

            let currentDescription = '';
            let descriptionGroups = [];
            Object.keys(recipe.groups).map(g => {
                const group = recipe.groups[g];
                descriptionGroups.push(
                    <option value={group.id}>{globals.foodGroupTypes[group.type]}: {group.title}</option>

                );
                if(group.id == state.activeDescriptionGroup) {
                    currentDescription = group.description;
                }
            });

            let saveChangesButton = (
                <span><a onClick={this.saveChanges}>Uložit změny</a></span>
            );

            if(this.state.savingChanges) {
                saveChangesButton = <span><a className="disabled">Ukládám...</a></span>
            }

            let gastroData = false;
            if(Util.userHasPurchased('ZOF_GASTRO')) {

                let amount = 0;
                let alergens = [];
                Object.keys(recipe.ingredients).map(i => {
                    const ingr = recipe.ingredients[i];
                    amount += parseFloat(ingr.amount);

                    ingr.alergens.map(aid => {
                        if(!alergens.includes(globals.itemAlergens[aid])) alergens.push(globals.itemAlergens[aid]);
                        //alergens[aid] = globals.itemAlergens[aid];
                    }); 
                });

                if(alergens.length == 0) alergens = ['žádné'];
                

                gastroData = (
                    <>
                    <h3>Gastro údaje</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <label>
                                        <input type="checkbox" onChange={this.onEditChange} checked={recipe.gastro_is_on_menu === 1} name="gastro_is_on_menu" value={recipe.gastro_is_on_menu} />
                                        {'\u00A0'}zařadit na jídelní lístek
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <label>
                                        <input type="checkbox" onChange={this.onEditChange} checked={recipe.gastro_dont_show_description === 1} name="gastro_dont_show_description" value={recipe.gastro_dont_show_description} />
                                        {'\u00A0'}nezobrazovat popis receptu (postup přípravy)
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>Původ surovin</td>
                                <td><textarea style={{height:'10em'}} onChange={this.onEditChange} name="gastro_ingredients_origin" value={recipe.lang.gastro_ingredients_origin}></textarea></td>
                            </tr>
                            <tr>
                                <td>Množství</td>
                                <td>
                                    <input style={{width:'8em',display:'inline'}} type="text" onChange={this.onEditChange} name="gastro_grammage" value={recipe.lang.gastro_grammage} />
                                    {'\u00A0'}(přesné množství: {Math.ceil(amount)} g/ml)
                                </td>
                            </tr>
                            <tr>
                                <td>Cena</td>
                                <td><input style={{width:'8em',display:'inline'}} type="text" onChange={this.onEditChange} name="gastro_price" value={recipe.lang.gastro_price} /> Kč</td>
                            </tr>
                            <tr>
                                <td>Alergeny</td>
                                <td>{alergens.join(', ')}</td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                );
            }


            // Tags

            let tags = [];
            Object.values(globals.tagGroupsOrder).map(g => {

            //Object.keys(globals.tagGroups).map(g => {
                const tg = globals.tagGroups[g];
                let groupContents = [];
                Object.keys(globals.tagsToGroups[g]).map(io => {
                    const tagId = globals.tagsToGroups[g][io];
                    // if(tagId == 1) return;      // Pevne jidlo nepouzivat
                    groupContents.push(
                        <>
                        <input id={`rtag_${g}_${io}`} type="checkbox" onChange={this.onEditChange} checked={recipe.tags.includes(tagId)} name="rtag" data-id={tagId} />
                        <label htmlFor={`rtag_${g}_${io}`}>
                            <span><i className="fa fa-check"></i></span>
                            {globals.tags[tagId]}
                        </label>
                        </>
                    );
                });
                 tags.push(<h4>{tg}</h4>);
                tags.push(<div className="recipe-tags-group">{groupContents}</div>);    
            });

            let ingredients = [];
            Object.keys(recipe.ingredients).map(f => {
                let food = recipe.ingredients[f];
                ingredients.push(<p>{food.title}</p>);
            });


            let useRecipeInGeneratorOption = false;
            if(Util.userHasPurchased('ZOF_PROFI')) {
                useRecipeInGeneratorOption = (
                    <div style={{padding:'2em 0 1em 0'}}>
                        <p><b>Použít tento recept v generátoru</b><br />
                        Před zařazením receptu do generátoru je důležité zaškrtnout všechny relevantní a správné štítky.</p>
                        <label><input type="checkbox" name="use_in_generator" onChange={this.onEditChange} checked={recipe.profi_use_in_generator == 1} /> Použít recept v generátoru jídelníčků</label>
                    </div>
                );
            }



            tabContent = (
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <div className="form-generic">
                        <h2>Základní údaje</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Název receptu</td>
                                    <td><input type="text" onChange={this.onEditChange} name="title" value={state.recipe.lang.title} /></td>
                                </tr>
                                <tr>
                                    <td>Popis receptu</td>
                                    <td>
                                        <p><select name="description_group" onChange={this.onEditChange}>{descriptionGroups}</select></p>
                                        <p><textarea name="description" onChange={this.onEditChange}>{currentDescription}</textarea></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Obrázek receptu</td>
                                    <td>
                                        <input type="file" name="picture" onChange={this.onEditChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {gastroData}
                        <h2>Štítky</h2>
                        <div className="recipe-tags">
                            <div style={{display:'flex'}}>
                                <div style={{flex:'1'}}>
                                    {tags}
                                </div>
                                <div className="recipe-detail-tags-ingredients" style={{flexBasis:'200px'}}>
                                    <p><b>Ingredience:</b></p>
                                    <div>{ingredients}</div>
                                </div>
                            </div>
                        </div>
                        {useRecipeInGeneratorOption}
                        <div className="recipe-save-changes">
                        {saveChangesButton}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
            <div className="recipe-detail">
                <h1>{recipe.lang.title}</h1>
                {navBar}
                {tabContent}
            </div>
            </React.Fragment>            
        );            
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeDetail));
