import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import api from "api";
import Util from "utility";
import { isThisHour, isFuture } from "date-fns";

import { openModal, closeModal } from "actions/index";

import PanelRecommendFoods from "./PanelRecommendFoods";
import PanelDecreaseFoods from "./PanelDecreaseFoods";

const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (data) => dispatch(openModal(data)),
        closeModal: (data) => dispatch(closeModal(data)),
    };
};

class EvaluationPanelContents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluationProfileId: Util.getFirstProfileId(),
      selectedDDDAttributeKey: "",
      selectedGlossaryItem: {},
      showAdvancedEvaluation: false,
      glossaryModalIsOpen: false,
    };
    this.openGlossaryModal = this.openGlossaryModal.bind(this);
    this.closeGlossaryModal = this.closeGlossaryModal.bind(this);
    this.showEvaluationGlossary = this.showEvaluationGlossary.bind(this);
    this.toggleAdvancedEvaluation = this.toggleAdvancedEvaluation.bind(this);
    this.showRecommendedFoods = this.showRecommendedFoods.bind(this);
    this.onRecommendedFoodAdded = this.onRecommendedFoodAdded.bind(this);
    this.showDecreaseFoods = this.showDecreaseFoods.bind(this);
  }

  openGlossaryModal() {
    this.setState({ glossaryModalIsOpen: true });
  }

  closeGlossaryModal() {
    this.setState({ glossaryModalIsOpen: false });
  }

  toggleAdvancedEvaluation() {
    this.setState({
      showAdvancedEvaluation: !this.state.showAdvancedEvaluation,
    });
  }

  showEvaluationGlossary(id) {
    api("glossary/item/" + id).then((result) => {
      result = result.data;
      if (result.error) return false;
      this.setState({
        selectedGlossaryItem: result,
      });
      this.openGlossaryModal();
    });
  }

  showRecommendedFoods(e, attr) {
    e.preventDefault();
    if (e.target != e.currentTarget) return;
    this.props.closeModal();
    this.props.openModal({
      title:
        "Potraviny vhodné k doplnění: " +
        this.props.data.globals.attributes[attr],
      closeButton: "Zavřít",
      content: (
        <PanelRecommendFoods
          attr={attr}
          ddd={this.props.ddd[attr]}
          onFoodAdded={this.onRecommendedFoodAdded}
        />
      ),
    });
  }

  onRecommendedFoodAdded() {
    this.props.closeModal();
    // Util.doLoadDayEvaluation();
    this.props.onRecommendedFoodAdded();
  }

  showDecreaseFoods(e, attr) {
    e.preventDefault();
    if (e.target != e.currentTarget) return;
    this.props.closeModal();
    this.props.openModal({
      title:
        "Potraviny s nejvyšším obsahem: " +
        this.props.data.globals.attributes[attr],
      closeButton: "Zavřít",
      content: <PanelDecreaseFoods attr={attr} />,
    });
  }

  render() {
    const { state } = this;
    const { data } = this.props;
    const { globals } = this.props.data;

    const evaluatingWholeDay = this.props.wholeDay === true;
    const results = this.props.results; //data.evaluationResults.results;
    const ddd = this.props.ddd;
    const units = globals.attribute_units;

    let resultsTable = false;
    let resultsTableMacrosOnly = [];
    let quickResults = false;

    let attributeKey;
    let attributeUnit;

    let chartColours = ["#96c999", "#eb714a", "#eae869", "#8bcbce", "#c694be"];

    let glossaryDialog = false;
    if (state.glossaryModalIsOpen) {
      glossaryDialog = (
        <div
          className="dialog dialog-save-recipe"
          onClick={this.closeSaveRecipeDialog}
        >
          <div className="dialog-body">
            <h2>
              {this.state.selectedGlossaryItem.title}{" "}
              <a>
                <i
                  onClick={this.closeGlossaryModal}
                  className="fa fa-times"
                ></i>
              </a>
            </h2>
            <div className="dialog-content">
              <div style={{ height: "250px" }}>
                <Scrollbars heightrelativetoparent="1">
                  <div
                    style={{
                      flex: "1",
                      overflow: "hidden",
                      padding: "0 14px 0 0",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.selectedGlossaryItem.description,
                      }}
                    ></div>
                    <p
                      style={{
                        paddingTop: "1em",
                        marginTop: "1em",
                        borderTop: "1px solid #ddd",
                      }}
                    >
                      Více se o nutriční kvalitě výživy dozvíte na{" "}
                      <a
                        style={{ color: "#4eb32d" }}
                        href="https://www.kurzyatac.cz"
                      >
                        www.kurzyatac.cz
                      </a>
                    </p>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let evaluateOptions = false;

    // Convert string-floats with unnecessary amount of digits to something human readable
    for (let k in results) {
      let f = parseFloat(results[k]);
      if (k.indexOf("ratio") >= 0) {
        //let fstr = f.toString();
        let fstr = results[k];
        if (fstr.indexOf(":") >= 0) {
          f = results[k];
          continue;
        } else {
          if (f <= 1) {
            let fmul = 1 / f;
            f = "1 : " + fmul.toPrecision(2);
          } else {
            f = f.toPrecision(2) + " : 1";
          }
        }
      }
      if (typeof f === "number") {
        f = Math.round(f * 100) / 100.0;
      }
      results[k] = f;
    }

    if (results !== undefined) {
      resultsTable = [];
      quickResults = [];

      if (evaluatingWholeDay) {
        // Quick results = in whole day mode, show energy balance info

        let balanceKcal = results["energy_balance_kcal"];
        if (balanceKcal > 0) {
          balanceKcal = balanceKcal + "";
          if (balanceKcal.substr(0, 1) != "+") balanceKcal = "+" + balanceKcal;
        }

        let balanceKj = results["energy_balance_kj"];
        if (balanceKj > 0) {
          balanceKj = balanceKj + "";
          if (balanceKj.substr(0, 1) != "+") balanceKj = "+" + balanceKj;
        }

        quickResults.push(
          <React.Fragment key="balance">
            <p className="quick-result">
              <span>Bilance</span>
              <strong>{balanceKcal} kcal</strong>
              <strong>{balanceKj} kJ</strong>
            </p>
          </React.Fragment>
        );

        // Energy balance info

        let energyBalanceClass =
          results["energy_balance_kcal"] < 0 ? "wrong" : "ok";
        if (results["energy_balance_kcal"] > 0) {
          let sKcal = results["energy_balance_kcal"].toString();
          let sKj = results["energy_balance_kj"].toString();
          if (sKcal.substr(0, 1) != "+") sKcal = "+" + sKcal;
          if (sKj.substr(0, 1) != "+") sKj = "+" + sKj;
          results["energy_balance_kcal"] = sKcal;
          results["energy_balance_kj"] = sKj;
        }
        resultsTable.push(
          <React.Fragment key="energy_balance">
            <h4>Energetická bilance</h4>
            <div className="eval-item-pair">
              <a onClick={() => this.showEvaluationGlossary(45)}>
                <strong>{results["energy_balance_kcal"]}</strong>
                {units["energy_balance_kcal"]}
              </a>
              <a onClick={() => this.showEvaluationGlossary(45)}>
                <strong>{results["energy_balance_kj"]}</strong>
                {units["energy_balance_kj"]}
              </a>
            </div>
            <h4>Energetický příjem</h4>
            <div className="eval-item-pair" style={{ borderBottom: "none" }}>
              <a onClick={() => this.showEvaluationGlossary(17)}>
                <strong>{results["energy_kcal"]}</strong>
                {units["energy_kcal"]}
              </a>
              <a onClick={() => this.showEvaluationGlossary(17)}>
                <strong>{results["energy_kj"]}</strong>
                {units["energy_kj"]}
              </a>
            </div>
            <h4>Energetický výdej</h4>
            <div className="eval-item-pair" style={{ borderBottom: "none" }}>
              <a onClick={() => this.showEvaluationGlossary(54)}>
                <strong>{results["energy_output_kcal"]}</strong>
                {units["energy_output_kcal"]}
              </a>
              <a onClick={() => this.showEvaluationGlossary(54)}>
                <strong>{results["energy_output_kj"]}</strong>
                {units["energy_output_kj"]}
              </a>
            </div>
          </React.Fragment>
        );
      } else {
        // V beznem rezimu zobrazit pouze informace o prijmu energie

        resultsTable.push(
          <React.Fragment>
            <h4>Energie</h4>
            <div className="eval-item-pair">
              <a onClick={() => this.showEvaluationGlossary(17)}>
                <strong>{results["energy_kcal"]}</strong>
                {units["energy_kcal"]}
              </a>
              <a onClick={() => this.showEvaluationGlossary(17)}>
                <strong>{results["energy_kj"]}</strong>
                {units["energy_kj"]}
              </a>
            </div>
          </React.Fragment>
        );
      }

      quickResults.push(
        <React.Fragment key="energy">
          <p className="quick-result">
            <span>kcal</span>
            <strong>{results["energy_kcal"]}</strong>
          </p>
          <p className="quick-result">
            <span>kJ</span>
            <strong>{results["energy_kj"]}</strong>
          </p>
        </React.Fragment>
      );

      const barGraphData = [
        { name: "B", value: results["proteins"] * 1 },
        { name: "S", value: results["carbohydrates"] * 1 },
        { name: "T", value: results["fats"] * 1 },
        { name: "V", value: results["fiber"] * 1 },
      ];

      let barValues = ["proteins", "carbohydrates", "fats", "fiber"];
      let barChart = [];
      let mmax = Math.max(
        results["proteins"],
        results["carbohydrates"],
        results["fats"],
        results["fiber"]
      );

      let step = 0.01;
      if (mmax > 1) step = 0.1;
      if (mmax > 10) step = 1;
      if (mmax > 100) step = 10;
      if (mmax > 1000) step = 100;
      if (mmax > 10000) step = 1000;
      if (mmax > 100000) step = 10000;
      if (mmax > 1000000) step = 100000;
      if (mmax > 10000000) step = 1000000;
      let scaleMax = Math.ceil(Math.round(mmax * 1.2) / step) * step;

      for (let i = 0; i < barValues.length; i++) {
        let v = Math.floor((results[barValues[i]] / scaleMax) * 150);
        if(isNaN(v)) continue;
        barChart.push(
          <rect
            x={110 + i * 70}
            y={180 - v}
            width={65}
            height={v}
            fill={chartColours[i]}
            key={`bc1_${i}`}
          />
        );
        barChart.push(
          <text
            textAnchor="middle"
            fontWeight="bold"
            fontSize="1.3em"
            x={110 + i * 70 + 32}
            y={180 - v - 6}
            fill={chartColours[i]}
            key={`bc2_${i}`}
          >
            {results[barValues[i]]}
          </text>
        );
      }

      let chartLabels = [];
      let labStep = scaleMax / 5;
      let currentValue = 0;
      for (let i = 0; i <= 5; i++) {
        let currentValueFmt = (
          Math.round(currentValue / step) * step
        ).toPrecision(1);
        chartLabels.push(
          <text
            textAnchor="end"
            fontSize="1.4em"
            x="72"
            y={4 + 180 - i * 25}
            fill="#444"
            key={`cl1_${i}`}
          >
            {Number(currentValueFmt)}
          </text>
        );
        chartLabels.push(
          <line
            x1="80"
            y1={180 - i * 25}
            x2="90"
            y2={180 - i * 25}
            style={{ stroke: "#333" }}
            key={`cl2_${i}`}
          />
        );
        chartLabels.push(
          <line
            x1="91"
            y1={180 - i * 25}
            x2="390"
            y2={180 - i * 25}
            style={{ stroke: "#ccc" }}
            key={`cl3_${i}`}
          />
        );
        currentValue += labStep;
      }

      resultsTable.push(
        <React.Fragment key="macros_wrap">
          <h4>Makroživiny</h4>
          <div
            className="eval-item evaluation-macronutrients"
            style={{ padding: 0 }}
          >
            <div className="svg-wrap">
              <svg viewBox="0 0 400 220" width="100%" height="100%">
                {chartLabels}
                <line
                  x1="90"
                  y1="30"
                  x2="90"
                  y2="180"
                  style={{ stroke: "#333" }}
                />
                {barChart}
                <line
                  x1="90"
                  y1="180"
                  x2="390"
                  y2="180"
                  style={{ stroke: "#333" }}
                />
                <text
                  fontWeight="bold"
                  textAnchor="middle"
                  fontSize="1.3em"
                  x="140"
                  y="200"
                  fill="#444"
                >
                  B
                </text>
                <text
                  fontWeight="bold"
                  textAnchor="middle"
                  fontSize="1.3em"
                  x="210"
                  y="200"
                  fill="#444"
                >
                  S
                </text>
                <text
                  fontWeight="bold"
                  textAnchor="middle"
                  fontSize="1.3em"
                  x="280"
                  y="200"
                  fill="#444"
                >
                  T
                </text>
                <text
                  fontWeight="bold"
                  textAnchor="middle"
                  fontSize="1.3em"
                  x="350"
                  y="200"
                  fill="#444"
                >
                  Vl
                </text>
              </svg>
            </div>
          </div>
        </React.Fragment>
      );

      let ta = results["total_amount"];
      if (ta === undefined) ta = 0;
      let totalAmountInfo = ta + " g";
      if (ta >= 1000) {
        ta /= 1000;
        ta = Math.round(ta * 1000) / 1000;
        totalAmountInfo = ta + " kg";
      }

      quickResults.push(
        <React.Fragment key="macros">
          <p className="quick-result">
            <span>Bílkoviny</span>
            <strong>{results["proteins"]} g</strong>
          </p>
          <p className="quick-result">
            <span>Sacharidy</span>
            <strong>{results["carbohydrates"]} g</strong>
          </p>
          <p className="quick-result">
            <span>Tuky</span>
            <strong>{results["fats"]} g</strong>
          </p>
          <p className="quick-result">
            <span>Vláknina</span>
            <strong>{results["fiber"]} g</strong>
          </p>
          <p className="quick-result">
            <span>Pitný režim</span>
            <strong>{results["drink_amount"]} l</strong>
          </p>
          <p className="quick-result">
            <span>Hmotnost</span>
            <strong>{totalAmountInfo}</strong>
          </p>
        </React.Fragment>
      );

      let gk = "Makroživiny";

      let subItems = [];
      for (let subGroup in globals.evaluation_groups[gk]) {
        attributeKey = globals.evaluation_groups[gk][subGroup];
        attributeUnit = globals.attribute_units[attributeKey];
        if (typeof results[attributeKey] == "undefined") continue;

        let glossaryId = -1;
        if (this.props.data.globals.glossary_ids[attributeKey] != "undefined") {
          glossaryId = this.props.data.globals.glossary_ids[attributeKey];
        }

        let attributeTitle = globals.attributes[attributeKey];

        subItems.push(
          <div key={attributeKey} className="eval-item-single">
            <a onClick={() => this.showEvaluationGlossary(glossaryId)}>
              <span>{attributeTitle}:</span>
              <strong>
                {results[attributeKey]} {attributeUnit}
              </strong>
            </a>
          </div>
        );
      }
      if (subItems.length > 0) {
        resultsTable.push(<React.Fragment key="macros_subitems">{subItems}</React.Fragment>);
      }

      let resGI, resGN, resSALT, resCHOLESTEROL;
      resGI = <strong>{results["gi"]}</strong>;
      resGN = <strong>{results["gn"]}</strong>;
      resSALT = (
        <strong>
          {results["salt"]} {units["salt"]}
        </strong>
      );
      resCHOLESTEROL = (
        <strong>
          {results["cholesterol"]} {units["cholesterol"]}
        </strong>
      );

    
      resultsTable.map(el => {
          resultsTableMacrosOnly.push(el);
      });

      resultsTable.push(
        <React.Fragment key="glycemic_info">
          <h4>Glykémie</h4>
          <div className="eval-item-pair">
            <a onClick={() => this.showEvaluationGlossary(18)}>
              index
              {resGI}
            </a>
            <a onClick={() => this.showEvaluationGlossary(19)}>
              nálož
              {resGN}
            </a>
          </div>
        </React.Fragment>
      );

      let riSalt = false;
      let riCholesterol = false;
      let riSaltColor = "#000";
      let riCholesterolColor = "#000";

      if (this.props.ddd) {
        if (
          typeof this.props.ddd["salt"] !== "undefined" &&
          typeof this.props.ddd["cholesterol"] !== "undefined"
        ) {
          if (
            this.props.wholeDay === true &&
            (this.props.ddd["salt"].overflow_direction == "min" ||
              this.props.ddd["salt"].overflow_direction == "max")
          ) {
            riSaltColor = "#f00";
          }
          riSalt = (
            <p style={{ margin: "6px 0 0" }}>
              <span style={{ color: riSaltColor }}>
                {this.props.ddd["salt"].percent} % RI
              </span>
            </p>
          );

          if (
            this.props.wholeDay === true &&
            (this.props.ddd["cholesterol"].overflow_direction == "min" ||
              this.props.ddd["cholesterol"].overflow_direction == "max")
          ) {
            riCholesterolColor = "#f00";
          }
          riCholesterol = (
            <p style={{ margin: "6px 0 0" }}>
              <span style={{ color: riCholesterolColor }}>
                {this.props.ddd["cholesterol"].percent} % RI
              </span>
            </p>
          );
        }
      }

      resultsTable.push(
        <React.Fragment key="salt_cholesterol">
          <h4>Sůl, cholesterol</h4>
          <div className="eval-item-pair">
            <a onClick={() => this.showEvaluationGlossary(24)}>
              sůl
              {resSALT}
              {riSalt}
            </a>
            <a onClick={() => this.showEvaluationGlossary(27)}>
              cholesterol
              {resCHOLESTEROL}
              {riCholesterol}
            </a>
          </div>
        </React.Fragment>
      );

      if (results !== undefined) {
        const graphData_Weight = [
          { name: "B", value: results["proteins"] * 1 },
          { name: "S", value: results["carbohydrates"] * 1 },
          { name: "T", value: results["fats"] * 1 },
          { name: "V", value: results["fiber"] * 1 },
        ];
        const graphData_Energetic = [
          { name: "B", value: results["proteins"] * 4 },
          {
            name: "S",
            value: results["carbohydrates"] * 4 + results["fiber"] * 2,
          },
          { name: "T", value: results["fats"] * 9 },
        ];

        // Chart 1

        let chart1 = Util.pieChart(
          [
            results["proteins"],
            results["carbohydrates"],
            results["fats"],
            results["fiber"],
          ],
          100,
          100,
          95,
          chartColours
        );

        // Chart 2

        let enProt = results["proteins"] * 4.18;
        let enCarb = results["carbohydrates"] * 4.18;
        let enFats = results["fats"] * 9;
        let enFibr = results["fiber"] * 2;

        let total = enProt + enCarb + enFats + enFibr;
        if (total == 0) total = 0.00001;

        enProt = Math.round((enProt / total) * 100);
        enCarb = Math.round((enCarb / total) * 100);
        enFats = Math.round((enFats / total) * 100);
        enFibr = 100 - enProt - enCarb - enFats; //(enFibr / total) * 100;

        let chart2 = Util.pieChart(
          [enProt, enCarb, enFats, enFibr],
          100,
          100,
          95,
          chartColours
        );

        //

        resultsTable.push(
          <React.Fragment key="ratio_graphs">
            <h4>Hmotnostní poměry živin</h4>
            <div className="eval-item">
              <div className="svg-wrap">
                <svg viewBox="0 0 400 200" width="100%" height="100%">
                  {chart1}
                  <rect
                    x="220"
                    y="40"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[0], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="55" fill="#444">
                    Bílkoviny ({parseFloat(results["proteins"])} g)
                  </text>
                  <rect
                    x="220"
                    y="70"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[1], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="85" fill="#444">
                    Sacharidy ({parseFloat(results["carbohydrates"])} g)
                  </text>
                  <rect
                    x="220"
                    y="100"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[2], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="115" fill="#444">
                    Tuky ({parseFloat(results["fats"])} g)
                  </text>
                  <rect
                    x="220"
                    y="130"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[3], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="145" fill="#444">
                    Vláknina ({parseFloat(results["fiber"])} g)
                  </text>
                </svg>
              </div>
            </div>
            <h4>Energetické poměry makroživin</h4>
            <div className="eval-item">
              <div className="svg-wrap">
                <svg viewBox="0 0 400 200" width="100%" height="100%">
                  {chart2}
                  <rect
                    x="220"
                    y="40"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[0], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="55" fill="#444">
                    Bílkoviny ({parseFloat(enProt)} %)
                  </text>
                  <rect
                    x="220"
                    y="70"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[1], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="85" fill="#444">
                    Sacharidy ({parseFloat(enCarb)} %)
                  </text>
                  <rect
                    x="220"
                    y="100"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[2], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="115" fill="#444">
                    Tuky ({parseFloat(enFats)} %)
                  </text>
                  <rect
                    x="220"
                    y="130"
                    width="20"
                    height="20"
                    style={{ fill: chartColours[3], strokeWidth: 0 }}
                  />
                  <text fontSize="1.4em" x="250" y="145" fill="#444">
                    Vláknina ({parseFloat(enFibr)} %)
                  </text>
                </svg>
              </div>
            </div>
          </React.Fragment>
        );
      }

      for (let groupKey in this.props.data.globals.evaluation_groups_closed) {
        let subItems = [];
        for (let subGroup in globals.evaluation_groups[groupKey]) {
          attributeKey = globals.evaluation_groups[groupKey][subGroup];
          attributeUnit = globals.attribute_units[attributeKey];
          if (typeof results[attributeKey] == "undefined") continue;

          let glossaryId = -1;
          if (
            this.props.data.globals.glossary_ids[attributeKey] != "undefined"
          ) {
            glossaryId = this.props.data.globals.glossary_ids[attributeKey];
          }

          let dddInfo = false;
          let riColor = "#444";
          if (ddd && typeof ddd[attributeKey] !== "undefined") {
            dddInfo = ddd[attributeKey].percent + " % RI";
            if (
              this.props.wholeDay === true &&
              (ddd[attributeKey].overflow_direction == "min" ||
                ddd[attributeKey].overflow_direction == "max")
            ) {
              riColor = "#f00";
            }
          }

          subItems.push(
            <div key={attributeKey} className="eval-item-single">
              <a onClick={() => this.showEvaluationGlossary(glossaryId)}>
                <span>{globals.attributes_short[attributeKey]}:</span>
                <em style={{ color: riColor }}>{dddInfo}</em>
                <strong>
                  {results[attributeKey]} {attributeUnit}
                </strong>
              </a>
            </div>
          );

          if(attributeKey == 'added_sugars') {
                let sugarCubes = results[attributeKey] / 4;
                sugarCubes = Math.ceil(sugarCubes * 10) / 10;
            subItems.push(
                <div key="temp01" className="eval-item-single">
                    <a>
                        <span style={{flexBasis:'180px',marginRight:'2em'}}>Odpovídá kostkám cukru:</span>
                        <strong>
                        {sugarCubes}
                        </strong>
                    </a>
                </div>
              );    
          }

        }
        if (subItems.length > 0) {
          resultsTable.push(
            <div className="evaluation-group evaluation-group-closed" key={`subgroup_${groupKey}`}>
              <input type="checkbox" id={"group-title-" + groupKey} />
              <p className="evaluation-group-title">
                <label htmlFor={"group-title-" + groupKey}>
                  <i className="fa fa-angle-right"></i> {groupKey}
                </label>
              </p>
              <div className="evaluation-group-content">{subItems}</div>
            </div>
          );
        }
      }
    }

    let styles = [];
    if (this.props.style) styles = this.props.style;

    let mainStyles = [];
    if (this.props.mainStyle) mainStyles = this.props.mainStyle;

    let classes = [];
    classes.push("panel");
    classes.push("panel-evaluation");
    if (this.props.opened === true) classes.push("panel-evaluation-opened");


    if(this.props.data.appMode == 'RECIPE_EDITOR') classes.push('evaluation-recipe-editor');


    let quick = false;
    if (this.props.quickResults === true) {
      quick = (
        <div className="icons">
          <label htmlFor="cb-panel-evaluation" className="panel-reveal">
            <i className="fa fa-angle-left"></i>
          </label>
          {quickResults}
        </div>
      );
    }

    // let userId = data.user.id;
    let simplifiedResults = false;

    if (this.props.wholeDay === true) {
      const ddd = this.props.ddd;
      let recomReduce = [];
      let recomIncrease = [];
      let totalAttributes = 0;

      let text = "";
      let percentDifference;

      // Energy balance simplified result

      if (results !== undefined) {
        const energyIntake = results["energy_kcal"] * 1;
        const energyLimitFrom = results["energy_balance_limit_from"] * 1;
        const energyLimitTo = results["energy_balance_limit_to"] * 1;
        if (energyIntake < energyLimitFrom) {
          let diff = Math.abs(energyLimitFrom - energyIntake);
          percentDifference = Math.ceil((diff / energyIntake) * 100);
          // text = <span><i className="fa fa-arrow-up"></i> o {percentDifference} %</span>;
          text = <span>{percentDifference} %</span>;
          recomIncrease.push(
            <tr key="energy_intake_increase">
              <td>Energetický příjem</td>
              <td>{text}</td>
            </tr>
          );
        }
        if (energyIntake > energyLimitTo) {
          let diff = Math.abs(energyIntake - energyLimitTo);
          percentDifference = Math.ceil((diff / energyIntake) * 100);
          // text = <span><i className="fa fa-arrow-down"></i> o {percentDifference} %</span>;
          text = <span>{percentDifference} %</span>;
          recomReduce.push(
            <tr key="energy_intake_decrease">
              <td>Energetický příjem</td>
              <td>{text}</td>
            </tr>
          );
        }
      }

      // DDD (RI) simplified results

      for (let k in ddd) {
        if (k == "alcohol") continue; // DON'T RECOMMEND TO INCREASE ALCOHOL :-)

        let attrOrder = this.props.data.globals.attributes_order[k];


        if (ddd[k].overflow_direction != "") {
          if (ddd[k].percent < 100) {
            percentDifference = Math.ceil(((100 - ddd[k].percent) / 100) * 100);
          } else {
            percentDifference = Math.ceil(
              ((ddd[k].percent - 100) / ddd[k].percent) * 100
            );
          }

          if (ddd[k].overflow_direction == "min") {
            text = (
              <span>
                {Math.ceil(ddd[k].amount_to_optimum)}{" "}
                {globals.attribute_units[k]}
              </span>
            );
            recomIncrease[attrOrder] = (
              <tr key="recom_increase">
                <td>
                  <a onClick={(e) => this.showRecommendedFoods(e, k)}>
                    {globals.attributes[k]}
                  </a>
                </td>
                <td>{text}</td>
              </tr>
            );
          }
          if (ddd[k].overflow_direction == "max") {
            text = (
              <span>
                {Math.ceil(ddd[k].amount_to_optimum)}{" "}
                {globals.attribute_units[k]}
              </span>
            );
            recomReduce[attrOrder] = (
              <tr key="recom_decrease">
                <td>
                  <a onClick={(e) => this.showDecreaseFoods(e, k)}>
                    {globals.attributes[k]}
                  </a>
                </td>
                <td>{text}</td>
              </tr>
            );
          }
        }
        totalAttributes++;
      }

      let reclen =
        Object.keys(recomReduce).length + Object.keys(recomIncrease).length;
      let percent = Math.round(
        ((totalAttributes - reclen) / totalAttributes) * 100
      );

      let ratingText = "Je co zlepšovat...";
      if (percent > 33) {
        ratingText = "Jste na dobré cestě!";
      }
      if (percent > 70) {
        ratingText = "Výborně!";
      }

      let increaseElement = false;
      if (recomIncrease.length > 0) {

        let recomIncreaseElement = [];
        Object.keys(recomIncrease).map(e => {
            if(e === "0") return;
            recomIncreaseElement.push(recomIncrease[e]);
        });

        increaseElement = (
          <>
            <h3>
              <i className="fa fa-info-circle"></i> Doporučujeme navýšit:
            </h3>
            <table>
              <tbody>{recomIncreaseElement}</tbody>
            </table>
          </>
        );
      }

      let reduceElement = false;
      if (recomReduce.length > 0) {
        let recomReduceElement = [];
        Object.keys(recomReduce).map(e => {
            if(e === "0") return;
            recomReduceElement.push(recomReduce[e]);
        });

        reduceElement = (
          <>
            <h3>
              <i className="fa fa-info-circle"></i> Doporučujeme snížit:
            </h3>
            <table>
              <tbody>{recomReduceElement}</tbody>
            </table>
          </>
        );
      }

      simplifiedResults = (
        <>
          <div className="evaluation-recommendations">
            <h2>{ratingText}</h2>
            <p>
              Váš jídelníček dosahuje rating <b>{percent} %</b>.
            </p>
            {increaseElement}
            {reduceElement}
          </div>
        </>
      );
    }

    if (this.props.quickResults === false) {
      simplifiedResults = false;
    }

    // Skip simplified results if current day menu is empty
    // (ie. no foods entered)

    let numFoods = 0;
    Object.keys(data.foods).map((g) => {
      numFoods += data.foods[g].length;
    });
    if (numFoods == 0) {
      simplifiedResults = false;
    }

    let toggleButtonText = "Skrýt detailní vyhodnocení";
    if (simplifiedResults !== false && state.showAdvancedEvaluation == false) {
      resultsTable = false;
      toggleButtonText = "Zobrazit detailní vyhodnocení";
    }

    if(this.props.evaluationCollapsedAtStart === true && state.showAdvancedEvaluation === false) {
        resultsTable = false;
        toggleButtonText = "Zobrazit detailní vyhodnocení";  
    }

    let toggleAdvancedEvaluationButton = false;
    if (this.props.wholeDay === true) {
      toggleAdvancedEvaluationButton = (
        <p className="evaluation-toggle-advanced">
          <a onClick={this.toggleAdvancedEvaluation}>{toggleButtonText}</a>
        </p>
      );
    }


    if(this.props.data.appMode == 'LECTURE') {
        toggleAdvancedEvaluationButton = false;
    }

    if(this.props.macrosOnly === true) resultsTable = resultsTableMacrosOnly; 

    return (
      <>
        <div className={classes.join(" ")} style={styles}>
          <div className="row">
            {quick}
            <div className="main" style={mainStyles}>
              <div
                style={{
                  flex: "1",
                  marginRight: "14px",
                  paddingBottom: "100px",
                }}
              >
                {evaluateOptions}
                {this.props.beforeContent}
                {simplifiedResults}
                {resultsTable}
                {toggleAdvancedEvaluationButton}
              </div>
            </div>
          </div>
        </div>
        {glossaryDialog}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationPanelContents);
