import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './store/index';
import App from 'components/App/App.js';
import 'assets/scss/index.scss';
import * as serviceWorker from './serviceWorker';
  
ReactDOM.render((
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
),  document.getElementsByClassName('app')[0]);

class GlobalBroadcaster {
    constructor() {
        this.events = {};
    }
    /**
     * Called by event generators to inform subscribers.
     *
     * @param string event: a string identifying the event name
     * @param mix data: message to communicate to subsribers
     */
    dispatch(event, data) {
        if (!this.events[event]) return; // no subscribers
        this.events[event].forEach(subscriber => subscriber(data));
    }
    /**
     * Means to subscribe a callback function to a given event.
     * @param string event: a string identifying the event name
     * @param function subscriber: a function to call back when the given event takes place
     */
    subscribe(event, subscriber) {
        if (!this.events[event]) this.events[event] = []; // new event
        this.events[event].push(subscriber);
    }

    unsubscribe(event) {
        this.events[event] = [];
    }
}

window.globalBroadcaster = new GlobalBroadcaster();
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
