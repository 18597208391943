import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading/index';
import api from 'api';
import Util from 'utility.js';

import { 
    addMeal, 
    addTraining,
    setLectureTaskData,
    setProfileData
} from 'actions/index';


const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        addMeal: data => dispatch(addMeal(data)),
        addTraining: data => dispatch(addTraining(data)),
        setLectureTaskData: data => dispatch(setLectureTaskData(data)),
        setProfileData: data => dispatch(setProfileData(data)),
    };
};

class LectureIntro extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lectureInfo: false
        };    
        this.initTask = this.initTask.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Úkol');
        const lectureId = this.props.data.appModeData.lectureId;
        api('lecturetask/info/' + lectureId).then(result => {
            if(result.data.profile !== false) {
                this.props.setProfileData({
                    id: result.data.profile.id,
                    data: result.data.profile
                });
            }
            this.setState({
                lectureInfo: result.data,
                loading: false
            }, () => {
                this.initTask();
                this.props.history.push('/plan');        
            });
            // this.props.setLectureTaskData(result.data);
        });
    }

    initTask() {
        const data = this.state.lectureInfo.lecture_data;

        // Prepare meals, if they are a part of the task
        if(Array.isArray(data.meal_types)) {
            data.meal_types.map(m => {
                let t = m.time.split(':');
                const params = {
                    mealId: m.type * 1,
                    mealLength: 0,
                    startHour: t[0] * 1,
                    startMinute: t[1] * 1,
                    profiles: [Util.getActiveProfileId()]
                };
                this.props.addMeal(params);
            });
        }


        // Prepare trainings, if they are a part of the task
        if(Array.isArray(data.activity_types)) {
            data.activity_types.map(a => {
                if(a.time == "") return;
                if(a.length == "") return;
                let t = a.time.split(':');
                const params = {
                    start: t[0] + ':' + t[1],
                    length: a.length,
                    description: a.title,
                    profiles: [Util.getActiveProfileId()]
                };
                this.props.addTraining(params);
            });
        }


        const currentDate = new Date('2000-01-01');
        let saveData = {};
        saveData.titles = JSON.stringify(this.props.data.titles);
        saveData.meals = JSON.stringify(this.props.data.meals);
        saveData.foods = JSON.stringify(this.props.data.foods);
        saveData.groups = JSON.stringify(this.props.data.foodGroups);
        saveData.modifications = JSON.stringify(this.props.data.modifications);
        saveData.activities = JSON.stringify(this.props.data.activities);
        saveData.trainings = JSON.stringify(this.props.data.trainings);
        saveData.title = this.state.saveTitle;
        saveData.dayTimestamp = Math.floor(currentDate.getTime() / 1000);
        saveData.energyOutputSettings = JSON.stringify(this.props.data.energyOutputSettings);
        saveData = JSON.stringify(saveData);

        api('save/dayplan', { post: true, data: saveData }).then(result => {
            this.props.history.push('/plan/task');
        });
    }

    render() {
        const { state } = this;
        const { lectureInfo } = state;


        let content = <Loading />;
        if(!state.loading) {

            let taskTextElement = <div dangerouslySetInnerHTML={{__html: lectureInfo.task_text}}></div>;

            let profileInfo = false;
            if(lectureInfo.profile !== false) {
                profileInfo = (
                    <div className="lecture-intro-profile">
                        <h3>Vytváříte jídelníček pro:</h3>
                        <p>{lectureInfo.profile.name}</p>
                        <table>
                            <tbody>
                            <tr>
                                    <td>Výška:</td>
                                    <td>{lectureInfo.profile.height} cm</td>
                                </tr>
                                <tr>
                                    <td>Váha:</td>
                                    <td>{lectureInfo.profile.weight} kg</td>
                                </tr>
                                <tr>
                                    <td>Obvod pasu:</td>
                                    <td>{lectureInfo.profile.diameter_waist} cm</td>
                                </tr>
                                <tr>
                                    <td>Obvod boků:</td>
                                    <td>{lectureInfo.profile.diameter_sides} cm</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                );
            }

            content = (
                <>
                <div className="page">
                    <div className="page-header">
                        <h1>ÚKOL K LEKCI</h1>
                        <h2>{lectureInfo.title}</h2>
                    </div>
                    <div className="page-content">
                        <div className="container">
                            <div className="lecture-intro">
                                {taskTextElement}
                                {profileInfo}
                                <p className="begin"><a onClick={(e) => this.initTask()}>Začít</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LectureIntro);
