import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import store from 'store/index';
import Util from 'utility';
import { format } from "date-fns";
import api from 'api';

import { 
    setActiveMealHash, 
    clearEvaluationResults, 
    addMeal, 
    removeMeal, 
    updateMeal, 
    addActivity, 
    removeActivity, 
    addTraining,
    updateTraining,
    removeTraining,
    openModal, 
    closeModal, 
    showMessage,
    setData,
} from "../../actions/index";

import DialogNewMeal from './DialogNewMeal';

var locales = {
    cs: require('date-fns/locale/cs'),
}

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        setActiveMealHash: data => dispatch(setActiveMealHash(data)),
        clearEvaluationResults: data => dispatch(clearEvaluationResults(data)),
        addMeal: data => dispatch(addMeal(data)),
        removeMeal: data => dispatch(removeMeal(data)),
        updateMeal: data => dispatch(updateMeal(data)),
        addActivity: data => dispatch(addActivity(data)),
        removeActivity: data => dispatch(removeActivity(data)),
        addTraining: data => dispatch(addTraining(data)),
        updateTraining: data => dispatch(updateTraining(data)),
        removeTraining: data => dispatch(removeTraining(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        setData: data => dispatch(setData(data)),
    };
};

class DayPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.date,
            showMealTypeModal: false,
            selectedMealId: 0,
            modalX: 0,
            modalY: 0,
            addMealSelectedMealId: 1,
            addMealSelectedHour: 0,
            addMealSelectedMinute: 0,
            addMealSelectedProfiles: [],
            editMealSelectedMealHash: '',
            editMealSelectedMealId: 1,
            editMealSelectedHour: 0,
            editMealSelectedMinute: 0,
            editMealSelectedProfiles: [],

            editTrainingSelectedId: '',
            editTrainingSelectedDescription: 1,
            editTrainingSelectedLength: 0,
            editTrainingSelectedHour: 0,
            editTrainingSelectedMinute: 0,

            currentMouseOverHour: -1,
            currentMouseOverMinute: 0,
            selectedMinute: 0,

            profiMealHash: '',              // ZOF Profi: hash of meal to add comment to
            profiMealDate: '',              // ZOF Profi: current date (redundant - can be taken from data.plannerDate, but...)
            profiMealComment: '',           // ZOF Profi: text of meal comment
        };

        this.handleEditMeal = this.handleEditMeal.bind(this);
        this.doUpdateMeal = this.doUpdateMeal.bind(this);
        this.handleDeleteMeal = this.handleDeleteMeal.bind(this);
        this.doDeleteMeal = this.doDeleteMeal.bind(this);

        this.handleEditTraining = this.handleEditTraining.bind(this);
        this.handleEditTrainingSelectHour = this.handleEditTrainingSelectHour.bind(this);
        this.handleEditTrainingSelectMinute = this.handleEditTrainingSelectMinute.bind(this);
        this.handleEditTrainingDescription = this.handleEditTrainingDescription.bind(this);
        this.handleEditTrainingLength = this.handleEditTrainingLength.bind(this);
        this.doUpdateTraining = this.doUpdateTraining.bind(this);


        this.selectActiveMealHash = this.selectActiveMealHash.bind(this);
        this.handleEditMealSelectHour = this.handleEditMealSelectHour.bind(this);
        this.handleEditMealSelectMinute = this.handleEditMealSelectMinute.bind(this);
        this.handleAddMealSelectType = this.handleAddMealSelectType.bind(this);
        this.handleAddMealSelectHour = this.handleAddMealSelectHour.bind(this);
        this.handleAddMealSelectMinute = this.handleAddMealSelectMinute.bind(this);
        this.onAddMealClick = this.onAddMealClick.bind(this);
        this.doAddMealType = this.doAddMealType.bind(this);
        this.handleEditMealSelectType = this.handleEditMealSelectType.bind(this);
        this.handleEditMealSelectHour = this.handleEditMealSelectHour.bind(this);
        this.handleEditMealSelectMinute = this.handleEditMealSelectMinute.bind(this);
        this.handleEditSelectProfiles = this.handleEditSelectProfiles.bind(this);
        this.handleDeleteTraining = this.handleDeleteTraining.bind(this);

        this.handleMealProfiComment = this.handleMealProfiComment.bind(this);
        this.updateProfiMealComment = this.updateProfiMealComment.bind(this);
        this.doSaveProfiComment = this.doSaveProfiComment.bind(this);
        this.handleMealShowProfiComment = this.handleMealShowProfiComment.bind(this);
    }

    selectActiveMealHash(e, hash) {
        e.preventDefault();
        this.props.clearEvaluationResults();
        this.props.setActiveMealHash(hash);
        this.props.history.push('/recipe/edit/meal/' + hash.replace('|', '_')); 
        
    }

    handleEditMeal(id, e) {
        this.setState({
            editMealSelectedMealHash: id,
            editMealSelectedHour: this.props.data.meals[id].startHour,
            editMealSelectedMinute: this.props.data.meals[id].startMinute,
            editMealSelectedMealId: this.props.data.meals[id].mealId,
            editMealSelectedProfiles: this.props.data.meals[id].profiles
        }, () => {

            let meal = this.props.data.meals[id];

            let hours = [];
            let minutes = [];
            for (let i = 0; i <= 24; i++) {
                hours.push(i);
            }
            for (let i = 0; i < 60; i++) {
                minutes.push(i);
            }

            let mealTypes = [];
            for (let i in this.props.data.globals.mealTypes) {
                mealTypes.push(<option value={i} key={i}>{this.props.data.globals.mealTypes[i].title}</option>);
            }

            let profilesList = [];
            for (let profileId in this.props.data.globals.profiles) {
                profileId = profileId * 1;
                let checked = this.state.editMealSelectedProfiles.includes(profileId);
                profilesList.push(
                    <label>
                        <input type="checkbox" defaultChecked={checked} data-profile-id={profileId} onClick={this.handleEditSelectProfiles} />
                        <span>{this.props.data.globals.profiles[profileId].name}</span>
                    </label>
                );
            }

            this.props.openModal({
                title: 'Upravit chod',
                content: (
                    <table className="generic-table">
                        <tbody>
                            <tr>
                                <th>Typ chodu:</th>
                                <td><select name="select-meal-type" defaultSelected={meal.mealId} onChange={this.handleEditMealSelectType}>
                                    {mealTypes}
                                </select></td>
                            </tr>
                            <tr>
                                <th>Čas:</th>
                                <td><select name="select-time-start-h" defaultValue={meal.startHour} onChange={this.handleEditMealSelectHour}>
                                    {hours.map(n => {
                                        return <option value={n} key={n}>{n}</option>;
                                    })};
                                            </select>
                                    {'\u00A0'}:{'\u00A0'}
                                    <select name="select-time-start-m" defaultValue={meal.startMinute} onChange={this.handleEditMealSelectMinute}>
                                        {minutes.map(n => {
                                            return <option value={n} key={n}>{n}</option>;
                                        })};
                            </select>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td><button className="btn-primary" onClick={() => this.doUpdateMeal()}>Uložit změny</button></td>
                            </tr>
                        </tbody>
                    </table>
                )
            });
        });
    }

    doUpdateMeal() {
        if (this.state.editMealSelectedProfiles.length == 0) {
            alert("Prosím vyberte alespoň jeden profil.");
            return;
        }
        const params = {
            mealHash: this.state.editMealSelectedMealHash,
            mealId: this.state.editMealSelectedMealId,
            mealLength: 0, //this.state.addMealSelectedMealLength,
            startHour: this.state.editMealSelectedHour,
            startMinute: this.state.editMealSelectedMinute,
            profiles: this.state.editMealSelectedProfiles
        };
        this.props.updateMeal(params);
        this.props.onChange();
        this.props.closeModal();
    }





    handleMealShowProfiComment(mealId, e) {
        this.props.closeModal();
        this.props.openModal({
            title: 'Komentář od poradce',
            content: (
                <table className="generic-table">
                    <tbody>
                        <tr>
                            <pre style={{height:'12em',overflowY:'scroll',border:'1px solid #ccc',padding:'4px',fontFamily:'sans-serif'}}>{this.props.data.meals[mealId].profi_comment}</pre>
                        </tr>
                        <tr>
                            <td><button className="btn-primary" onClick={() => this.props.closeModal()}>Zavřít</button></td>
                        </tr>
                    </tbody>
                </table>
            )
        });
    }




    handleMealProfiComment(id, e) {
        let meal = false;
        Object.keys(this.props.data.meals).map(m => {
            if(id == m) {
                meal = this.props.data.meals[m];
            }
        });

        let date = format(this.props.data.plannerDate, 'yyyy-MM-dd');
        let hash = meal.mealHash + ";" + meal.mealId + ";" + meal.startHour + ":" + meal.startMinute;

        this.setState({
            profiMealHash: hash,
            profiMealDate: date,
            profiMealComment: ''
        }, () => {
            this.props.openModal({
                title: 'Vložit komentář',
                content: (
                    <table className="generic-table">
                        <tbody>
                            <tr>
                                <td><textarea onChange={this.updateProfiMealComment} defaultValue={this.state.profiMealComment} style={{width:'100%',height:'10em'}}></textarea></td>
                            </tr>
                            <tr>
                                <td><button className="btn-primary" onClick={() => this.doSaveProfiComment()}>Uložit</button></td>
                            </tr>
                        </tbody>
                    </table>
                )
            });
        });
    }

    updateProfiMealComment(e) {
        this.setState({
            profiMealComment: e.target.value
        });
    }

    doSaveProfiComment() {
        api('clients/save-meal-comment', { post: true, data: { 
            hash: this.state.profiMealHash, 
            date: this.state.profiMealDate, 
            comment: this.state.profiMealComment,
            uhash: localStorage.getItem('zof_profi_return_hash')
        }}).then((res) =>{

            // Update current state without the need to load the whole dayplan again
            let currState = store.getState();
            let mh = this.state.profiMealHash.split(';');
            currState.meals[mh[0]].profi_comment = this.state.profiMealComment;
            this.props.setData(currState);

            // SHhw success message 

            this.props.closeModal();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: 'Komentář byl zapsán.'
            });               
        });        
    }


    handleEditTraining(id, e) {
        let start = this.props.data.trainings[id].start;
        start = start.split(':');
        this.setState({
            editTrainingSelectedId: id,
            editTrainingSelectedDescription: this.props.data.trainings[id].description,
            editTrainingSelectedLength: this.props.data.trainings[id].length,
            editTrainingSelectedHour: start[0],
            editTrainingSelectedMinute: start[1]
        }, () => {

            let meal = this.props.data.meals[id];

            let hours = [];
            let minutes = [];
            for (let i = 0; i <= 24; i++) {
                hours.push(i);
            }
            for (let i = 0; i < 60; i++) {
                minutes.push(i);
            }

            this.props.openModal({
                title: 'Upravit aktivitu',
                content: (
                    <table className="generic-table">
                        <tbody>
                            <tr>
                                <th>Čas:</th>
                                <td><select name="select-time-start-h" defaultValue={this.state.editTrainingSelectedHour} onChange={this.handleEditTrainingSelectHour}>
                                    {hours.map(n => {
                                        return <option value={n} key={n}>{n}</option>;
                                    })};
                                            </select>
                                    {'\u00A0'}:{'\u00A0'}
                                    <select name="select-time-start-m" defaultValue={this.state.editTrainingSelectedMinute} onChange={this.handleEditTrainingSelectMinute}>
                                        {minutes.map(n => {
                                            return <option value={n} key={n}>{n}</option>;
                                        })};
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Popis:</th>
                                <td>
                                    <input type="text" name="activityTitle" onChange={this.handleEditTrainingDescription} defaultValue={this.state.editTrainingSelectedDescription} />
                                </td>
                            </tr>
                            <tr>
                                <th>Délka:</th>
                                <td>
                                    <input type="number" name="activityLength" onChange={this.handleEditTrainingLength} defaultValue={this.state.editTrainingSelectedLength} style={{display:'inline',width:'6em'}} /> minut
                                </td>
                            </tr>                            
                            <tr>
                                <th></th>
                                <td><button className="btn-primary" onClick={() => this.doUpdateTraining()}>Uložit změny</button></td>
                            </tr>
                        </tbody>
                    </table>
                )
            });
        });
    }

    
    handleEditTrainingSelectHour(e) {
        this.setState({
            editTrainingSelectedHour: e.target.value
        });
    }

    handleEditTrainingSelectMinute(e) {
        this.setState({
            editTrainingSelectedMinute: e.target.value
        });
    }

    handleEditTrainingDescription(e) {
        this.setState({
            editTrainingSelectedDescription: e.target.value
        });
    }

    handleEditTrainingLength(e) {
        this.setState({
            editTrainingSelectedLength: e.target.value
        });
    }

    doUpdateTraining() {
        const params = {
            id: this.state.editTrainingSelectedId,
            startHour: this.state.editTrainingSelectedHour,
            startMinute: this.state.editTrainingSelectedMinute,
            description: this.state.editTrainingSelectedDescription,
            length: this.state.editTrainingSelectedLength
        };
        this.props.updateTraining(params);
        this.props.onChange();
        this.props.closeModal();
    }








    handleDeleteTraining(id, e) {
        this.props.openModal({
            'title': 'Vymazat trénink', 
            'content': (
                <>
                <p>Opravdu vymazat trénink?</p>
                <table className="generic-table">
                    <tbody>
                        <tr>
                            <th></th>
                            <td>
                                <button className="btn-primary" onClick={() => this.doDeleteTraining(id)}>Ano, vymazat trénink</button>
                                <a className="link-cancel" onClick={this.props.closeModal}>Zpět</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </>
            )
        });
    }

    doDeleteTraining(id) {
        this.props.removeTraining(id);

        // ATTN - make this a callback! Not timeout
        setTimeout(() => {
            this.props.onChange();
            this.props.closeModal();
        }, 150);
    }


    handleDeleteMeal(id, e) {
        this.props.openModal({
            'title': 'Vymazat chod', 
            'content': (
                <>
                <p>Opravdu vymazat chod?</p>
                <table className="generic-table">
                    <tbody>
                        <tr>
                            <th></th>
                            <td>
                                <button className="btn-primary" onClick={() => this.doDeleteMeal(id)}>Ano, vymazat chod</button>
                                <a className="link-cancel" onClick={this.props.closeModal}>Zpět</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </>
            )
        });
    }

    doDeleteMeal(id) {
        this.props.removeMeal(id);
        this.props.onUpdateEvaluation();
        this.props.onChange();
        this.props.closeModal();
    }

    handleEditMealSelectType(e) {
        this.setState({
            editMealSelectedMealId: e.target.value
        });
    }

    handleEditMealSelectHour(e) {
        this.setState({
            editMealSelectedHour: e.target.value
        });
    }

    handleEditMealSelectMinute(e) {
        this.setState({
            editMealSelectedMinute: e.target.value
        });
    }

    handleEditSelectProfiles(e) {
        const profileId = e.target.attributes.getNamedItem('data-profile-id').value * 1;
        let selProfiles = this.state.editMealSelectedProfiles;

        selProfiles = selProfiles.filter(pid => {
            return pid !== profileId
        });
        if (e.target.checked) {
            selProfiles.push(profileId);
        }

        this.setState({
            editMealSelectedProfiles: selProfiles
        });
    }

    handleAddMealSelectType(e) {
        this.setState({
            addMealSelectedMealId: e.target.value
        });
    }

    handleAddMealSelectHour(e) {
        this.setState({
            addMealSelectedHour: e.target.value
        });
    }

    handleAddMealSelectMinute(e) {
        this.setState({
            addMealSelectedMinute: e.target.value
        });
    }


    onHourMouseMove(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let hour = e.target.attributes.getNamedItem('data-hour').value * 1;
        let height = this[`refHour${hour}`].clientHeight;
        let clickY = e.nativeEvent.offsetY;
        let minute = Math.round((clickY / height) * 60);
        minute = Math.round(minute / 5) * 5;
        if (minute < 0) minute = 0;
        if (minute > 55) minute = 55;

        this.setState({
            currentMouseOverHour: hour,
            currentMouseOverMinute: minute
        });
    }

    onHourMouseLeave(e) {
        this.setState({
            currentMouseOverHour: -1,
            currentMouseOverMinute: 0
        });
    }

    onAddMealClick(e) {
        e.preventDefault();
        if (e.target != e.currentTarget) return;

        let hour = e.target.attributes.getNamedItem('data-hour').value * 1;
        let height = this[`refHour${hour}`].clientHeight;
        let clickY = e.nativeEvent.offsetY;
        let minute = Math.round((clickY / height) * 60);
        minute = Math.round(minute / 5) * 5;

        this.props.openModal({
            'title': 'Přidat chod, pitný režim nebo aktivitu', 
            'content': (
                <DialogNewMeal selectedHour={hour} selectedMinute={minute} onAddMeal={this.doAddMealType}/>
            )
        });
    }

    doAddMealType(data) {
        if(data.type == 'meal') {
            const params = {
                mealId: data.mealId,
                mealLength: 0,
                startHour: data.hour,
                startMinute: data.minute,
                profiles: [Util.getActiveProfileId()]
            };

            let hash = params.mealId + '|' + params.profiles.sort((a, b) => a - b).join(',');
            if(typeof this.props.data.meals[hash] !== "undefined") {
                alert("Tento chod s vybranými profily už máte přidán. Zvolte prosím jiný typ chodu, nebo jiný profil / profily.");
                return;
            }
            this.props.addMeal(params);
        }

        if(data.type == 'drink_regime') {

            let profileId = Util.getActiveProfileId();
            if(typeof this.props.data.appModeData.profileId === 'number') {
                profileId = this.props.data.appModeData.profileId;
            }

            const params = {
                mealId: 50,     // First ID of drink regime meal types
                mealLength: 0,
                startHour: data.hour,
                startMinute: data.minute,
                profiles: [profileId]
            };
            this.props.addMeal(params);
        }

        if(data.type == 'activity') {
            const params = {
                start: data.hour + ':' + data.minute,
                length: data.activityLength,
                description: data.activityTitle,
                profiles: [Util.getActiveProfileId()]
            };
            this.props.addTraining(params);
        }

        this.props.onChange();
        this.props.closeModal();
    }

    render() {
        const { data } = this.props;
        const { globals } = data;


        // When in lecture task mode, and there are predefined meal types,
        // the meal types should not be editable

        let mealsAreEditable = true;
        let taskData = {};
        if(typeof this.props.data.lectureTaskData === 'object') {
            taskData = this.props.data.lectureTaskData;
            if(typeof taskData !== 'undefined' && typeof taskData.lecture_data !== 'undefined') {
                if(Array.isArray(taskData.lecture_data.meal_types)) {
                    mealsAreEditable = false;
                }
            }
        }



        // ATTN: make this more secure.
        let isLoggedInAsAdvisor = false;
        if(typeof localStorage.getItem('zof_profi_return_hash') === 'string') {
            isLoggedInAsAdvisor = true;
        }


        let dayPlanHours = [];
        for (let hour = 0; hour < 24; hour++) {
            let hourItems = [];
            let hourItemsResult = [];
            for (let m in this.props.data.meals) {
                if (this.props.data.meals[m].startHour == hour) {
                    this.props.data.meals[m].mealHash = m;
                    hourItems.push({
                        'type': 'meal',
                        'data': this.props.data.meals[m],
                        'key': m
                    });
                }
            }

            // ATTN: MAXIMUM TRAININGS NUMBER!!!!!
            // MAX NUMBER OF TRAININGS - MAKE CFGABLE
            let trainingId;
            for(trainingId=1; trainingId<=10; trainingId++) {
                if(typeof this.props.data.trainings[trainingId] == 'undefined') continue;
                let training = this.props.data.trainings[trainingId];
                if(training.length == 0) continue;
                if(training.start == '') continue;
                training.id = trainingId;
                let x = training.start.split(':');
                training.startHour = x[0] * 1;
                training.startMinute = x[1] * 1;
                if(training.startHour == hour) {
                    hourItems.push({
                        'type': 'training',
                        'data': training,
                        'key': trainingId
                    });
                }
            }


            hourItems.sort((i1, i2) => {
                let minute1, minute2;
                if(i1.type == 'meal') {
                    minute1 = this.props.data.meals[i1.data.mealHash].startMinute;                    
                } else {
                    minute1 = i1.startMinute;
                }
                if(i2.type == 'meal') {
                    minute2 = this.props.data.meals[i2.data.mealHash].startMinute;                    
                } else {
                    minute2 = i2.startMinute;
                }
                return minute1 - minute2;
            });

            hourItems.map(i => {

                if(i.type == 'meal') {
                    let m = i.data.mealHash;
                    let mealId = this.props.data.meals[m].mealId;
                    let mealProfiles = [];

                    this.props.data.meals[m].profiles.map(profileId => {
                        let profileName = '';
                        if(typeof globals.profiles[profileId] !== 'undefined') {
                            profileName = globals.profiles[profileId].name;
                        }
                        if(profileName && profileName != '') {
                            let profileColor = this.props.data.globals.profiles[profileId].profile_color;
                            if (profileColor == '' || !profileColor) profileColor = '999999';
                            let profileStyle = {
                                backgroundColor: '#' + profileColor,
                                color: '#fff'
                            };
                            mealProfiles.push(<b key={profileName} style={profileStyle}>{profileName}</b>);
                        }
                    });

/*                    
                    if(typeof this.props.data.appMode !== 'undefined' && this.props.data.appMode == 'LECTURE') {
                        if(typeof this.props.data.appModeData['profileId'] === 'number') {
                            mealProfiles = [this.props.data.appModeData['profileId']];
                        }
                    }
*/

                    let sh = this.props.data.meals[m].startHour;
                    let sm = this.props.data.meals[m].startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;

                    let foodGroupTitles = [];
                    Object.keys(this.props.data.foodGroups[m]).map(key => {
                        const fg = this.props.data.foodGroups[m][key];
                        let bgUrl = `https://www.zofapp.cz/data/zof/recipe/t/${Math.floor(fg['from_recipe_id'] / 1000)}/${fg['from_recipe_id']}.jpg`;
                        const recipeImage = false; //<div className="recipe-preview" style={{ backgroundImage:'url(' + bgUrl + ')' }}></div>
                        foodGroupTitles.push(
                            <div className="title-row">
                                {recipeImage}
                                {fg.title}
                            </div>
                        )
                    });

                    if(typeof this.props.data.globals.mealTypes[mealId] == 'undefined') return false;

                    let hourItemButtons = [];
                    hourItemButtons.push(
                        <>
                            <a onClick={(e) => this.handleEditMeal(m, e)}><i className="fa fa-pencil-alt"></i></a>
                            <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>
                        </>
                    );

                    let currentCommentButton = false;
                    if(typeof this.props.data.meals[m].profi_comment === 'string' && 
                        this.props.data.meals[m].profi_comment != '') {
                            currentCommentButton = <a onClick={(e) => this.handleMealShowProfiComment(m, e)}><i className="fa fa-comment-dots"></i></a>;

                        hourItemButtons.push(
                            <a onClick={(e) => this.handleMealShowProfiComment(m, e)}><i className="fa fa-comment-dots"></i></a>
                        );

                     }

                    if(isLoggedInAsAdvisor) {
                        hourItemButtons = [];
                        hourItemButtons.push(
                            <>
                                <a onClick={(e) => this.handleMealProfiComment(m, e)}><i className="fa fa-comment"></i></a>
                                {currentCommentButton}
                                <a onClick={(e) => this.handleEditMeal(m, e)}><i className="fa fa-pencil-alt"></i></a>
                                <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>
                            </>
                        );
                    }

                    let classes = ['meal-item'];
                    let thisItemMealId = m.split('|')[0];
                    if(globals.mealTypes[thisItemMealId].is_drink_regime) {
                        classes.push('meal-item-drink-regime');    
                    }

                    // Zrusit editacni tlacitka pouze, pokud neni 
                    // aktualni chod pitny rezim
                    if(!mealsAreEditable &&
                        !globals.mealTypes[thisItemMealId].is_drink_regime) {
                        hourItemButtons = false;
                    }

                    if(!mealsAreEditable) hourItemButtons = false;
                    // Pokud se nemaji zobrazovat editButtons, a typ
                    // chodu je pitny rezim, zobrazit jen Xko na smazani

                    if(!mealsAreEditable &&
                        globals.mealTypes[thisItemMealId].is_drink_regime) {
                            hourItemButtons = <a onClick={(e) => this.handleDeleteMeal(m, e)}><i className="fa fa-times"></i></a>;
                    }

                    hourItemsResult.push(
                        <div className={classes.join(' ')} key={m}>
                            <div className="meal-item-body" onClick={(e) => this.selectActiveMealHash(e, m)}>
                                <div className="meal-item-title">
                                    <p className="title"><span>{this.props.data.globals.mealTypes[mealId].title}</span></p>
                                    <span className="time">
                                        <i className="fa fa-clock"></i> {sh}:{sm}
                                    </span>
                                    <p className="meal-item-profiles">
                                        {mealProfiles}
                                    </p>
                                </div>
                                <div className="meal-item-details">
                                    <div className="meal-item-details-title" style={{ fontWeight: 'normal' }}>
                                        {foodGroupTitles}
                                    </div>
                                </div>
                            </div>
                            <div className="meal-item-buttons">
                                {hourItemButtons}
                            </div>
                        </div>
                    );
                }

                if(i.type == 'training') {
                    let sh = i.data.startHour;
                    let sm = i.data.startMinute;
                    if (sh < 10) sh = '0' + sh;
                    if (sm < 10) sm = '0' + sm;

                    let mealItemClass = 'meal-item meal-item-training';
                    let m = i.data.id;

                    let hourItemButtons = (
                        <>
                            <a onClick={(e) => this.handleEditTraining(m, e)}><i className="fa fa-pencil-alt"></i></a>
                            <a onClick={(e) => this.handleDeleteTraining(m, e)}><i className="fa fa-times"></i></a>
                        </>
                    );

                    if(data.profiClientId > 0) {
                        hourItemButtons = false;
                    }

                    if(!mealsAreEditable) hourItemButtons = false;

                    hourItemsResult.push(
                        <div className={mealItemClass} key={m}>
                            <div className="meal-item-body">
                                <a data-hash={m} className="meal-item-title">
                                    <p className="title"><span>Trénink</span></p>
                                    <span className="time">
                                        <b>{sh}</b>:<b>{sm}</b>
                                    </span>
                                </a>
                                <div className="meal-item-details">
                                    <p className="meal-item-details-title">
                                        <strong>{i.data.description}</strong>
                                    </p>
                                    <p className="meal-item-profiles">
                                        Délka: {i.data.length} minut
                                    </p>
                                </div>
                            </div>
                            <div className="meal-item-buttons">
                                {hourItemButtons}
                            </div>
                        </div>
                    );
                }                
            });


            let hourFmt = hour >= 10 ? hour + ':00' : '0' + hour + ':00';
            if (this.state.currentMouseOverHour == hour) {
                let spanH = '0' + hour;
                if (hour >= 10) spanH = hour;
                let spanM = '0' + this.state.currentMouseOverMinute;
                if (this.state.currentMouseOverMinute >= 10) spanM = this.state.currentMouseOverMinute;
                hourFmt = spanH + ':' + spanM;
            }

            let addCurrentHour = true;
            if(this.props.data.plannerViewMode == 1) {
                if(hourItemsResult.length == 0) addCurrentHour = false;
            }

            if(addCurrentHour) {
                dayPlanHours.push(
                    <div ref={div => { this[`refHour${hour}`] = div }} data-hour={hour} onClick={(e) => this.onAddMealClick(e)} onMouseMove={(e) => this.onHourMouseMove(e)} onMouseLeave={(e) => this.onHourMouseLeave(e)} className="hour" key={hour}>
                        <span>{hourFmt}</span>
                        {hourItemsResult}
                    </div>
                );
            }
        }

        return (      
            <React.Fragment>           
            {dayPlanHours}
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DayPlan));
