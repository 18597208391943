import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Util from 'utility';

import { 
    showMessage, 
    openModal,
    closeModal,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class DialogAssignmentProfileInfo extends Component { 

    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        const { globals } = data;

        let fpid = Util.getFirstProfileId();
        
        if(typeof data.appModeData.profileId === 'number') {
            fpid = data.appModeData.profileId;
        }

        const profile = globals.profiles[fpid];
        const bmr = Util.getBMR(profile);

        let energCoef = this.props.data.globals.energyOutputCoefficients[profile.energy_output_coefficient];

        if(typeof data.appModeData.lectureInfoData.lecture_data.energy_coefficient_id === 'number') {
            const newCoefId = data.appModeData.lectureInfoData.lecture_data.energy_coefficient_id;
            energCoef = this.props.data.globals.energyOutputCoefficients[newCoefId];
        }

        const energyExpTotal = bmr * energCoef.coefficient;

        return (
            <div className="lecturetask-profile-dialog">
                <h3 style={{marginTop:'0'}}>Zadání úkolu</h3>
                <div style={{maxHeight:'250px',overflowY:'auto',border:'1px solid #ccc',padding:'4px',fontSize:'.9em'}} dangerouslySetInnerHTML={{ __html: this.props.assignmentText }}></div>
                <h3>Profil</h3>
                <p><b>{profile.name}</b></p>
                <table>
                    <tbody>
                        <tr>
                            <td>Výška:</td>
                            <td>{profile.height} cm</td>
                        </tr>
                        <tr>
                            <td>Váha:</td>
                            <td>{profile.weight} kg</td>
                        </tr>
                        <tr>
                            <td>Obvod pasu:</td>
                            <td>{profile.diameter_waist} cm</td>
                        </tr>
                        <tr>
                            <td>Obvod boků:</td>
                            <td>{profile.diameter_sides} cm</td>
                        </tr>           
                        <tr>
                            <td>BMR:</td>
                            <td>{bmr} kcal / {Math.round(bmr * 4.18)} kJ</td>
                        </tr>
                        <tr>
                            <td>Výdej energie:</td>
                            <td>{Math.round(energyExpTotal)} kcal / {Math.round(energyExpTotal * 4.18)} kJ</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogAssignmentProfileInfo));
