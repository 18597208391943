import React from 'react';
import store from 'store/index';
import Util from 'utility';

export default class TaskEvaluator  // extends Component
{
    constructor(callbacks) {
        //super(props);
        this.callbacks = callbacks;
        this.lectureTaskAssistantDDD = this.lectureTaskAssistantDDD.bind(this);
        this.showTaskListDDDInfo = this.showTaskListDDDInfo.bind(this);
    }

    lectureTaskAssistantDDD() {
    }

    showTaskListDDDInfo(taskListDDDInfo, id, type, e) {
        this.callbacks['dddInfo'](taskListDDDInfo, id, type);
    }

    evaluate(parameters) 
    {
        const data = store.getState();
        const taskData = data.lectureTaskData;
        const lectureInfo = parameters.lectureInfo;


        let profileId = Util.getActiveProfileId();
        const baseProfileId = profileId;
        if(typeof data.appModeData.profileId === 'number') {
            profileId = data.appModeData.profileId;
        }

        let evalResults = false;
        let mealsResults = false;

        if(typeof data.evaluationResults === 'object') {
            evalResults = data.evaluationResults.results;
            mealsResults = data.mealsEvaluationResults;
            if(typeof parameters.mode !== 'undefined' && parameters.mode === 'meal') {
                mealsResults = {};
                mealsResults[parameters.mealHash] = evalResults;
            }
        }

        let taskListMeals = [];
        let taskListMealsOk = [];
        let taskListUndesirableFoodsUsed = [];
        let taskListNumberOfFoods = [];
        let taskListNumberOfFoodsOk = [];
        let taskListDDD = [];
        let taskListDDDOk = [];

        let taskListDDDInfo = {
            'categories': {},
            'tags': {}
        };

        // Meal types taste

        const tasteTitles = {'sweet': 'sladká', 'salty': 'slaná'};
        if(Array.isArray(taskData.lecture_data.meal_types)) {
            taskData.lecture_data.meal_types.map(m => {
                if(m.type*1 > 0) {
                    if(m.taste != '') {

                        // Check this meal's taste

                        let mealHash = m.type + '|' + profileId;

                        if(typeof parameters.mealHash !== 'undefined') {
                            if(parameters.mealHash !== mealHash) return;
                        }

                        // Switch to user's profile if current profileId has no meals

                        if(typeof data.foods[mealHash] === 'undefined') {
                            let myOwnProfileId = 0;
                            Object.keys(data.globals.profiles).map(pid => {
                                if(myOwnProfileId > 0) return;
                                const profile = data.globals.profiles[pid];
                                if(profile.id != profileId) {
                                    myOwnProfileId = profile.id;
                                }
                            });

                            mealHash = m.type + '|' + myOwnProfileId;
                        }

                        let tasteOk = false;
                        let resultingTaste = 'sweet';
                        let hasFoods = false;

                        if(Array.isArray(data.foods[mealHash])) {
                            tasteOk = true;
                            if(data.foods[mealHash].length > 0) {
                                hasFoods = true;
                                data.foods[mealHash].map(f => {
                                    const food = data.globals.itemData[f.food_id];
                                    if(food.taste === 1) resultingTaste = 'salty';
                                });
                            }
                        }

                        // Try mealHash with user's own profile (not the one selected in lecture admin)
                        // if last mealHash meal does not exist
                        const userProfileId = Util.getFirstProfileId();
                        mealHash = m.type + '|' + userProfileId;
                        if(Array.isArray(data.foods[mealHash])) {
                            tasteOk = true;
                            if(data.foods[mealHash].length > 0) {
                                hasFoods = true;
                                data.foods[mealHash].map(f => {
                                    const food = data.globals.itemData[f.food_id];
                                    if(food.taste === 1) resultingTaste = 'salty';
                                });
                            }
                        }


                        tasteOk = hasFoods && resultingTaste === m.taste;
                        if(tasteOk) {
                            taskListMealsOk.push(
                                <div className="lecturetask-task ok">
                                    <span><i className="fa fa-check"></i></span>
                                    <p>
                                        {data.globals.mealTypes[m.type*1].title}: {tasteTitles[m.taste]}
                                    </p>
                                </div>
                            );
                        } else {
                            taskListMeals.push(
                                <div className="lecturetask-task fail">
                                    <span><i className="fa fa-times"></i></span>
                                    <p>
                                        {data.globals.mealTypes[m.type*1].title}: {tasteTitles[m.taste]}
                                    </p>
                                </div>
                            );
                        }
                    }
                }
            });
        }

        // Meals setup (GI/GN/ST/TE/AC/EN)

        if(typeof evalResults !== 'undefined') {

            const keyToAttr = {
                'gi': 'gi',
                'gn': 'gn',
                'st': 'digestibility',
                'te': 'thermic_effect',
                'ac': 'acidobasicity',
                'en': 'energy',
            };

            var n = 0;
            taskData.lecture_data.meal_types.map(m => {
                if(m.type == 0) return;
                
                let mealHash = m.type + '|' + profileId;
                let ms = taskData.meal_types_setup[n];
                let mealName = data.globals.mealTypes[m.type].title;

                Object.keys(keyToAttr).map(k => {

                    /*
                    if(typeof mealsResults[mealHash] === 'undefined') {
                        mealHash = m.type + '|' + baseProfileId;
                    }
                    */

                    if(typeof mealsResults[mealHash] === 'undefined') return;

                    const attr = keyToAttr[k];

                    let value = mealsResults[mealHash][attr] * 1;
                    if(!value) value = mealsResults[mealHash][attr + '_n'] * 1;

                    let valueLimited = value;   // value limited to attr's key range 

                    let minRaw = ms[k].from;
                    let maxRaw = ms[k].to;

                    let min = +minRaw;
                    let max = +maxRaw;

                    let permittedValueText = <span>{min}-{max}</span>;
                    let actualValueText = <span>{value}</span>;

                    let evalK = attr;
                    if(evalK === 'gn') evalK = 'gn_for_item';
                    if(evalK === 'thermic_effect') evalK = 'thermiceffect';

                    if(typeof data.globals.evaluationTexts[evalK] !== 'undefined') {
                        const t = data.globals.evaluationTexts[evalK];
                        if(typeof t[min] !== 'undefined') {
                            permittedValueText = <span>{t[min]}</span>;

                            valueLimited = value;
                            let keysRange = Object.keys(t);
                            keysRange = keysRange.map(n => n * 1);
                            if(valueLimited < Math.min(...keysRange)) valueLimited = Math.min(...keysRange);
                            if(valueLimited > Math.max(...keysRange)) valueLimited = Math.max(...keysRange);
                            actualValueText = <span>{t[valueLimited]}</span>;
                        } else {
                            Object.keys(t).map(rk => {
                                let range = rk.split(',');
                                range[0] = range[0] * 1;
                                range[1] = range[1] * 1;
                                if(min >= range[0] && min <= range[1]) {
                                    permittedValueText = t[rk];
                                }
                                if(value >= range[0] && value <= range[1]) {
                                    actualValueText = t[rk];
                                }
                            });
                        }
                    }

                    let thisMealFoods = data.foods[mealHash];

                    // is this mealHash does not exist, try find one with the baseProfileId instead
                    if(typeof thisMealFoods === 'undefined' || thisMealFoods.length == 0) {


                        const personalProfileId = Util.getFirstProfileId();
                        mealHash = m.type + '|' + personalProfileId;
                    }

                    thisMealFoods = data.foods[mealHash];

                    if(typeof thisMealFoods === 'undefined' || thisMealFoods.length == 0) {

                        // If this meal is empty...
                        if(minRaw != '' && maxRaw != '') {
                            // And there are actual values in task instructions,
                            // mark the task as not completed without any further explanation)
                            taskListMeals.push(
                                <div className="lecturetask-task fail">
                                    <span><i className="fa fa-times"></i></span>
                                    <p>
                                        {mealName}: {data.globals.attributes[attr]}{'\u00A0'}
                                        ({permittedValueText})
                                    </p>
                                </div>
                            );
                        }

                    } else {

                        // Else, check if values are okay

                        if(minRaw != '' && maxRaw != '') {

                            let valRealLimited = valueLimited;

                            if(typeof data.globals.evaluationRealLimits[evalK] !== 'undefined') {
                                const rl = data.globals.evaluationRealLimits[evalK];
                                const realMin = Math.min(...rl);
                                const realMax = Math.max(...rl);
                                if(valRealLimited < realMin) valRealLimited = realMin;
                                if(valRealLimited > realMax) valRealLimited = realMax;
                            }

                            if(valRealLimited >= min && valRealLimited <= max) {
                                taskListMealsOk.push(
                                    <div className="lecturetask-task ok">
                                        <span><i className="fa fa-check"></i></span>
                                        <p>
                                            {mealName}: {data.globals.attributes[attr]}{'\u00A0'}
                                            ({permittedValueText})
                                        </p>
                                    </div>
                                );
                            } else {
                                taskListMeals.push(
                                    <div className="lecturetask-task fail">
                                        <span><i className="fa fa-times"></i></span>
                                        <p>
                                            {mealName}: {data.globals.attributes[attr]}{'\u00A0'}
                                            ({permittedValueText}, máte {actualValueText})
                                        </p>
                                    </div>
                                );
                            }
                        }

                    }
                });
                n++;
            });
        }


        // Meals - forbidden foods used?

        const undesirableFoods = lectureInfo.undesirable_foods;
        let undesirableFoodsInternalIds = [];

        if(typeof undesirableFoods === 'object') {
            Object.keys(data.foods).map(meal => {
                let m = meal.split('|')[0];
                if(typeof data.globals.mealTypes[m] === 'undefined') {
                    return;
                }
                let mealName = data.globals.mealTypes[m].title;
                let mealOk = true;
                Object.keys(data.foods[meal]).map(f => {
                    const food = data.foods[meal][f];
                    if(undesirableFoods.includes(food.food_id)) {
                        undesirableFoodsInternalIds.push(food.id);
                        mealOk = false;
                    }
                });



                if(!mealOk) {

                    if(typeof parameters.mode !== 'undefined' && parameters.mode === 'meal') {

                        if(meal === parameters.mealHash) {
                            taskListUndesirableFoodsUsed.push(
                                <div className="lecturetask-task fail">
                                    <span><i className="fa fa-times"></i></span>
                                    <p>
                                        {mealName}: použity nepovolené potraviny
                                    </p>
                                </div>
                            );
                        }

                    } else {

                        taskListUndesirableFoodsUsed.push(
                            <div className="lecturetask-task fail">
                                <span><i className="fa fa-times"></i></span>
                                <p>
                                    {mealName}: použity nepovolené potraviny
                                </p>
                            </div>
                        );

                    }
                }
            });
        }

        // Exact number of foods from individual categories

        if(Array.isArray(Object.keys(taskData.lecture_data.categories_foods_numbers))) {

            // Go through all the used foods and count
            let foodInCategory = {};
            let mealAlreadyCounted = {};
            Object.keys(data.foods).map(meal => {
                Object.keys(data.foods[meal]).map(f => {

                    const baseFood = data.foods[meal][f];
                    let foodsArray = data.globals.itemSubItems[baseFood.food_id] ?? [baseFood.food_id];

                    if(undesirableFoodsInternalIds.includes(baseFood.id)) return;

                    foodsArray.map(foodId => {
                        let food = data.globals.itemData[foodId];
                        food.food_id = foodId;

                        // const food = data.foods[meal][f];
                        data.globals.itemToCategory[food.food_id].map(cid => {
                            Object.keys(data.globals.categoryHierarchy).map(cid2 => {
                                if(data.globals.categoryHierarchy[cid2].includes(cid)) {
                                    if(typeof foodInCategory[cid2] === 'undefined') {
                                        foodInCategory[cid2] = 0;
                                    }
                                    const h = cid2 + '|' + meal;
                                    if(typeof mealAlreadyCounted[h] === 'undefined') {
                                        mealAlreadyCounted[h] = 1;
                                        foodInCategory[cid2] += 1;
                                    }

                                    if(typeof taskListDDDInfo['categories'][cid2] === 'undefined') {
                                        taskListDDDInfo['categories'][cid2] = [];
                                    }


                                    let addThis = true;
                                    /*
                                    taskListDDDInfo['categories'][cid2].map(i => {
                                        if(i.baseFood.food_id === baseFood.food_id) {
                                            addThis = false;
                                        }
                                    });
                                    */

                                    if(addThis) {
                                        const ms = meal.split('|')[0];



                                        taskListDDDInfo['categories'][cid2].push({
                                            'food': food,
                                            'baseFood': baseFood,
                                            'meal': data.globals.mealTypes[ms].title,
                                            'meal_id': ms
                                        });
                                    }
                                }
                            });
                        });
                    });
                });
            });

            Object.keys(taskData.lecture_data.categories_foods_numbers).map(c => {
                let n = taskData.lecture_data.categories_foods_numbers[c];

                if(n.trim() == '') return;

                let limitInfo = '';
                let limitMin = 0, limitMax = 0;
                if(n.indexOf('-') >= 0) {
                    n = n.split('-');
                    limitMin = n[0] * 1;
                    limitMax = n[1] * 1;
                    limitInfo = limitMin + 'x - ' + limitMax + 'x ';
                } else {
                    limitMin = n * 1;
                    limitMax = n * 1;
                    limitInfo = limitMin + 'x ';
                }

                if(limitMin >= 0 && limitMax >= 0) {
                    let countOk = false;
                    let symbol = false;
                    if(typeof foodInCategory[c*1] !== 'undefined') {
                        let fc = foodInCategory[c*1] * 1;
                        if(fc >= limitMin && fc <= limitMax) {
                            countOk = true;
                        } else {
                            if(fc < limitMin) symbol = <span>zbývá {limitMin - fc}x</span>;
                            if(fc > limitMax) symbol = <span>přebývá {fc - limitMax}x</span>;
                            limitInfo = symbol;
                        }
                    } else {
                        if(limitMin === 0 && limitMax === 0) countOk = true;
                    }

                    if(countOk) {
                        taskListNumberOfFoodsOk.push(
                            <div className="lecturetask-task ok">
                                <span><i className="fa fa-check"></i></span>
                                <p>
                                    {data.globals.foodCategories[c*1].title}: <a style={{borderBottom:'1px dotted #aaa'}} onClick={(e) => this.showTaskListDDDInfo(taskListDDDInfo, c, 'category', e)}>{limitInfo}</a>
                                </p>
                            </div>
                        );
                    } else {
                        taskListNumberOfFoods.push(
                            <div className="lecturetask-task fail">
                                <span><i className="fa fa-times"></i></span>
                                <p>
                                    {data.globals.foodCategories[c*1].title}: <a style={{borderBottom:'1px dotted #aaa'}} onClick={(e) => this.showTaskListDDDInfo(taskListDDDInfo, c, 'category', e)}>{limitInfo}</a>
                                </p>
                            </div>
                        );
                    }
                }
            });
        }

        // Exact number of foods from tags

        if(Array.isArray(Object.keys(taskData.lecture_data.tags_foods_numbers))) {

            // Go through all the used foods and count
            let foodInTag = {};
            let foodTotalAmountInTag = {};
            let mealAlreadyCounted = {};
            let baseFoodsAlreadyAddedToDrink = [];
            Object.keys(data.foods).map(meal => {
                Object.keys(data.foods[meal]).map(f => {

                    const baseFood = data.foods[meal][f];
                    const baseFoodWeight = baseFood.weight * 1;  // For drinks (take this base weight, not the one from FoodSubfood)

                    //let foodsArray = [data.globals.itemSubItems[baseFood.food_id] ?? [baseFood.food_id];
                    //let foodsArray = [baseFood.food_id, ...data.globals.itemSubItems[baseFood.food_id]] ?? [baseFood.food_id];

                    let foodsArray = [baseFood.food_id];
                    if(typeof data.globals.itemSubItems[baseFood.food_id] === 'object') {
                        foodsArray = [baseFood.food_id, ...data.globals.itemSubItems[baseFood.food_id]];
                    }

                    if(undesirableFoodsInternalIds.includes(baseFood.id)) return;

                    foodsArray.map(foodId => {
                        let food = data.globals.itemData[foodId];
                        food.food_id = foodId;

                        if(typeof data.globals.foodToTags[food.food_id] === 'undefined') return;

                        data.globals.foodToTags[food.food_id].map(tagId => {

                            if(typeof foodInTag[tagId] === 'undefined') {
                                foodInTag[tagId] = 0;
                            }

                            const h = tagId + '|' + meal;
                            if(typeof mealAlreadyCounted[h] === 'undefined') {
                                mealAlreadyCounted[h] = 1;
                                foodInTag[tagId] += 1;
                            }

                            //

                            if(typeof taskListDDDInfo['tags'][tagId] === 'undefined') {
                                taskListDDDInfo['tags'][tagId] = [];
                            }


                            let addThis = true;
                            taskListDDDInfo['tags'][tagId].map(i => {
                                if(i.baseFood.food_id === baseFood.food_id) {
                                //    addThis = false;
                                }
                            });

                            if(addThis) {
                                if(tagId === 5) {
                                    // Pokud je to pitny rezim, pridat pouze pokud 
                                    // je tak oznacena potravina v receptu
                                    if(baseFood.is_drink === 1) {
                                        const ms = meal.split('|')[0];
                                        taskListDDDInfo['tags'][tagId].push({
                                            'food': food,
                                            'baseFood': baseFood,
                                            'meal': data.globals.mealTypes[ms].title,
                                            'meal_id': ms
                                        });
                                    }
                                } else {
                                    const ms = meal.split('|')[0];
                                    taskListDDDInfo['tags'][tagId].push({
                                        'food': food,
                                        'baseFood': baseFood,
                                        'meal': data.globals.mealTypes[ms].title,
                                        'meal_id': ms
                                    });
                                }
                            }

                            //

                            if(typeof foodTotalAmountInTag[tagId] === 'undefined') {
                                foodTotalAmountInTag[tagId] = 0;
                            }

                            if(tagId === 5) {
                                if(baseFood.is_drink === 1) {
                                    if(!baseFoodsAlreadyAddedToDrink.includes(baseFood.id)) {
                                        foodTotalAmountInTag[tagId] += baseFoodWeight; //food.weight * 1;
                                        baseFoodsAlreadyAddedToDrink.push(baseFood.id);
                                    }
                                }
                            } else {
                                foodTotalAmountInTag[tagId] += food.weight * 1;
                            }
                        });
                    });
                });
            });

            Object.keys(taskData.lecture_data.tags_foods_numbers).map(c => {
                let n = taskData.lecture_data.tags_foods_numbers[c];

                if(n.trim() == '') return;

                let limitInfo = '';
                let limitMin = 0, limitMax = 0;
                if(n.indexOf('-') >= 0) {
                    n = n.split('-');
                    limitMin = n[0].replace(',', '.') * 1;
                    limitMax = n[1].replace(',', '.') * 1;
                    limitInfo = limitMin + 'x - ' + limitMax + 'x ';

                    if(c == 5) {    // pitny rezim
                        limitInfo = limitMin + ' - ' + limitMax + ' l';
                    }
                } else {
                    limitMin = n * 1;
                    limitMax = n * 1;
                    limitInfo = limitMin + 'x ';
                }

                if(limitMin >= 0 && limitMax >= 0) {
                    let countOk = false;
                    let symbol = false;
                    if(typeof foodInTag[c*1] !== 'undefined') {
                        let fc = foodInTag[c*1] * 1;

                        if(c*1 === 5) {
                            // pitny rezim - kontroluje se mnozstvi, ne pocet (tj. * 1000 ml)
                            let tv = foodTotalAmountInTag[c*1];
                            if(tv >= (limitMin*1000) && tv <= (limitMax*1000)) {
                                countOk = true;
                            } else {
                                let l1 = limitMin * 1000;
                                let l2 = limitMax * 1000;
                                if(tv < l1) symbol = <span>zbývá {(l1 - tv)/1000} l</span>;
                                if(tv > l2) symbol = <span>přebývá {(tv - l2)/1000} l</span>;
                                limitInfo = symbol;    
                            }
                        } else {
                            // u ostatnich stitku se kontroluje celkovy pocet
                            if(fc >= limitMin && fc <= limitMax) {
                                countOk = true;
                            } else {
                                if(fc < limitMin) symbol = <span>zbývá {limitMin - fc}x</span>;
                                if(fc > limitMax) symbol = <span>přebývá {fc - limitMax}x</span>;
                                limitInfo = symbol;    
                            }
                        }

                    } else {
                        if(limitMin === 0 && limitMax === 0) countOk = true;
                    }

                    if(countOk) {
                        taskListNumberOfFoodsOk.push(
                            <div className="lecturetask-task ok">
                                <span><i className="fa fa-check"></i></span>
                                <p>
                                    {data.globals.foodTags[c*1]}: <a style={{borderBottom:'1px dotted #aaa'}} onClick={(e) => this.showTaskListDDDInfo(taskListDDDInfo, c, 'tag', e)}>{limitInfo}</a>
                                </p>
                            </div>
                        );
                    } else {
                        taskListNumberOfFoods.push(
                            <div className="lecturetask-task fail">
                                <span><i className="fa fa-times"></i></span>
                                <p>
                                    {data.globals.foodTags[c*1]}: <a style={{borderBottom:'1px dotted #aaa'}} onClick={(e) => this.showTaskListDDDInfo(taskListDDDInfo, c, 'tag', e)}>{limitInfo}</a>
                                </p>
                            </div>
                        );
                    }
                }
            });
        }

        // DDD Limits
        
        let taskListDDDSorted = [];
        let taskListDDDSortedOk = [];

        if(typeof evalResults !== 'undefined') {
            if(Array.isArray(Object.keys(taskData.ddd_limits))) {
                Object.keys(taskData.ddd_limits).map(attr => {
                    const limit = taskData.ddd_limits[attr];
                    let eMin = limit[0] * 1;
                    let eMax = limit[1] * 1;

                    // ATTN: Terminology mismatch
                    if(attr === 'fats_unsaturated') attr = 'fats_unsaturated_total';

                    if(attr === 'kcal_kj') {
                        let kcal = evalResults['energy_kcal'];
                        let kj = evalResults['energy_kj'];

                        const attr1 = 'energy_kcal';
                        const attr2 = 'energy_kj';

                        let evalOk = false;
                        if(typeof evalResults !== 'undefined') {
                            if(typeof evalResults[attr1] !== 'undefined') {
                                if(evalResults[attr1] >= eMin && evalResults[attr1] <= eMax) {
                                    evalOk = true;
                                }
                            }
                        }

                        let haveInfoKcal = <span style={{fontSize:'10px'}}>(máte {evalResults[attr1]})</span>;
                        let haveInfoKj = <span style={{fontSize:'10px'}}>(máte {evalResults[attr2]})</span>;
                        let attrOrder = data.globals.attributes_order[attr];

                        if(evalOk) {
                            taskListDDDOk.push({
                                'element':
                                <div className="lecturetask-task ok">
                                    <span><i className="fa fa-check"></i></span>
                                    <p>
                                        {data.globals.attributes[attr1]}:{'\u00A0'}
                                        <span className="ddd-limits">
                                            {limit[0]}
                                            {'\u00A0'}-{'\u00A0'} 
                                            {limit[1]}
                                        </span>
                                        {'\u00A0'}
                                        {haveInfoKcal}
                                    </p>
                                </div>,
                                'order': attrOrder
                            });

                            taskListDDDOk.push({
                                'element':
                                <div className="lecturetask-task ok">
                                    <span><i className="fa fa-check"></i></span>
                                    <p>
                                        {data.globals.attributes[attr2]}:{'\u00A0'}
                                        <span className="ddd-limits">
                                            {Math.round(limit[0] * 4.18)}
                                            {'\u00A0'}-{'\u00A0'} 
                                            {Math.round(limit[1] * 4.18)}
                                        </span>
                                        {'\u00A0'}
                                        {haveInfoKj}
                                    </p>
                                </div>,
                                'order': attrOrder
                            });
                        } else {

                            if(evalResults[attr] < eMin) {
                                // Navyseni

                                taskListDDD.push({
                                    'element': 
                                        <div className="lecturetask-task fail">
                                            <span><i className="fa fa-times"></i></span>
                                            <p>
                                                <a onClick={(e) => this.lectureTaskAssistantDDD(e, attr1)}>
                                                    {data.globals.attributes[attr1]}:{'\u00A0'}
                                                    <span className="ddd-limits">
                                                        {limit[0]}
                                                        {'\u00A0'}-{'\u00A0'} 
                                                        {limit[1]}
                                                    </span>
                                                    {'\u00A0'}
                                                    {haveInfoKcal}
                                                </a>
                                            </p>
                                        </div>,
                                    'order': attrOrder
                                });

                                taskListDDD.push({
                                    'element': 
                                        <div className="lecturetask-task fail">
                                            <span><i className="fa fa-times"></i></span>
                                            <p>
                                                <a onClick={(e) => this.lectureTaskAssistantDDD(e, attr2)}>
                                                    {data.globals.attributes[attr2]}:{'\u00A0'}
                                                    <span className="ddd-limits">
                                                        {Math.round(limit[0] * 4.18)}
                                                        {'\u00A0'}-{'\u00A0'} 
                                                        {Math.round(limit[1]* 4.18)} {data.globals.attribute_units[attr2]}
                                                    </span>
                                                    {'\u00A0'}
                                                    {haveInfoKj}
                                                </a>
                                            </p>
                                        </div>,
                                    'order': attrOrder
                                });

                            } else {
                                // Snizeni

                                taskListDDD.push({
                                    'element': 
                                        <div className="lecturetask-task fail">
                                            <span><i className="fa fa-times"></i></span>
                                            <p>
                                                <a onClick={(e) => this.lectureTaskAssistantDDD(e, attr1, true)}>
                                                    {data.globals.attributes[attr1]}:{'\u00A0'}
                                                    <span className="ddd-limits">
                                                        {limit[0]}
                                                        {'\u00A0'}-{'\u00A0'} 
                                                        {limit[1]}
                                                    </span>
                                                    {'\u00A0'}
                                                    {haveInfoKcal}
                                                </a>
                                            </p>
                                        </div>,
                                    'order': attrOrder
                                });

                                taskListDDD.push({
                                    'element': 
                                        <div className="lecturetask-task fail">
                                            <span><i className="fa fa-times"></i></span>
                                            <p>
                                                <a onClick={(e) => this.lectureTaskAssistantDDD(e, attr2, true)}>
                                                    {data.globals.attributes[attr2]}:{'\u00A0'}
                                                    <span className="ddd-limits">
                                                        {Math.round(limit[0] * 4.18)}
                                                        {'\u00A0'}-{'\u00A0'} 
                                                        {Math.round(limit[1] * 4.18)}
                                                    </span>
                                                    {'\u00A0'}
                                                    {haveInfoKj}
                                                </a>
                                            </p>
                                        </div>,
                                    'order': attrOrder
                                });
                            }
                        }
                    }

                    if(typeof data.globals.attributes[attr] !== 'undefined') {

                        let evalOk = false;
                        if(typeof evalResults !== 'undefined') {
                            if(typeof evalResults[attr] !== 'undefined') {
                                if(evalResults[attr] >= eMin && evalResults[attr] <= eMax) {
                                    evalOk = true;
                                }
                            }
                        }

                        let haveInfo = <span style={{fontSize:'10px'}}>(máte {evalResults[attr]} {data.globals.attribute_units[attr]})</span>;

                        /*
                        if(typeof parameters.mode !== 'undefined' && parameters.mode === 'meal') {
                            haveInfo = <span>{evalResults[attr]} {data.globals.attribute_units[attr]}</span>;
                        }
                        */


                        let attrOrder = data.globals.attributes_order[attr];

                        if(evalOk) {
                            taskListDDDOk.push({
                                'element':
                                <div className="lecturetask-task ok">
                                    <span><i className="fa fa-check"></i></span>
                                    <p>
                                        {data.globals.attributes[attr]}:{'\u00A0'}
                                        <span className="ddd-limits">
                                            {limit[0]} {data.globals.attribute_units[attr]}
                                            {'\u00A0'}-{'\u00A0'} 
                                            {limit[1]} {data.globals.attribute_units[attr]}
                                        </span>
                                        {'\u00A0'}
                                        {haveInfo}
                                    </p>
                                </div>,
                                'order': attrOrder
                            });
                        } else {

                            if(evalResults[attr] < eMin) {
                                // Navyseni

                                taskListDDD.push({
                                    'element': 
                                        <div className="lecturetask-task fail">
                                            <span><i className="fa fa-times"></i></span>
                                            <p>
                                                <a onClick={(e) => this.lectureTaskAssistantDDD(e, attr)}>
                                                    {data.globals.attributes[attr]}:{'\u00A0'}
                                                    <span className="ddd-limits">
                                                        {limit[0]} {data.globals.attribute_units[attr]}
                                                        {'\u00A0'}-{'\u00A0'} 
                                                        {limit[1]} {data.globals.attribute_units[attr]}
                                                    </span>
                                                    {'\u00A0'}
                                                    {haveInfo}
                                                </a>
                                            </p>
                                        </div>,
                                    'order': attrOrder
                                });

                            } else {
                                // Snizeni

                                taskListDDD.push({
                                    'element': 
                                        <div className="lecturetask-task fail">
                                            <span><i className="fa fa-times"></i></span>
                                            <p>
                                                <a onClick={(e) => this.lectureTaskAssistantDDD(e, attr, true)}>
                                                    {data.globals.attributes[attr]}:{'\u00A0'}
                                                    <span className="ddd-limits">
                                                        {limit[0]} {data.globals.attribute_units[attr]}
                                                        {'\u00A0'}-{'\u00A0'} 
                                                        {limit[1]} {data.globals.attribute_units[attr]}
                                                    </span>
                                                    {'\u00A0'}
                                                    {haveInfo}
                                                </a>
                                            </p>
                                        </div>,
                                    'order': attrOrder
                                });
                            }
                        }
                    }
                });
            }
        }

        // sort DDD tasklist by attribute order

        taskListDDD.sort((a, b) => {
            if(a.order === b.order) return 0;
            if(a.order < b.order) return -1;
            return 1;
        });

        taskListDDDOk.sort((a, b) => {
            if(a.order === b.order) return 0;
            if(a.order < b.order) return -1;
            return 1;
        });

        taskListDDD.map(e => taskListDDDSorted.push(e.element));
        taskListDDDOk.map(e => taskListDDDSortedOk.push(e.element));

        return {
            'taskListMeals': taskListMeals,
            'taskListMealsOk': taskListMealsOk,
            'taskListUndesirableFoodsUsed': taskListUndesirableFoodsUsed,
            'taskListNumberOfFoods': taskListNumberOfFoods,
            'taskListNumberOfFoodsOk': taskListNumberOfFoodsOk,
            'taskListDDD': taskListDDDSorted,
            'taskListDDDOk': taskListDDDSortedOk,
            'taskListDDDInfo': taskListDDDInfo,
        };

    }
}
