import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDay, format } from 'date-fns';
import store from 'store/index';
import api from 'api';

import { 
    setEvaluationResults, 
    setDayEvaluationResults 
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

class Util extends Component {
    constructor(props) {
        super(props);
        this.doLoadEvaluation = this.doLoadEvaluation.bind(this);
        this.doLoadDayEvaluation = this.doLoadDayEvaluation.bind(this);
        this.getFirstProfileId = this.getFirstProfileId.bind(this);
    }

    static isReadOnly() {
        const state = store.getState();
        let readOnly = false;
        if(typeof state.readonly !== "undefined") {
            readOnly = state.readonly;
        }
        return readOnly;
    }

    static getSessionIdentifier() {
        const state = store.getState();
        let sessionIdentifier = '';
        if(typeof state.sessionIdentifier !== "undefined") {
            sessionIdentifier = state.sessionIdentifier;
        }
        return sessionIdentifier;
    }


    static apiRoot() {
        return 'https://www.zofapp.cz/api2';
    }

    static saveCurrentRecipeLocal() {
        const state = store.getState();
        let unsavedRecipe = {
            'foodGroups': state.foodGroups,
            'foods': state.foods,
            'titles': state.titles,
            'descriptions': state.descriptions,
        };
        localStorage.setItem('zof_unsaved_recipe', JSON.stringify(unsavedRecipe));
    }


    static saveDayPlan(callback) {
        const state = store.getState();

        let saveData = {};
        saveData.profile_id = state.profileId;
        saveData.date = JSON.stringify(state.plannerDate);
        saveData.titles = JSON.stringify(state.titles);
        saveData.meals = JSON.stringify(state.meals);
        saveData.groups = JSON.stringify(state.foodGroups);
        saveData.foods = JSON.stringify(state.foods);
        saveData.modifications = JSON.stringify(state.modifications);
        saveData.energyOutputSettings = JSON.stringify(state.energyOutputSettings);
        saveData.activities = JSON.stringify(state.activities);
        saveData.trainings = JSON.stringify(state.trainings);
        saveData = JSON.stringify(saveData);

        api('save/dayplan', {post:true, data: saveData}).then(result => {
            result = result.data;
            callback(result);
        });
    }

    static doLoadEvaluation(profileId) {
        if(!profileId || profileId === "undefined") {
            const state = store.getState();
            profileId = state.autoEvaluateProfileId;
        }

        let state = store.getState();
        //if(state.autoEvaluate !== true) return;
        let stateSnapshot = Object.assign({}, state);
        stateSnapshot.selectedProfileId = profileId;
        delete stateSnapshot.globals;
        delete stateSnapshot.modalContent;
        delete stateSnapshot.evaluationResults;

        api('evaluation/meal', { post: true, data: stateSnapshot } ).then(result => {
            result = result.data;
            store.dispatch(setEvaluationResults({ 
                'results': result.evaluation, 
                'ddd': result['ddd'] 
            }));
        });
    }

    static nutritionRegimeIdToTagId(regimeId) {

        // ATTN: make dynamic
        const convert = {
            '1': 103,
            '10': 93,
            '20': 95,
            '30': 94,
            '40': 96,
            '50': 91,
            '60': 92,
        };

        regimeId = "" + regimeId;
        if(typeof convert[regimeId] === 'undefined') return 0;

        return convert[regimeId];
    }


    static readCookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
        return false;
    }

    static writeCookie(name, value, expiryDays) {
        let expiry = new Date();
        expiry.setDate(expiry.getDate() + expiryDays);
        document.cookie = name + '=' + value + '; expires=' + expiry.toUTCString() + '; path=/';
    }

    static userHasPurchased(itemKey) {
        const state = store.getState();
        return itemKey in state.globals.purchases;
    }


    static doLoadDayEvaluation(profileId, callback) {
        let state = store.getState();

        if(!profileId || profileId === 'undefined') {
            profileId = Object.keys(state.globals.profiles)[0];
        }

        let stateSnapshot = Object.assign({}, state);
        stateSnapshot.selectedProfileId = profileId;

        if(state.appMode === 'LECTURE') {
            const fpid = this.getFirstProfileId();
            stateSnapshot.lectureTaskProfileId =  state.globals.profiles[fpid].id;
        }

        delete stateSnapshot.globals;
        delete stateSnapshot.modalContent;
        delete stateSnapshot.evaluationResults;

        api('evaluation/menu', { post: true, data: stateSnapshot } ).then(result => {
            result = result.data;
            store.dispatch(setDayEvaluationResults({
                'results': {
                    'results': result['evaluation'],
                    'ddd': result['ddd']
                },
                'mealsResults': result['meals_evaluation']
            }));

            if(callback) callback();
        });
    }

    static getActiveProfileId()
    {
        const state = store.getState();
        let activeProfileId = state.profileId;
        if(activeProfileId === 0) {
            activeProfileId = Object.keys(state.globals.profiles)[0];
        }
        return activeProfileId;
    }

    /*
    * Get first profile ID
    *
    * Skips any LectureTask-based profiles, ie. first profile must be user's profile
    */
    static getFirstProfileId()
    {
        const state = store.getState();
        let primaryProfileId = -1;
        let firstProfileId = -1;

        let lectureTaskProfileId = -1;
        if(typeof state.appModeData.profileId === 'number') {
            lectureTaskProfileId = state.appModeData.profileId;
        }

        Object.keys(state.globals.profiles).map(profileId => {
            const profile = state.globals.profiles[profileId];
            if(profileId === lectureTaskProfileId) return;
            if(firstProfileId < 0) firstProfileId = profileId;
            if(profile.is_primary == 1 && primaryProfileId < 0) {
                primaryProfileId = profileId;
            }
        });
        if(primaryProfileId > 0) {
            return primaryProfileId;
        }

        if(firstProfileId > 0) {
            return firstProfileId;
        }
        return 0;
    }
    
    static parseRecipeDescription(t)
    {
        if(t.trim() === '') return false;
        let result = [];
        let currentChunk = [];
        let lines = t.split("\n");
        lines.forEach(line => {
            if(line.trim() == '') return;
            let m = line.match(/^([0-9]+)(\.|\:)([^\n]+)/i);
            if(m) {

                let chunkText = m[3];
                let m2 = chunkText.match(/^(\s)?Příprava(.*?)\:(.*?)$/);
                if(m2) {
                    chunkText = <span><b>Příprava {m2[2]}</b>: {m2[3]}</span>;
                }
                currentChunk.push(<div className="recipe-preparation-step"><div className="number"><span>{m[1]}</span></div><div className="text">{chunkText}</div></div>);

            } else {
                if(currentChunk.length > 0) {
                    result.push(<div className="recipe-preparation-steps-chunk">{currentChunk}</div>);
                    currentChunk = [];
                }
                let m2 = line.match(/^\*\*\*([^\n]+)/i);
                if(m2) {
                    result.push(<div className="recipe-preparation-step"><strong><br />{m2[1]}<br /></strong></div>);
                } else {
                    result.push(<div className="recipe-preparation-step">{line}</div>);
                }
            }
            //preg_replace('~^([0-9]+)\.([^\n]+)~is', '<div class="cookbook-preparation-step"><div class="number"><span>\\1</span></div><div class="text">\\2</div></div>', $line);
        });
        if(currentChunk.length > 0) {
            result.push(<div className="recipe-preparation-steps-chunk">{currentChunk}</div>);
            currentChunk = [];
        }

        return result;
    }





    static resetCookbookFilter()
    {
        localStorage.removeItem('zof_cookbook_filters');
    }

    static resetFoodsFilter() 
    {
        localStorage.removeItem('zof_foods_filters');
    }

    static getFormattedDate(date) {
        const dayNames = ["neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota"];
        let weekDay = getDay(date) * 1;
        return dayNames[weekDay] + ' ' + format(date, 'd. M. yyyy');
    }

    static yyyymmdd(date) {
    
        // year
        let yyyy = '' + date.getFullYear();
    
        // month
        let mm = ('0' + (date.getMonth() + 1));  // prepend 0 // +1 is because Jan is 0
        mm = mm.substr(mm.length - 2);                  // take last 2 chars
    
        // day
        let dd = ('0' + date.getDate());         // prepend 0
        dd = dd.substr(dd.length - 2);                  // take last 2 chars
    
        return yyyy + "-" + mm + "-" + dd;
    } 
    
    static getBMR(profile) {
    	const males = ['m', 'ms', 'b1', 'b2', 'b3', 'b4'];
    	const females = ['f', 'fs', 'fp', 'fbf', 'g1', 'g2', 'g3', 'g4'];

        let gender = false;
        if(males.includes(profile.persona_key)) gender = 'm';
        if(females.includes(profile.persona_key)) gender = 'f';

    	if(!gender) return 0;

        if(typeof profile.age === 'undefined') {
            let birth = new Date(profile.birthday);
            let cur = new Date();
            let diff = cur - birth;
            profile.age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
        }

        let bmr = 0;
        if(gender == 'm') {
            bmr = Math.ceil(10 * profile.weight + 6.25 * profile.height - 5 * profile.age + 5);
        }
        if(gender == 'f') {
            bmr = Math.ceil(10 * profile.weight + 6.25 * profile.height - 5 * profile.age - 161);
        }
        if(profile.individual_bmr > 0) bmr = profile.individual_bmr;
        return bmr;
    }

    static pieChart(data, cx, cy, radius, colours) {
        let chartelem = [];
        let max = data.length;
        let sum = 0;
        
        data.map((val) => {
            sum += val;
        });

        let deg = sum / 360;
        if(deg == 0) deg = .01;
        let jung = sum / 2;

        let dx = radius;
        let dy = 0;
        let oldangle = 0;

        for(let i=0; i<max; i++) {
            let angle = oldangle + data[i] / deg;
            let x = Math.cos(angle * Math.PI / 180.0) * radius;
            let y = Math.sin(angle * Math.PI / 180.0) * radius;
            let colour = colours[i];
            let laf = 0;
            if(data[i] > jung) {
                laf = 1;
            }

            let ax = cx + x;
            let ay = cy + y;
            let adx = cx + dx;
            let ady = cy + dy;

            let d = [`M${cx},${cy} L${adx},${ady} A${radius},${radius} 0 ${laf},1 ${ax},${ay} z`];

            chartelem.push(<path d={d} fill={colour} stroke="black" strokeWidth={1} fillOpacity={1} key={i} />);
            dx = x;
            dy = y;
            oldangle = angle;
        }
        
        return chartelem;
    }

    static setPageTitle(title) 
    {
        document.title = title + ' | ZOFapp';
    }

    static nl2br(text)
    {
        return  text.split('\n').map(function(item, key) {
            return (
              <span key={key}>
                {item}
                <br/>
              </span>
            )
          });
    }    
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

export default connect(mapStateToProps)(Util);
