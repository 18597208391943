import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from "date-fns";
import { Link } from 'react-router-dom';
import Loading from 'components/Loading/index';
import Util from 'utility';
import api from 'api';

import { 
    openModal, 
    closeModal,
    showMessage,
    loadGlobals,
    loginUser
} from 'actions/index';

import GeneratorCalendar from './GeneratorCalendar';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        loginUser: data => dispatch(loginUser(data)),
    };
};

class Generator extends Component {
    constructor(props) {
        super(props);

        let profileId = Util.getFirstProfileId();
        let profile = this.props.data.globals.profiles[profileId];

        let initialProfileRegimes = [];
        profile.regimes.map(r => {
            initialProfileRegimes.push(Util.nutritionRegimeIdToTagId(r));
        });

        let allowedNutritionRegimeCombinations = {
            103: [103, 92],
            93: [93, 91],
            95: [95, 91],
            94: [94, 91],
            96: [96, 91],
            91: [91, 103, 93, 95, 94, 96, 92],
            92: [92, 103, 95, 96, 91]
        };

        let nutritionRegimeExcludesList = {
            103: [93, 95, 94, 96],
            93: [95, 94, 96, 103, 92],
            95: [93, 94, 96, 103],
            94: [93, 95, 96, 103, 92],
            96: [93, 95, 94, 103, 92],
            91: [],
            92: [93, 94]
        };

        let initialMealSelection = [];
        let initialMealSelectionRatio = {};
        if(this.props.data.user.id == 1485) {
            initialMealSelection = ['1', '3', '5'];
            initialMealSelectionRatio = {1: '25', 3: '45', 5: '30'};
        }


        this.state = {
            courses: -1,
            regime: -1,
            regimes: initialProfileRegimes,
            clientId: 0,
            mealSelection: initialMealSelection,   // ZOFapp Profi only
            mealSelectionRatio: initialMealSelectionRatio,     // ZOFapp Profi only - % radio of energy value of individual selected meals
            eatingRegimes: [103, 105, 91, 92, 93, 94, 95, 96, 98, 101, 102, 100],
            allowedNutritionRegimes: [103, 105, 91, 92, 93, 94, 95, 96, 98, 101, 102, 100],
            allowedNutritionCombinations: allowedNutritionRegimeCombinations,
            nutritionRegimeExcludes: nutritionRegimeExcludesList,
            vegeRegimesWarning: false,
            energeticRegime: 0,
            drinkRegime: "1",
            desserts: "1",
            clients: {},
            loading: true,
            generating: false,
            inserting: false,
            insertedToDate: false,
            replacing: false,
            replacingMealId: 0,
            replacingPlateId: 0,
            generatorFormData: false,
            generatorData: false,
            generatorClientInsertToDate: false,
            mealsToAdd: [],
            removeMealIds: [],
            monthData: false,
            calendarMonthYear: '',

            saveAsMenuTitle: '',
            saveAsMenuDescription: '',
            saveAsMenuTargetUserId: 0,
            saveAsMenuDone: false
        };

        this.loadClients = this.loadClients.bind(this);
        this.changeClient = this.changeClient.bind(this);
        this.restrictRegimes = this.restrictRegimes.bind(this);
        this.onChange = this.onChange.bind(this);
        this.doGenerate = this.doGenerate.bind(this);        

        this.removeMeal = this.removeMeal.bind(this);
        this.replaceMeal = this.replaceMeal.bind(this);
        this.replacePlate = this.replacePlate.bind(this);
        this.setGeneratorClientInsertDate = this.setGeneratorClientInsertDate.bind(this);
        this.loadMonthData = this.loadMonthData.bind(this);
        this.doImport = this.doImport.bind(this);
        this.openInPlanner = this.openInPlanner.bind(this);

        this.gotoClientMenus = this.gotoClientMenus.bind(this);

        this.saveAsMenu = this.saveAsMenu.bind(this);
    }



    componentDidMount() {
        this.loadClients();
        let reg = this.restrictRegimes(this.state.regimes);
        this.setState({
            regimes: reg
        });

        let d = new Date();
        const m = d.getMonth() + 1;
        const y = d.getFullYear();
        this.loadMonthData(m, y);
    }

    loadClients() {
        api('clients/list', { post: true, data: { filter: {} }}).then((res) =>{
            this.setState({
                loading: false,
                clients: res.data.clients
            });
        });        
    }

    changeClient(e) {
        const clientId = e.target.value;
        let regimeTagIds = [];

        if(clientId == 0) {
            // My profile

            let profileId = Util.getFirstProfileId();
            let profile = this.props.data.globals.profiles[profileId];
    
            profile.regimes.map(r => {
                regimeTagIds.push(Util.nutritionRegimeIdToTagId(r));
            });

            regimeTagIds = this.restrictRegimes(regimeTagIds);

            this.setState({
                clientId: 0,
                regimes: regimeTagIds
            });

            
        } else {
            // Client profile
            
            let client = false;
            Object.keys(this.state.clients).map(c => {
                if(this.state.clients[c].id == clientId) {
                    client = this.state.clients[c];
                }
            });

            let regimes = [];
            if(client && client.profile && client.profile.regimes) {
                regimes = client.profile.regimes;
            }

            regimes.map(r => {
                regimeTagIds.push(Util.nutritionRegimeIdToTagId(r));
            });

            regimeTagIds = this.restrictRegimes(regimeTagIds);
    
            if(client) {
                this.setState({
                    clientId: clientId,
                    regimes: regimeTagIds
                });
            }  
        }
    }

    gotoClientMenus(e, clientId) {
        api('clients/switch-to', { post: true, data: { id: clientId }}).then((res) =>{
            let result = res.data;
            if(typeof result.error !== 'undefined') return;
            localStorage.setItem('zof_profi_return_hash', result.return_hash);
            localStorage.setItem('jwt_token', result.auth_token);
            this.props.loadGlobals(result.startup_data);
            this.props.loginUser({
                user: result.startup_data.user,
                callback: () => {
                    this.props.history.push('/menu');
                }
            });
        });
    }


    restrictRegimes(reg) {
        let state = this.state;

        // Disable non-valid regime combinations
        let allowed = this.state.eatingRegimes; //Object.keys(this.props.data.globals.eatingRegimes);
        reg.map(r => {
            r = r * 1;
            if(typeof this.state.nutritionRegimeExcludes[r] == 'undefined') return;
            this.state.nutritionRegimeExcludes[r].map(excl => {
                allowed = allowed.filter(a => a != excl);
            });
        });

        // Filter currently selected eating regimes based on what is excluded
        reg = reg.filter(r => allowed.includes(r*1));

        // If nothing allowed, reset everything
        if(allowed.length == 0) {
            reg = [];
            allowed = this.state.eatingRegimes; //Object.keys(this.props.data.globals.eatingRegimes);
        }

        state.allowedNutritionRegimes = allowed.map(r => r*1);

        // Warning, if more than one vegetarian regime is selected

        const vegetarianRegimes = [10, 20, 30];
        let numVegeRegimes = 0;
        reg.map(regime => {
            if(vegetarianRegimes.includes(regime)) numVegeRegimes++;
        });

        if(numVegeRegimes > 1) {
            this.setState({
                vegeRegimesWarning: true
            });
        } else {
            this.setState({
                vegeRegimesWarning: false
            });
        }

        return reg;
    }

    onChange(e) {
        let state = this.state;

        switch(e.target.name) {
            case 'meal_energy_ratio[]':
                const id = e.target.dataset.id;
                state.mealSelectionRatio[id] = e.target.value;
                break;
            case 'regimes[]':
                let reg = state.regimes;
                let regimeTagId =  e.target.value * 1;
                reg = reg.filter(i => i !== regimeTagId);
                if(e.target.checked) {
                    reg.push(regimeTagId);
                }

                state.regimes = this.restrictRegimes(reg);
                break;
            case 'meal_selection[]':
                let ms = state.mealSelection;
                let msr = state.mealSelectionRatio;
                ms = ms.filter(i => i !== e.target.value);
                if(e.target.checked) {
                    ms.push(e.target.value);
                } else {
                    // If meal is unchecked, remove energy ratio aswell
                    msr[e.target.value] = 0;
                }
                state.mealSelection = ms;
                state.mealSelectionRatio = msr;
                break;
            default:
                state[e.target.name] = e.target.value;
        }

        this.setState(state);
    }

    doGenerate(e) {
        e.preventDefault();


        // Reload month data for selected user just before generating new menu

        let calMY = this.state.monthDataKey.split('-');
        this.loadMonthData(calMY[1], calMY[0]);


        let activeProfileId = this.props.data.profileId;
        if(activeProfileId == 0) {
            activeProfileId = Object.keys(this.props.data.globals.profiles)[0];
        }
        
        if(Util.userHasPurchased('ZOF_PROFI')) {
            // ZOFapp Profi meal selection
            if(this.state.mealSelection.length == 0 || this.state.regimes.length == 0) {
                alert("Vyberte prosím chod(y) a stravovací režim.");
                return;
            }
        } else {
            // ZOFapp Personal meal selection (just a number of meals)
            if(this.state.courses < 0 || this.state.regime < 0) {
                alert("Vyberte prosím počet chodů a stravovací režim.");
                return;
            }
        }

        const fd = new FormData(e.target);       
        fd.append('profile_id', activeProfileId);

        if(Util.userHasPurchased('ZOF_PROFI')) {
            fd.append('client_id', this.state.clientId);
        }

        this.setState({
            generating: true
        }, () => {

            if(typeof this.props.data.energyOutputSettings[activeProfileId] !== 'undefined') {
                const eo = this.props.data.energyOutputSettings[activeProfileId];
                fd.set('eo_coefficient_id', eo.coefficientId);
                fd.set('eo_amount', eo.amount);
                fd.set('eo_unit', eo.unit);
            }

            this.setState({
                generatorFormData: fd
            }, () => {

                api('generator', { post: true, data: fd }).then(result => {
                    result = result.data;
                    
                    let mealsToAdd = [];
                    result.menu.map(m => {
                        mealsToAdd.push(m.mealId);
                    });

                    this.setState({
                        mealsToAdd: mealsToAdd,
                        generating: false,
                        generatorInputData: fd,
                        generatorData: result
                    });
        
                });
            });
        });
    }

    removeMeal(e, id) {
        let { removeMealIds } = this.state;

        if(removeMealIds.includes(id)) {
            removeMealIds = removeMealIds.filter(i => i !== id);
        } else {
            removeMealIds.push(id);
        }

        this.setState({
            removeMealIds: removeMealIds
        });
    }

    replaceMeal(e, mealId) {
        let data = this.state.generatorFormData;

        let requestData = data;
        requestData.delete('regenerateFromHash');
        requestData.delete('regenerateMealId');

        requestData.append('regenerateFromHash', this.state.generatorData.hash);
        requestData.append('regenerateMealId', mealId);

        const profileId = requestData.get('profile_id');
        if(typeof this.props.data.energyOutputSettings[profileId] !== 'undefined') {
            const eo = this.props.data.energyOutputSettings[profileId];
            requestData.set('eo_coefficient_id', eo.coefficientId);
            requestData.set('eo_amount', eo.amount);
            requestData.set('eo_unit', eo.unit);
        }

        this.setState({
            replacing: true,
            replacingMealId: mealId,
            replacingPlateId: 0,
            generatorFormData: data
        }, () => {
            api('generator', { post: true, data: requestData }).then(result => {
                result = result.data;
                this.setState({
                    replacing: false,
                    replacingMealId: 0,
                    replacingPlateId: 0,
                    generatorFormData: data,
                    generatorData: result
                });
            });
        });       
    }
        
    replacePlate(e, mealId, recipeId) {
        let data = this.state.generatorFormData;

        data.delete('regenerateFromHash');
        data.delete('regenerateRecipeId');
        data.delete('regenerateRecipeMealId');

        data.append('regenerateFromHash', this.state.generatorData.hash);
        data.append('regenerateRecipeId', recipeId);
        data.append('regenerateRecipeMealId', mealId);

        const profileId = data.get('profile_id');
        if(typeof this.props.data.energyOutputSettings[profileId] !== 'undefined') {
            const eo = this.props.data.energyOutputSettings[profileId];
            data.set('eo_coefficient_id', eo.coefficientId);
            data.set('eo_amount', eo.amount);
            data.set('eo_unit', eo.unit);
        }
        this.setState({
            replacing: true,
            replacingMealId: 0,
            replacingPlateId: recipeId,
            generatorFormData: data
        }, () => {
            api('generator', { post: true, data: data }).then(result => {
                result = result.data;
                this.setState({
                    replacing: false,
                    replacingMealId: 0,
                    replacingPlateId: 0,
                    generatorFormData: data,
                    generatorData: result
                });
            });
        });       
    }

    setGeneratorClientInsertDate(e, date) {
        let dateFmt = Util.yyyymmdd(date);
        this.setState({
            insertedToDate: false,
            generatorClientInsertToDate: dateFmt
        });
    }

    loadMonthData(m, y) {
        const clientId = this.state.clientId;

        let apiUrl = 'load/month-data?m=' + m + '&y=' + y;
        if(typeof clientId === 'string') {
            apiUrl = 'load/month-data?m=' + m + '&y=' + y + '&clientId=' + clientId;
        }

        api(apiUrl).then(result => {
            result = result.data;
            this.setState({
                loading: false,
                monthData: result,
                monthDataKey: y + '-' + m,
            });
        });
    }

    doImport() {
        let data = this.state.generatorFormData;

        let finalMealsToAdd = [];
        this.state.mealsToAdd.map(m => {
            if(!this.state.removeMealIds.includes(m)) finalMealsToAdd.push(m);
        });

        data.append('mealsToAdd', finalMealsToAdd);
        data.append('hash', this.state.generatorData.hash);

        if(Util.userHasPurchased('ZOF_PROFI')) {
            data.append('clientId', this.state.generatorData.client_id);
            data.append('clientInsertToDate', this.state.generatorClientInsertToDate);
        }

        this.setState({
            inserting: true
        }, () => {
            api('generator/insert', { post: true, data: data }).then(result => {
                let calMY = this.state.monthDataKey.split('-');
                this.loadMonthData(calMY[1], calMY[0]);
                this.setState({
                    inserting: false,
                    insertedToDate: this.state.generatorClientInsertToDate
                });
                /*
                this.props.showMessage({
                    show: true,
                    type: 'success',
                    message: 'Vloženo do plánovače.'
                });
                */               
            });
        });
    }

    openInPlanner(e) {
        this.props.history.push('/plan/' + this.state.insertedToDate);
    }

    saveAsMenu() {
        const { state } = this;

        if(state.saveAsMenuTitle.trim() == '') {
            alert("Zadejte název jídelníčku");
            return;
        }

        let data = new FormData;
        data.append('title', state.saveAsMenuTitle);
        data.append('description', state.saveAsMenuDescription);
        data.append('hash', state.generatorData.hash);
        data.append('target', state.saveAsMenuTargetUserId);
        data.append('client_id', state.generatorFormData.get('client_id'));

        api('generator/save-as-menu', { post: true, data: data }).then(result => {
            this.setState({
                saveAsMenuTitle: '',
                saveAsMenuDescription: '',
                saveAsMenuDone: true
            });
        });
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        if(state.loading) return <Loading />;

        let nutritionRegimeOptions = [];
        Object.keys(globals.tagsToGroups[11]).map(t => {
            const tagId = globals.tagsToGroups[11][t];
            if([98, 99, 100, 102, 104, 105].includes(tagId)) return;
            nutritionRegimeOptions.push(
                <label><input type="radio" name="regime" value={tagId} onChange={this.onChange} /><span></span> {globals.tags[tagId]}</label>
            );
        })

        let clientSelection = false;
        let mealSelection = false;
        let content = false;
        let energeticRegime = false;

        /*
        *
        *   GENERATOR FORM
        *
        */ 


        let submitButton = <button type="submit"><i className="fa fa-check"></i> Vygenerovat jídelníček</button>;

        if(Util.userHasPurchased('ZOF_PROFI')) {

            // Nutrition regime options - option to select more when ZOFapp Profi

            let allowed = state.allowedNutritionRegimes.map(r => r * 1);

            let filterOut = [98, 99, 100, 102, 104, 105];

            nutritionRegimeOptions = [];
            Object.keys(globals.tagsToGroups[11]).map(t => {
                const tagId = globals.tagsToGroups[11][t];
                if(filterOut.includes(tagId)) return;
                if (!allowed.includes(tagId)) {
                    nutritionRegimeOptions.push(
                        <label key={tagId} style={{opacity:'.3'}}><input disabled={true} type="checkbox" name="regimes[]" value={tagId} onChange={this.onChange} checked={state.regimes.includes(tagId)} /><span></span> {globals.tags[tagId]}</label>
                    );
                } else {
                    nutritionRegimeOptions.push(
                        <label key={tagId}><input type="checkbox" name="regimes[]" value={tagId} onChange={this.onChange} checked={state.regimes.includes(tagId)} /><span></span> {globals.tags[tagId]}</label>
                    );
                }
            })            

            // Client selection (who to generate for)
            
            let clientOptions = [];
            clientOptions.push(<option value={0} key={0}>- Můj profil -</option>);
            Object.keys(this.state.clients).map(c => {
                const client = this.state.clients[c];
                clientOptions.push(<option value={client.id} key={client.id}>{client.name}</option>);
            });

            clientSelection = (
                <>
                <p className="sub-hd">Vygenerovat pro:</p>
                <select name="client_id" onChange={this.changeClient}>
                    {clientOptions}
                </select>
                </>
            );
        
            // Selection of individual meal types to generate meals to

            let filteredMealTypes = Object.keys(globals.mealTypes).filter(id => {
                let type = globals.mealTypes[id];
                if(type.gastro_only === true) return false;
                return true;
            });

            let energyRatioOptions = [];
            for(let e=0; e<=100; e+=5) {
                energyRatioOptions.push(<option value={e}>{e} %</option>);
            }

            let visibleMealTypes = [1,2,3,4,5,6];   // snidane/sv/obed/sv/vecere/druha vecere
            let mealTypes = [];
            filteredMealTypes.map(id => {
                const type = globals.mealTypes[id];
                if(type.is_drink_regime === true) return;

                if(!visibleMealTypes.includes(+id)) return;

                let val = 0;
                if(typeof state.mealSelectionRatio[id] !== 'undefined') {
                    val = state.mealSelectionRatio[id];
                }

                let energyRatioSelect = false;
                if(this.state.mealSelection.includes(id)) {
                    energyRatioSelect = <select onChange={this.onChange} value={val} name="meal_energy_ratio[]" data-id={id}>{energyRatioOptions}</select>;
                }

                mealTypes.push(
                    <label key={id}>
                        <input type="checkbox" name="meal_selection[]" onChange={this.onChange} checked={state.mealSelection.includes(id)} value={id} /><span></span> {type.title}
                        {energyRatioSelect}
                    </label>
                );
            });

            // Control if energy ratios sum is 100
            let erSum = 0, erSumWarning = false;
            Object.keys(this.state.mealSelectionRatio).map(e => {
                erSum += this.state.mealSelectionRatio[e] * 1;
            });

            let generatorSubmitAllowed = true;

            if(this.state.mealSelection.length > 0 && erSum != 100) {
                generatorSubmitAllowed = false;
                erSumWarning = <p className="generator-100-warning"><i className="fa fa-exclamation-triangle"></i> Součet procent energetických poměrů je {erSum} (musí být 100).</p>;
                submitButton = false;
            }

            if(state.generating) submitButton = <Loading />;

            mealSelection = (
                <>
                <p className="sub-hd">Vyberte chody:</p>
                <div className="options-row">
                    {mealTypes}
                </div>
                {erSumWarning}
                </>
            );

            energeticRegime = (
                <>
                <p className="sub-hd">Energetický režim:</p>
                <div className="options-row options-row-2">
                    <label>
                        <input type="checkbox" name="energeticRegime" onChange={this.onChange} value={0} checked={this.state.energeticRegime == 0} /><span></span> vyrovnaný
                    </label>
                    <label>
                        <input type="checkbox" name="energeticRegime" onChange={this.onChange} value={1} checked={this.state.energeticRegime == 1} /><span></span> hubnoucí
                    </label>
                </div>
                </>
            );

            let drinkRegime = (
                <>
                <p className="sub-hd">Pitný režim:</p>
                <div className="options-row options-row-2">
                    <label>
                        <input type="checkbox" name="drinkRegime" onChange={this.onChange} value={1} checked={this.state.drinkRegime == "1"} /><span></span> ano
                    </label>
                    <label>
                        <input type="checkbox" name="drinkRegime" onChange={this.onChange} value={0} checked={this.state.drinkRegime == "0"} /><span></span> ne
                    </label>
                </div>
                </>
            );

            let desserts = (
                <>
                <p className="sub-hd">Dezerty:</p>
                <div className="options-row options-row-2">
                    <label>
                        <input type="checkbox" name="desserts" onChange={this.onChange} value={1} checked={this.state.desserts == "1"} /><span></span> ano
                    </label>
                    <label>
                        <input type="checkbox" name="desserts" onChange={this.onChange} value={0} checked={this.state.desserts == "0"} /><span></span> ne
                    </label>
                </div>
                </>
            );

            energeticRegime = (
                <>
                {energeticRegime}
                {drinkRegime}
                {desserts}
                </>
            );

        } else {
            mealSelection = (
                <>
                <p className="sub-hd">Počet chodů:</p>
                <div className="options-row options-row-4">
                    <label><input type="radio" name="courses" value="3" onChange={this.onChange} /><span></span> 3</label>{'\u00A0'}{'\u00A0'}
                    <label><input type="radio" name="courses" value="4" onChange={this.onChange} /><span></span> 4</label>{'\u00A0'}{'\u00A0'}
                    <label><input type="radio" name="courses" value="5" onChange={this.onChange} /><span></span> 5</label>{'\u00A0'}{'\u00A0'}
                    <label><input type="radio" name="courses" value="6" onChange={this.onChange} /><span></span> 6</label>
                </div>
                </>
            );
        }



        /*
        *
        *   GENERATOR RESULTS
        *
        */ 

    
        let generatorResults = false;

        if(state.generatorData === false) {
            // generatorResults = <p style={{textAlign:'center',margin:'2em 0'}}><span style={{fontSize:'1.8em',color:'#555'}}>&larr;</span> Vygenerujte si jídelníček vyplněním formuláře.</p>;
        } else {

            const menu = state.generatorData;

            let nutritionTotals = {
                'kcal': 0,
                'kj': 0,
                'proteins': 0,
                'carbohydrates': 0,
                'fats': 0,
                'fiber': 0
            };
    
            // Get current profile day energy output
    
            let profileId = Util.getActiveProfileId();
            let profile = globals.profiles[profileId];
            let ee = data.energyOutputSettings[profileId];
    
            const coefficients = {
                '0': 1.2,
                '1': 1.3,
                '2': 1.4,
                '3': 1.5,
                '4': 1.6,
                '5': 1.7,
                '6': 1.8,
                '7': 1.9,
                '8':  2.1
            };
    
            let eCoefId;
            if(ee) {
                eCoefId = ee.coefficientId >= 0 ? ee.coefficientId : profile.energy_output_coefficient;
            } else {
                eCoefId = profile.energy_output_coefficient;
            }
            let eCoefficient = 1.0;
            if(typeof coefficients[eCoefId] !== 'undefined') eCoefficient = coefficients[eCoefId];
    
            const males = ['m', 'ms', 'b1', 'b2', 'b3', 'b4', 'b'];
            const females = ['f', 'fs', 'fp', 'fbf', 'g1', 'g2', 'g3', 'g4', 'g'];
            let gender = false;
            if(males.includes(profile.persona_key)) gender = 'm';
            if(females.includes(profile.persona_key)) gender = 'f';
            if(profile.persona_key_child_gender != '' && males.includes(profile.persona_key_child_gender)) gender = 'm';
            if(profile.persona_key_child_gender != '' && females.includes(profile.persona_key_child_gender)) gender = 'f';
    
            const birthD = new Date(profile.birthday);
            let ageDifMs = Date.now() - birthD.getTime();
            let ageDate = new Date(ageDifMs); // miliseconds from epoch
            let age = Math.abs(ageDate.getUTCFullYear() - 1970);
    
            let BMR = 0;
            if(gender == 'm') {
                BMR = 10 * profile.weight + 6.25 * profile.height - 5 * age + 5;
            }
            if(gender == 'f') {
                BMR = 10 * profile.weight + 6.25 * profile.height - 5 * age - 161;
            }
            
            if(profile.individual_bmr > 0) BMR = profile.individual_bmr;
    
            let energyOutputKcal = 0;
            if(ee) {
                if(ee.amount > 0) {
                    energyOutputKcal = ee.amount;
                    if(ee.unit == 'kj') {
                        energyOutputKcal = ee.amount / 4.184;
                    }
                } else {
                    energyOutputKcal = BMR * eCoefficient;
                }
            } else {
                energyOutputKcal = BMR * eCoefficient;
            }
    
            let energyOutputKj = parseFloat(energyOutputKcal * 4.184);
    
            let replacingOverlay = (
                <div style={{position:'absolute',left:'0',top:'0',width:'100%',height:'100%',zIndex:'10',background:'rgba(255,255,255,.8)'}}>
                    <Loading />
                </div>
            );


            let content = [];
            menu.menu.map(meal => {
    
                let totalKcal = 0, totalKj = 0;
                let recipes = [];
                meal.recipes.map(recipe => {  
                    nutritionTotals.kcal += recipe.nutritionData.energy_kcal * 1;
                    nutritionTotals.kj += recipe.nutritionData.energy_kj * 1;
                    nutritionTotals.proteins += recipe.nutritionData.proteins * 1;
                    nutritionTotals.carbohydrates += recipe.nutritionData.carbohydrates * 1;
                    nutritionTotals.fats += recipe.nutritionData.fats * 1;
                    nutritionTotals.fiber += recipe.nutritionData.fiber * 1;
    
                    let overlay = this.state.replacingPlateId == recipe.data.id ? replacingOverlay : false;

                    recipes.push(
                        <div className="recipe" style={{position:'relative'}}>
                            <div className="pic" style={{ backgroundImage: 'url(https://www.zofapp.cz/data/zof/' + recipe.data.picture + ')' }}>
                            </div>
                            <div className="info">
                                <h3>{recipe.data.title}</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Množství:</td>
                                            <td>{Math.ceil(recipe.nutritionData.weight)} g</td>
                                        </tr>
                                        <tr>
                                            <td>Energie:</td>
                                            <td>{Math.ceil(recipe.nutritionData.energy_kcal)} kcal, {Math.ceil(recipe.nutritionData.energy_kj)} kJ</td>
                                        </tr>
                                        <tr>
                                            <td>Bílkoviny:</td>
                                            <td>{recipe.nutritionData.proteins} g</td>
                                        </tr>
                                        <tr>
                                            <td>Sacharidy:</td>
                                            <td>{recipe.nutritionData.carbohydrates} g</td>
                                        </tr>
                                        <tr>
                                            <td>Tuky:</td>
                                            <td>{recipe.nutritionData.fats} g</td>
                                        </tr>
                                        <tr>
                                            <td>Vláknina:</td>
                                            <td>{recipe.nutritionData.fiber} g</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="opts">
                                <a onClick={(e) => this.replacePlate(e, meal.mealId, recipe.data.id)}>
                                    <i className="fas fa-redo-alt"></i>
                                </a>
                            </div>   
                            {overlay}                     
                        </div>
                    );
                    totalKcal += parseFloat(recipe.nutritionData.energy_kcal);
                    totalKj += parseFloat(recipe.nutritionData.energy_kj);                
                });
    
                if(!this.state.removeMealIds.includes(meal.mealId)) {
                
                    let overlay = this.state.replacingMealId == meal.mealId ? replacingOverlay : false;

                    content.push(
                        <div className="meal" style={{position:'relative'}}>
                            <label>
                                <h2>
                                    {data.globals.mealTypes[meal.mealId].title}
                                    <span>{Math.ceil(totalKcal)} kcal, {Math.ceil(totalKj)} kJ</span>
                                    <aside>
                                        <a onClick={(e) => this.replaceMeal(e, meal.mealId)}><i className="fas fa-redo-alt"></i> Vyměnit</a>
                                        <a onClick={(e) => this.removeMeal(e, meal.mealId)}><i className="fas fa-times"></i> Vymazat</a>
                                    </aside>
                                </h2>
                            </label>
                            <div className="recipes">
                                {recipes}
                            </div>
                            {overlay}
                        </div>
                    );
                } else {
                    content.push(
                        <div className="meal">
                            <label>
                                <h2>
                                    {data.globals.mealTypes[meal.mealId].title}
                                    <span>(nebude vloženo do jídelníčku)</span>
                                    <aside>
                                        <a onClick={(e) => this.removeMeal(e, meal.mealId)}><i className="fas fa-undo-alt"></i> Vrátit</a>
                                    </aside>
                                </h2>
                            </label>
                            <div className="recipes" style={{opacity:'.2'}}>
                                {recipes}
                            </div>
                        </div>
                    );
                }
            })
    

            // Generator evaluation
    
            nutritionTotals = menu.evaluation['x'];

            for(let k in nutritionTotals) {
                nutritionTotals[k] = Math.round((nutritionTotals[k] + Number.EPSILON) * 100) / 100            
            }
    
            nutritionTotals.energy_kcal = Math.round(nutritionTotals.energy_kcal);
            nutritionTotals.energy_kj = Math.round(nutritionTotals.energy_kj);
    
            energyOutputKcal = Math.round(energyOutputKcal);
            energyOutputKj = Math.round(energyOutputKj);
    
            const energyBalanceKcal = nutritionTotals.energy_kcal - energyOutputKcal;
            const energyBalanceKj = nutritionTotals.energy_kj - energyOutputKj;
        
            let result = (
                <React.Fragment>
                <div className="generator-meals">
                    {content}
                </div>
                <div className="generator-evaluation">
                    <h2>Nutriční hodnoty</h2>
                    <table>
                        <tbody>
                        <tr>
                                <th>Energetický příjem:</th>
                                <td>{nutritionTotals.energy_kcal} kcal / {nutritionTotals.energy_kj} kJ</td>
                            </tr>
                            <tr>
                                <th>Energetický výdej:</th>
                                <td>{energyOutputKcal} kcal / {energyOutputKj} kJ</td>
                            </tr>
                            <tr>
                                <th>Energetická bilance:</th>
                                <td>{energyBalanceKcal} kcal / {energyBalanceKj} kJ</td>
                            </tr>
                            <tr>
                                <th>Bílkoviny:</th>
                                <td>{nutritionTotals.proteins} g</td>
                            </tr>
                            <tr>
                                <th>Sacharidy:</th>
                                <td>{nutritionTotals.carbohydrates} g</td>
                            </tr>
                            <tr>
                                <th>Tuky:</th>
                                <td>{nutritionTotals.fats} g</td>
                            </tr>
                            <tr>
                                <th>Vláknina:</th>
                                <td>{nutritionTotals.fiber} g</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </React.Fragment>
            );
    

            // Generator actions

            let actions = false;

            // Did we select a client (ZOF_PROFI)?

            let client = false;
            let clientId = this.state.generatorFormData.get('client_id');
            Object.keys(this.state.clients).map(c => {
                const cl = this.state.clients[c];
                if(cl.id == clientId) {
                    client = this.state.clients[c];
                }
            });

            if(Util.userHasPurchased('ZOF_PROFI')) {

                let insertToPlannerTargetInfo = false;
                if(client) {
                    insertToPlannerTargetInfo = <p>Vkládáte do účtu „{client.name}“</p>;
                }

                let menuTargets = false;
                if(clientId > 0) {
                    if(client) {
                        menuTargets = (
                            <>
                            <p><label><input type="radio" name="saveAsMenuTarget" value={0} checked={state.saveAsMenuTargetUserId == 0} onClick={(e) => this.setState({saveAsMenuTargetUserId: 0})} /> do mého účtu</label></p>
                            <p><label><input type="radio" name="saveAsMenuTarget" value={1} checked={state.saveAsMenuTargetUserId == 1} onClick={(e) => this.setState({saveAsMenuTargetUserId: 1})} /> do účtu „{client.name}“</label></p>
                            </>
                        );
                    }
                }


                // Check if there is anything in selected date's menu

                let overwriteWarning = false;
                const ymd = state.generatorClientInsertToDate;
                if(typeof ymd === 'string') {
                    if(typeof state.monthData[ymd] !== 'undefined' && Object.keys(state.monthData[ymd]).length > 0) {
                        overwriteWarning = <p className="generator-insert-warning">Pozor: Jídelníček ve vybraném dni bude přepsán!</p>;    
                    }
                }


                let menuSavedInfo = false;
                if(state.saveAsMenuDone) {
                    if(clientId > 0) {
                        menuSavedInfo = <p className="generator-menu-saved-info">Jídelníček byl uložen. <a onClick={(e) => this.gotoClientMenus(e, clientId)}>Přejít na jídelíčky „{client.name}“</a></p>;
                    } else {
                        menuSavedInfo = <p className="generator-menu-saved-info">Jídelníček byl uložen. <a onClick={(e) => this.props.history.push('/menu')}>Přejít na mé jídelíčky</a></p>;
                    }
                }

                let gotoPlannerLink = false;
                if(typeof state.insertedToDate === 'string') {
                    gotoPlannerLink = <p>Vloženo. <a style={{color:'#4eb32d',textDecoration:'underline'}} onClick={(e) => this.openInPlanner(e)}>Otevřít v plánovači</a></p>
                }

                let insertToPlanner = (
                    <>
                    {insertToPlannerTargetInfo}
                    <GeneratorCalendar loadMonthData={this.loadMonthData} monthData={this.state.monthData} onClientDateClick={this.setGeneratorClientInsertDate} />
                    {overwriteWarning}
                    {gotoPlannerLink}
                    <button type="button" onClick={this.doImport}>Vložit do plánovače</button>
                    </>
                );

                if(state.inserting) insertToPlanner = (
                    <>
                    {insertToPlannerTargetInfo}
                    <GeneratorCalendar loadMonthData={this.loadMonthData} monthData={this.state.monthData} onClientDateClick={this.setGeneratorClientInsertDate} />
                    {overwriteWarning}
                    {gotoPlannerLink}
                    <p><Loading /></p>
                    </>
                );

                actions = (
                    <>
                    <div className="generator-actions">
                        <div className="insert-to-planner">
                            <h2>Vložit do plánovače</h2>
                            {insertToPlanner}
                        </div>
                        <div className="save-as-menu">
                            <h2>Uložit jídelníček</h2>
                            {menuTargets}
                            <input type="text" name="saveAsMenuTitle" placeholder="Název jídelníčku" value={state.saveAsMenuTitle} onChange={(e) => this.setState({saveAsMenuTitle: e.target.value})} />
                            <textarea name="saveAsMenuDescription" placeholder="Popis jídelníčku" value={state.saveAsMenuDescription} onChange={(e) => this.setState({saveAsMenuDescription: e.target.value})}></textarea>
                            <button type="button" onClick={this.saveAsMenu}>Uložit jako jídelníček</button>
                            {menuSavedInfo}
                        </div>
                    </div>
                    </>
                );

            } else {
                if(Util.userHasPurchased('ZOF_COOKBOOK')) {
                    /*
                    importPlanButton = (
                        <button style={{ cursor:'pointer', background: '#4eb32d', color: '#fff', padding: '.7em 2em', border: 'none'}} type="button" onClick={this.doImport}>Vložit to dnešního plánu</button>
                    );
                    */

                    actions = (
                        <p>
                            <button style={{ cursor:'pointer', background: '#4eb32d', color: '#fff', padding: '.7em 2em', border: 'none'}} type="button" onClick={this.doImport}>Vložit to dnešního plánu</button>
                        </p>
                    );
    
                } else {
                    /*
                    importPlanButton = <span><Link style={{ color: '#4eb32d' }} to="/cookbook/buy/cookbook">Pořiďte si ZOF kuchařku a nechte aplikaci plánovat jídelníček za Vás!</Link></span>
                    */
                    actions = (
                        <p>
                            <span><Link style={{ color: '#4eb32d' }} to="/cookbook/buy/cookbook">Pořiďte si ZOF kuchařku a nechte aplikaci plánovat jídelníček za Vás!</Link></span>
                        </p>
                    );


                }

            }    

            generatorResults = (
                <div style={{position:'relative'}}>
                {result}
                {actions}
                </div>
            );
    

        }


        content = [
            <div className="page page-recipes">
                <div className="page-header">
                    <h1>Generátor</h1>
                </div>
                <div className="page-content">
                    <div className="container">

                        <div className="generator-row">
                            <div className="generator-setup">
                                <form className="generator-form" id="generator-form" onSubmit={this.doGenerate}>
                                    {clientSelection}
                                    {mealSelection}
                                    <p className="sub-hd">Stravovací režim:</p>
                                    <div className="options-row">
                                        {nutritionRegimeOptions}
                                    </div>
                                    {energeticRegime}
                                    <div className="submit">
                                        <input type="hidden" name="date" value={format(new Date(), 'yyyy-MM-dd')} />
                                        {submitButton}
                                    </div>
                                 </form>
                            </div>
                            <div className="generator-results">
                                {generatorResults}                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ];

        return content;
    }  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Generator));
