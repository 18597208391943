import React, { Component } from 'react';
import { connect } from "react-redux";
import Util from 'utility';

import { 
    setDayEnergyOutputCoefficient,
    setDayEnergyOutputAmount,
    setDayEnergyOutputAmountUnit,
    setTrainingStart,
    setTrainingLength,
    setTrainingDescription,
} from 'actions/index';


const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        setDayEnergyOutputCoefficient: data => dispatch(setDayEnergyOutputCoefficient(data)),
        setDayEnergyOutputAmount: data => dispatch(setDayEnergyOutputAmount(data)),
        setDayEnergyOutputAmountUnit: data => dispatch(setDayEnergyOutputAmountUnit(data)),
        setTrainingStart: data => dispatch(setTrainingStart(data)),
        setTrainingLength: data => dispatch(setTrainingLength(data)),
        setTrainingDescription: data => dispatch(setTrainingDescription(data)),
    };
};

class PanelEnergyOutput extends Component { 

    constructor(props) {
        super(props);

        let activeProfileId = this.props.data.profileId;
        if(activeProfileId == 0) {
            activeProfileId = Object.keys(this.props.data.globals.profiles)[0];
        }

        this.state = {
            help: false,
            profileId: activeProfileId,
        };

        this.setDayCoefficient = this.setDayCoefficient.bind(this);
        this.setDayCaloriesBurnedUnit = this.setDayCaloriesBurnedUnit.bind(this);
        this.setDayCaloriesBurned = this.setDayCaloriesBurned.bind(this);
        this.setEnergyOutputProfile = this.setEnergyOutputProfile.bind(this);
        this.setTrainingStart = this.setTrainingStart.bind(this);
        this.setTrainingLength = this.setTrainingLength.bind(this);
        this.setTrainingDescription = this.setTrainingDescription.bind(this);
        this.onDone = this.onDone.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
    }

    onDone() {
        this.props.onDone();
    }

    toggleHelp(e) {
        let help = this.state.help;
        this.setState({
            help: !help
        });
    }

    setEnergyOutputProfile(e) {
        this.setState({
            profileId: e.target.value
        });
    }
    
    setDayCoefficient(e) {
        this.props.setDayEnergyOutputCoefficient({
            profileId: this.state.profileId,
            coefficientId: e.target.value
        });
        Util.doLoadDayEvaluation();
    }

    setDayCaloriesBurned(e) {
        this.props.setDayEnergyOutputAmount({
            profileId: this.state.profileId,
            amount: e.target.value,
        });
        Util.doLoadDayEvaluation();
    }

    setDayCaloriesBurnedUnit(e) {
        this.props.setDayEnergyOutputAmountUnit({
            profileId: this.state.profileId,
            unit: e.target.value
        });
        Util.doLoadDayEvaluation();
    }

    
    setTrainingStart(e) {
        const id = e.target.dataset.id;
        this.props.setTrainingStart({
            id: id,
            start: e.target.value
        });
    }

    setTrainingLength(e) {
        const id = e.target.dataset.id;
        this.props.setTrainingLength({
            id: id,
            length: e.target.value
        });
    }

    setTrainingDescription(e) {
        const id = e.target.dataset.id;
        this.props.setTrainingDescription({
            id: id,
            description: e.target.value
        });
    }

    render() {
        const { data } = this.props;
        const activeProfileId = this.state.profileId;

        let activeCoefficientId = -1;
        if (typeof data.energyOutputSettings[activeProfileId] != 'undefined') {
            activeCoefficientId = data.energyOutputSettings[activeProfileId].coefficientId;
        }

        let coefficients = [];
        for (let c in data.globals.energyOutputCoefficients) {
            const coef = data.globals.energyOutputCoefficients[c];
            coefficients.push(
                <div className="energy-coefficient">
                    <input type="radio" name="energy-output-type" checked={activeCoefficientId == c} value={c} id={'coef-' + c} onChange={this.setDayCoefficient} />
                    <label htmlFor={'coef-' + c}>
                        <div className="content">
                            <p style={{ margin: "0" }} data-tip={coef.description}>{coef.title}</p>
                        </div>
                    </label>
                </div>
            );
        }

        let energyOutputAmount = 0;
        let energyOutputAmountUnit = 'kcal';
        if(typeof data.energyOutputSettings[activeProfileId] != 'undefined') {
            energyOutputAmount = data.energyOutputSettings[activeProfileId].amount;
            energyOutputAmountUnit = data.energyOutputSettings[activeProfileId].unit;
        }


        // Sport settings

        let content = (
            <>
            <div className="energy-coefficients">
                {coefficients}
            </div>
            <p style={{ fontSize:'.85em',fontWeight:'bold',margin:'1em 0',textAlign:'center'}}>Nebo zadejte, kolik jste v tento den spálili energie:</p>
            <p style={{ display:'flex',alignItems:'center',justifyContent:'center' }}>
                Spáleno: {'\u00A0'}
                <input type="text" onChange={this.setDayCaloriesBurned} value={energyOutputAmount} style={{ width: '5em',textAlign:'right',padding:'2px 5px' }} />
                {'\u00A0'}
                <input type="radio" value="kcal" checked={energyOutputAmountUnit == 'kcal'} onChange={(e) => this.setDayCaloriesBurnedUnit(e)} /> kcal
                {'\u00A0'}{'\u00A0'}
                <input type="radio" value="kj" checked={energyOutputAmountUnit == 'kj'} onChange={(e) => this.setDayCaloriesBurnedUnit(e)} /> kJ
            </p>
            </>
        );

        if(this.state.help) {
            content = (
                <div style={{fontSize:'.85em',padding:'.5em',lineHeight:'110%'}}>
                    <p><b>Nepatrná</b><br />Sedavé zaměstnání, bez fyzické aktivity</p>
                    <p><b>Velmi malá</b><br />Sedavé zaměstnání, minimální  volnočasová fyzická aktivita</p>
                    <p><b>Malá</b><br />Sedavé zaměstnání, pravidelná volnočasová aktivita s nízkou intenzitou (chůze)</p>
                    <p><b>Malá sportovní</b><br />Sedavé zaměstnání, pravidelná volnočasová aktivita se střední intenzitou (jízda na kole, plavání, běh, pilates...)</p>
                    <p><b>Střední</b><br />Sedavé zaměstnání + trénink vyšší intenzity 3x- 4x týdně; nebo fyzicky náročná práce</p>
                    <p><b>Střední sportovní</b><br />Sedavé zaměstnání + trénink vyšší intenzity 4x- 5x týdně; nebo fyzicky náročná práce</p>
                    <p><b>Vysoká</b><br />Sedavé zaměstnání + trénink vyšší intenzity 5-6x týdně; nebo fyzicky náročná práce + trénink vyšší intenzity 2x týdně</p>
                    <p><b>Vysoká sportoní</b><br />Sedavé zaměstnání + trénink vyšší intenzity 5-6x týdně; nebo fyzicky náročná práce + trénink vyšší intenzity 3x týdně</p>
                    <p><b>Značně velká</b><br />Fyzicky náročná práce + trénink vyšší intenzity 4x – 5x týdně</p>
                </div>
            );
        }


        // Return JSX

        return (
            <React.Fragment>
            <div className="energy-output-row">
                <div className="col">
                    <div style={{ fontSize:'.85em',fontWeight:'bold',margin:'0 0 .5em 0',textAlign:'center'}}>
                        Vyberte pohybovou aktivitu:
                        <label className="help"><input onClick={this.toggleHelp} type="checkbox" name="help" /><span>?</span></label>
                    </div>
                    {content}
                </div>
            </div>
            <p style={{ textAlign: 'center', margin: '1em 0 0' }}><button className="button-primary" onClick={this.onDone}>Uložit a zavřít</button></p>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelEnergyOutput);
