import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading/index';
import Saving from 'components/Saving/index';
import api from 'api';
import Util from 'utility';

import UserEditHelp from 'components/Help/UserEditHelp';

import { 
    openModal, 
    closeModal, 
    showMessage,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data)),
        showMessage: data => dispatch(showMessage(data)),
    };
};

class UserEdit extends Component { 
    constructor(props) {
        super(props);

        let allowedNutritionRegimeCombinations = {
            1: [1, 60, 201],
            10: [10, 50, 201],
            20: [20, 50, 201],
            30: [30, 50, 201],
            40: [40, 50, 201],
            50: [50, 1, 10, 20, 30, 40, 60, 201],
            60: [60, 1, 20, 40, 50, 201],
            201: [201, 1, 10, 20, 30, 40, 60, 50],
        };

        let nutritionRegimeExcludesList = {
            1: [10, 20, 30, 40],
            10: [20, 30, 40, 1, 60],
            20: [10, 30, 40, 1],
            30: [10, 20, 40, 1, 60],
            40: [10, 20, 30, 1, 60],
            50: [],
            60: [10, 30],
            201: []
        };

        this.state = {
            loading: true,
            saving: false,
            profile: {},
            account: {},
            themes: {},
            themeFolder: '',
            vegeRegimesWarning: false,
            tab: 'basic',
            initialProfileUrl: '',
            allowedNutritionRegimes: Object.keys(props.data.globals.eatingRegimes),
            allowedNutritionCombinations: allowedNutritionRegimeCombinations,
            nutritionRegimeExcludes: nutritionRegimeExcludesList,
            newPasswordInputType: 'password',
        };    
        this.onChange = this.onChange.bind(this);
        this.onThemeFolderChange = this.onThemeFolderChange.bind(this);
        this.selectActiveTheme = this.selectActiveTheme.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.openHelp = this.openHelp.bind(this);
        this.toggleNewPasswordInputType = this.toggleNewPasswordInputType.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle('Uživatel');
        api('profile/get/' + this.props.match.params.id).then(result => {
            let profile = result.data;
            
            const birthday = profile.birthday.split('-').map(n => n * 1);
            profile.birthday_day = birthday[2];
            profile.birthday_month = birthday[1];
            profile.birthday_year = birthday[0];

            profile.new_password = '';
            profile.new_password_confirmation = '';

            let themeFolder = '';
            if(profile.theme !== null && profile.theme != '') {
                themeFolder = profile.theme.split('/');
                themeFolder = themeFolder[0];
            }
       
            this.setState({
                profile: profile,
                themes: profile.themes,
                themeFolder: themeFolder,
                loading: false
            });
        });
    }

    openHelp(e, help) {

        let helpTitles = {
            'name': 'Jméno profilu',
            'photo': 'Profilová fotka',
            'inputData': 'Vstupní údaje',
            'bmr': 'Bazální metabolismus',
            'energeticRegime': 'Energetický režim',
            'energyOutput': 'Výdej energie',
            'nutritionRegime': 'Stravovací režimy',
            'color': 'Barva profilu',
            'newPassword': 'Změna hesla',
            'pin': 'PIN',
        };


        this.props.closeModal();
        this.props.openModal({
            'title': helpTitles[help] + ' - nápověda', 
            'class': 'dialog-flexible',
            'content': UserEditHelp[help]()
        });
    }

    onThemeFolderChange(e) {
        this.setState({
            themeFolder: e.target.value
        });
    }

    onChange(e) {
        let profile = this.state.profile;
        const key = e.target.name;
        let value = e.target.value;

        // Set numeric values as numbers (instead of strings)
        let valueNumeric = value * 1;
        if(value == valueNumeric.toString()) {
            value = valueNumeric;
        }

        if(key == 'photo') {
            value = e.target.files[0];
        }

        if(key == 'eating_regimes[]') {
            if(e.target.checked) {
                profile.eating_regimes.push(value);
            } else {
                const i = profile.eating_regimes.indexOf(value);
                if(i > -1) {
                    profile.eating_regimes.splice(i, 1);
                }
            }

            // Disable non-valid regime combinations
            let allowed = Object.keys(this.props.data.globals.eatingRegimes);
            profile.eating_regimes.map(r => {
                if(r*1 == 0) return;
                this.state.nutritionRegimeExcludes[r*1].map(excl => {
                    allowed = allowed.filter(a => a != excl);
                });
            });

            allowed = allowed.map(r => r*1);

            // Filter currently selected eating regimes based on what is excluded
            profile.eating_regimes = profile.eating_regimes.filter(r => allowed.includes(r*1));

            // If nothing allowed, reset everything
            if(allowed.length == 0) {
                profile.eating_regimes = [];
                allowed = Object.keys(this.props.data.globals.eatingRegimes);
            }

            allowed = allowed.map(r => r*1);

            this.setState({
                allowedNutritionRegimes: allowed
            });

            // Warning, if more than one vegetarian regime is selected

            const vegetarianRegimes = [10, 20, 30];
            let numVegeRegimes = 0;
            profile.eating_regimes.map(regime => {
                if(vegetarianRegimes.includes(regime)) numVegeRegimes++;
            });

            if(numVegeRegimes > 1) {
                this.setState({
                    vegeRegimesWarning: true
                });
            } else {
                this.setState({
                    vegeRegimesWarning: false
                });
            }

        } else {
            profile[key] = value;
        }

        this.setState({
            profile: profile,
        });
    }

    selectActiveTheme(e, theme) {
        let profile = this.state.profile;
        profile.theme = this.state.themeFolder + '/' + theme;
        this.setState({
            profile: profile
        });
    }

    saveProfile() {
        const { state } = this;
        const { profile } = state;

        if(profile.new_password != '') {
            if(profile.new_password != profile.new_password_confirmation) {
                this.props.showMessage({
                    show: true,
                    type: 'error',
                    message: 'Zadaná hesla nejsou stejná.'
                });                 
                return false;
            }
        }
    

        this.setState({
            saving: true
        }, () => {

            let fd = new FormData();
            for(let i in profile) {
                fd.append(i, profile[i]);
            }

            api('profile/update/' + this.props.match.params.id, {post: true, data: fd}).then(result => {
                setTimeout(() => {
                    this.setState({
                        saving: false
                    }, () => {
                        this.props.history.push('/user/edit/' + Util.getFirstProfileId());
                    });
                }, 300);
            });
        });
    }

    toggleNewPasswordInputType() {
        let it = this.state.newPasswordInputType;
        if(it == 'password') {
            it = 'text';
        } else {
            it = 'password';
        }
        this.setState({
            newPasswordInputType: it
        });
    }

    changeTab(tab) {
        this.setState({
            tab: tab
        });
    }

    render() {
        if(this.state.loading) return <Loading />;

        let saving = false;
        if(this.state.saving) {
            saving = <Saving />;
        }

        const random = Math.random() * 10000000;

        const { state } = this;
        const { globals } = this.props.data;
        const { profile } = state;
        const { account } = state;

        let loginData = <p style={{margin:'2em 0 2em 1em'}}><a style={{ color: '#4eb32d', textDecoration: 'underline' }} onClick={() => this.setState({changeLoginData: true})}>Změnit přihlašovací údaje</a></p>
        if(this.state.changeLoginData) {
            loginData = (
                <div className="row">
                    <div className="col">
                    <h3>Přihlašovací údaje</h3>
                        <div className="input-row">
                            <span>E-mail:</span>
                            <div className="inputs">
                                <input type="text" name="username" onChange={this.onChange} value={account.username} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Nové heslo:</span>
                            <div className="inputs">
                                <input type="password" name="password" autocomplete="new-password" value={account.password} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="input-row">
                            <span>Nové heslo znovu:</span>
                            <div className="inputs">
                                <input type="password" name="password2" autocomplete="new-password" value={account.password2} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let currentProfileUrl = `https://www.zofapp.cz/u/id/${account.id}`;

        let publicProfileInput = (
            <React.Fragment>
                <div className="inputs" style={{ flexBasis: '100%' }}>
                    <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                        <span style={{ width: 'auto', flexBasis: 'auto', paddingRight: '.5em' }}>zofapp.cz/u/</span><input type="text" name="profile_url" onChange={this.onChange} value={account.profile_url} maxLength="40" />
                    </div>
                </div>
                <p style={{ margin: '.5em 0', fontSize: '11px', color: '#444' }}>Pokud si nezadáte vlastní adresu profilu, bude dostupný na adrese <b>{currentProfileUrl}</b>.<br />Upozornění: po zadání adresy již nebude možné ji změnit.</p>
            </React.Fragment>
        );
        if(state.initialProfileUrl !== null && state.initialProfileUrl != '') {
            publicProfileInput = (
                <React.Fragment>
                    <div className="inputs" style={{ flexBasis: '100%' }}>
                        <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                            <span style={{ width: 'auto', flexBasis: 'auto', paddingRight: '.5em' }}>zofapp.cz/u/</span><input style={{ background: '#f1f1f1', color: '#777', fontWeight: 'bold' }} readOnly={true} type="text" name="profile_url" value={state.initialProfileUrl} maxLength="40" />
                        </div>
                    </div>
                    <p style={{ margin: '.5em 0', fontSize: '11px', color: '#444' }}>Chcete změnit adresu Vašeho profilu? <a target="_blank" href="https://www.zofapp.cz/kontakt">Napište nám</a>.</p>
                </React.Fragment>
            );
        }


        // Form data

        if(profile.hobbies == 'null') profile.hobbies = '';
        if(profile.favorite_kitchen == 'null') profile.favorite_kitchen = '';


        let profileKeys = [];
        for(let pk in globals.profileKeys) {
            profileKeys.push({id: pk, title: globals.profileKeys[pk]});
        }

        let birthdayDays = [];
        for(let n=1; n<=31; n++) birthdayDays.push(n);

        let birthdayMonths =  ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
        
        let birthdayYears = [];
        for(let n=1900; n<=(new Date()).getFullYear(); n++) birthdayYears.push(n);

        let profileColors = ['000000', 'dd0000', '00cc00', '0088ee', 'ff7700', 'aa00cc', 'ffcc44', 'ff26f2', '00c97b'];
        let profileColorSelect = [];
        profileColors.map(color => {
            profileColorSelect.push(
                <div className="col col-3" key={color}>
                    <label class="check">
                        <input type="radio" name="profile_color" value={color} checked={profile.profile_color == color} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        <span style={{ padding: '3px 4px', fontSize: '.9em', borderRadius: '4px', backgroundColor: '#' + color, color: '#fff'}}>{profile.name}</span>
                    </label>
                </div>
            );
        });

        let allowed = state.allowedNutritionRegimes.map(r => r * 1);

        let eatingRegimes = [];
        for(let r in globals.eatingRegimes) {
            if (!allowed.includes(r*1)) {
                eatingRegimes.push(
                    <div className="col" style={{ flexBasis: '100%' }} key={r}>
                        <label class="check" style={{opacity:.3}}>
                            <input disabled={true} type="checkbox" name="eating_regimes[]" value={r} checked={profile.eating_regimes.includes(r * 1)} onChange={this.onChange} />
                            <b><i className="fa fa-check"></i></b>
                            {globals.eatingRegimes[r].title}
                        </label>                
                    </div>
                );
            } else {
                eatingRegimes.push(
                    <div className="col" style={{ flexBasis: '100%' }} key={r}>
                        <label class="check">
                            <input type="checkbox" name="eating_regimes[]" value={r} checked={profile.eating_regimes.includes(r * 1)} onChange={this.onChange} />
                            <b><i className="fa fa-check"></i></b>
                            {globals.eatingRegimes[r].title}
                        </label>                
                    </div>
                );
            }
        }

        let energyBalanceOptions = [];
        for(let e in globals.energyBalanceOptions) {
            energyBalanceOptions.push(
                <div className="col" style={{ flexBasis: '100%' }} key={e}>
                    <label class="check">
                        <input type="checkbox" name="energy_balance_option" value={e} checked={profile.energy_balance_option == e} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        {globals.energyBalanceOptions[e]}
                    </label>                
                </div>
            );
        }

        let themeFolderOptions = [<option value={''}>Vyberte...</option>];
        for(let f in state.themes) {
            themeFolderOptions.push(
                <option value={f}>{state.themes[f].title}</option>
            );
        }

        let themePictures = [];
        if(state.themeFolder && state.themeFolder != '' && state.themeFolder != 'null') {
            for(let t in state.themes[state.themeFolder].items) {
                let theme = state.themes[state.themeFolder].items[t];
                let themePath = this.state.themeFolder + '/' + theme;
                let aClass = '';
                if(themePath == this.state.profile.theme) aClass = 'active';
                themePictures.push(
                    <div class="theme">
                        <a className={aClass} onClick={(e) => this.selectActiveTheme(e, theme)}>
                            <div style={{ backgroundImage: `url(https://www.zofapp.cz/data/app/zof/themes/${this.state.themeFolder}/${theme}` }}>
                            </div>
                        </a>
                    </div>
                );
            }
        }

        let vegeRegimesWarning = false;
        if(this.state.vegeRegimesWarning) {
            vegeRegimesWarning = <span style={{ marginLeft: '1.5em', fontSize: '.7em', color: '#f00' }}>Upozornění: máte vybrán více než jeden vegetariánský profil.</span>;
        }




        let energyOutputCoefficients = [];
        for (let c in this.props.data.globals.energyOutputCoefficients) {
            const coef = this.props.data.globals.energyOutputCoefficients[c];


            energyOutputCoefficients.push(
                <div className="col" style={{ flexBasis: '100%' }} key={c}>
                    <label class="check">
                        <input type="radio" name="energy_output_coefficient" value={c} checked={profile.energy_output_coefficient == c} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        {coef.title}
                    </label>                
                </div>
            );
        }

        let erOptions = {
            'reduction': 'Chci hubnout', 
            'balanced': 'Vyrovnaný', 
            'gain': 'Chci přibrat'
        };

        if(profile.energetic_regime === null) profile.energetic_regime = 'balanced';

        let energeticRegimes = [];
        Object.keys(erOptions).map(r => {
            energeticRegimes.push(
                <div className="col" style={{ flexBasis: '100%' }} key={r}>
                    <label class="check">
                        <input type="radio" name="energetic_regime" value={r} checked={profile.energetic_regime == r} onChange={this.onChange} />
                        <b><i className="fa fa-check"></i></b>
                        {erOptions[r]}
                    </label>                
                </div>
            );
        });

        let newPasswordInputIcon = <i class="fa fa-eye-slash"></i>;
        if(state.newPasswordInputType == 'text') {
            newPasswordInputIcon = <i class="fa fa-eye"></i>;
        }


        //

        let content = false;
        if(state.tab == 'basic') {
            content = (
                <React.Fragment>
                    <div className="alert alert-info">
                        <i className="fa fa-info-circle"></i>
                        <p>Na základě vyplněných základních údajů může aplikace vyhodnocovat Vaše recepty a jídelníčky, generovat jídelníčky na míru, sledovat energetickou bilanci.</p>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>Jméno profilu <a className="btn-help" onClick={(e) => this.openHelp(e, 'name')}>?</a></h3>
                            <div className="input-row">
                                <span>Jméno:</span>
                                <div class="inputs">
                                    <input type="text" name="name" onChange={this.onChange} value={profile.name} style={{ fontWeight: 'bold' }} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Oslovení:</span>
                                <div class="inputs">
                                    <input type="text" name="salutation" onChange={this.onChange} value={profile.salutation} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Specifikace:</span>
                                <div class="inputs">
                                    <select name="persona_key" onChange={this.onChange} defaultValue={profile.persona_key}>
                                        {profileKeys.map(pk => {
                                            return <option value={pk.id}>{pk.title}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <h3>Profilová fotka <a className="btn-help" onClick={(e) => this.openHelp(e, 'photo')}>?</a></h3>
                            <div className="input-row">
                                <span>Foto:</span>
                                <div class="inputs">
                                    <input type="file" name="photo" accept="image/*" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stávající foto:</span>
                                <div class="inputs">
                                    <img src={`https://www.zofapp.cz/data/zof/profile/${profile.photo_filename}?r=${random}`} style={{ width: '90px', height: 'auto' }} alt="" />
                                </div>
                            </div>
                            <div className="input-row">
                                <span></span>
                                <div class="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="delete_photo" checked={profile.delete_photo === true} style={{ verticalAlign: 'middle' }} /> vymazat foto
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <h3>Vstupní údaje <a className="btn-help" onClick={(e) => this.openHelp(e, 'inputData')}>?</a></h3>
                            <div className="input-row">
                                <span>Datum narození:</span>
                                <div class="inputs">
                                    <select style={{ width: '4em' }} name="birthday_day" defaultValue={profile.birthday_day} onChange={this.onChange}>
                                        {birthdayDays.map(d => {
                                            return <option value={d}>{d}</option>
                                        })};
                                    </select>
                                    <select style={{ width: '8em' }} name="birthday_month" defaultValue={profile.birthday_month} onChange={this.onChange}>
                                        {birthdayMonths.map((d, i) => {
                                            return <option value={(i+1)}>{d}</option>
                                        })};
                                    </select>
                                    <select style={{ width: '5em' }} name="birthday_year" defaultValue={profile.birthday_year} onChange={this.onChange}>
                                        {birthdayYears.map(d => {
                                            return <option value={d}>{d}</option>
                                        })};
                                    </select>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Výška:</span>                                    
                                <div class="inputs">
                                    <input className="short" type="text" name="height" onChange={this.onChange} value={profile.height} /> cm
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Váha:</span>                                    
                                <div class="inputs">
                                    <input className="short" type="text" name="weight" onChange={this.onChange} value={profile.weight} /> kg
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Obvod pasu:</span>                                    
                                <div class="inputs">
                                    <input className="short" type="text" name="diameter_waist" onChange={this.onChange} value={profile.diameter_waist} /> cm    
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Obvod boků:</span>                                    
                                <div class="inputs">
                                    <input className="short" type="text" name="diameter_sides" onChange={this.onChange} value={profile.diameter_sides} /> cm
                                </div>
                            </div>
                            <h3>Bazální metabolismus <a className="btn-help" onClick={(e) => this.openHelp(e, 'bmr')}>?</a></h3>
                            <p>Pokud hodnotu neznáte, nevyplňujte, aplikace ji vypočítá sama.</p>
                            <div className="input-row">
                                <span>Bazální metabolismus (BMR):</span>                                    
                                <div class="inputs">
                                    <input className="short" type="text" name="individual_bmr" onChange={this.onChange} value={profile.individual_bmr} /> kcal
                                </div>
                            </div>
                            <h3>Energetický režim <a className="btn-help" onClick={(e) => this.openHelp(e, 'energeticRegime')}>?</a></h3>
                            <div class="row row-labels-only">
                                {energeticRegimes}
                            </div>
                            <h3>Výdej energie <a className="btn-help" onClick={(e) => this.openHelp(e, 'energyOutput')}>?</a></h3>
                            <div class="row row-labels-only">
                                {energyOutputCoefficients}
                            </div>
                            <h3>Stravovací režimy {vegeRegimesWarning}  <a className="btn-help" onClick={(e) => this.openHelp(e, 'nutritionRegime')}>?</a></h3>
                            <div class="row row-labels-only">
                                {eatingRegimes}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>Barva profilu <a className="btn-help" onClick={(e) => this.openHelp(e, 'color')}>?</a></h3>
                            <div className="input-row">
                                <div class="row row-labels-only">
                                    {profileColorSelect}
                                </div>
                            </div>
                        </div>
                    </div>                  
                    <div className="row">
                        <div className="col">
                        <h3>E-mail, změna hesla  <a className="btn-help" onClick={(e) => this.openHelp(e, 'newPassword')}>?</a></h3>
                            <div className="input-row">
                                <span>Přihlašovací e-mail:</span>
                                <div class="inputs">
                                    <input type="text" disabled={true} readonly={true} name="login_email" value={this.props.data.globals.user.username} style={{background:'none',border:'none',boxShadow:'none',maxWidth:'32em' }} />
                                </div>
                            </div>

                            <p style={{margin:'1.5em 0 .5em 0',fontSize:'.85em'}}>Pokud chcete ponechat stávající heslo, nechte pole prázdná.</p>
                            <div className="input-row">
                                <span>Nové heslo:</span>
                                <div class="inputs">
                                    <input type={state.newPasswordInputType} name="new_password" onChange={this.onChange} value={state.newPw} style={{ maxWidth:'20em' }} />
                                    <a onClick={this.toggleNewPasswordInputType}>{newPasswordInputIcon}</a>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Nové heslo znovu:</span>
                                <div class="inputs">
                                    <input type={state.newPasswordInputType} name="new_password_confirmation" onChange={this.onChange} value={state.newPwConfirmation} style={{ maxWidth:'20em' }} />
                                </div>
                            </div>
                        </div>
                    </div>                  
                    <div className="row">
                        <div className="col">
                        <h3>PIN <a className="btn-help" onClick={(e) => this.openHelp(e, 'pin')}>?</a></h3>
                            <div className="input-row">
                                <span>Váš ZOF PIN:</span>
                                <div className="inputs">
                                    <b>{this.props.data.globals.user.zof_pin}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if(state.tab == 'public') {

            let profileUrl = `https://zofapp.cz/u/id/${profile.user_id}`;

            content = (
                <React.Fragment>
                    <div className="alert alert-info">
                        <i className="fa fa-info-circle"></i>
                        <p>Veřejné údaje slouží k vyplnění Vašeho veřejného profilu (pokud jej máte aktivován), který je umístěn na adrese {profileUrl}</p>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>Osobní údaje</h3>
                            <div className="input-row">
                                <span>Jméno:</span>
                                <div className="inputs">
                                    <input type="text" name="name" onChange={this.onChange} value={account.name} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Datum narození:</span>
                                <div className="inputs">
                                    <input type="text" name="birthday" onChange={this.onChange} value={account.birthday} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Ulice:</span>
                                <div className="inputs">
                                   <input type="text" name="street" onChange={this.onChange} value={account.street}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Město:</span>
                                <div className="inputs">
                                <input type="text" name="city" onChange={this.onChange} value={account.city}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>PSČ:</span>
                                <div className="inputs">
                                    <input type="text" name="zip" onChange={this.onChange} value={account.zip}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Země:</span>
                                <div className="inputs">
                                    <input type="text" name="country" onChange={this.onChange} value={account.country}/>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Telefon:</span>
                                <div className="inputs">
                                    <input type="text" name="phone" onChange={this.onChange} value={account.phone} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Veřejný e-mail:</span>
                                <div className="inputs">
                                    <input type="text" name="public_email" onChange={this.onChange} value={account.public_email} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Více o mně:</span>
                                <div className="inputs">
                                    <textarea name="description" onChange={this.onChange} value={account.description}></textarea>
                                </div>
                            </div>


                            <h3>Profilové foto</h3>
                            <div className="input-row">
                                <span>Foto:</span>
                                <div className="inputs">
                                    <input type="file" name="avatar" accept="image/*" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stávající foto:</span>
                                <div className="inputs">
                                    <img src={`https://www.kurzyatac.cz/data/student-avatar/${state.originalAvatarFilename}?r=${random}`} style={{ width: '90px', height: 'auto' }} alt="" />
                                </div>
                            </div>
                            <div className="input-row">
                                <span></span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="delete_avatar" checked={account.delete_avatar === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> vymazat foto
                                    </label>
                                </div>
                            </div>

                            <h3>Pozadí profilu</h3>
                            <div className="input-row">
                                <span>Foto:</span>
                                <div className="inputs">
                                    <input type="file" name="cover" accept="image/*" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Stávající pozadí profilu:</span>
                                <div className="inputs">
                                    <img src={`https://www.zofapp.cz/data/zof/cover/${state.originalCoverFilename}?r=${random}`} style={{ width: '320px', height: 'auto' }} alt="" />
                                </div>
                            </div>
                            <div className="input-row">
                                <span></span>
                                <div className="inputs">
                                    <label style={{ padding: 0 }}>
                                        <input type="checkbox" name="delete_cover" checked={account.delete_cover === 1} onChange={this.onChange} style={{ verticalAlign: 'middle' }} /> vymazat foto
                                    </label>
                                </div>
                            </div>

                            <h3>Práce</h3>
                            <div className="input-row">
                                <span>Kde pracujete?</span>
                                <div className="inputs">
                                    <input type="text" name="workplace" onChange={this.onChange} value={account.workplace} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Odkaz:</span>
                                <div className="inputs">
                                    <input type="text" name="workplace_url" onChange={this.onChange} value={account.workplace_url} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Adresa:</span>
                                <div className="inputs">
                                    <input type="text" name="workplace_address" onChange={this.onChange} value={account.workplace_address} />
                                </div>
                            </div>
                            <h3>Sociální sítě</h3>
                            <div className="input-row">
                                <span>Facebook:</span>
                                <div className="inputs">
                                    <input type="text" name="social_facebook" onChange={this.onChange} value={account.social_facebook} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Instagram:</span>
                                <div className="inputs">
                                    <input type="text" name="social_instagram" onChange={this.onChange} value={account.social_instagram} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>YouTube:</span>
                                <div className="inputs">
                                    <input type="text" name="social_youtube" onChange={this.onChange} value={account.social_youtube} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Twitter:</span>
                                <div className="inputs">
                                    <input type="text" name="social_twitter" onChange={this.onChange} value={account.social_twitter} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>LinkedIn:</span>
                                <div className="inputs">
                                    <input type="text" name="social_linkedin" onChange={this.onChange} value={account.social_linkedin} />
                                </div>
                            </div>
                            <h3>Odkazy</h3>
                            <div className="input-row">
                                <span>Odkaz 1:</span>
                                <div className="inputs">
                                    <input type="text" name="generic_link_1" onChange={this.onChange} value={account.generic_link_1} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Odkaz 2:</span>
                                <div className="inputs">
                                    <input type="text" name="generic_link_2" onChange={this.onChange} value={account.generic_link_2} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Odkaz 3:</span>
                                <div className="inputs">
                                    <input type="text" name="generic_link_3" onChange={this.onChange} value={account.generic_link_3} />
                                </div>
                            </div>
                            <h3>Oblíbené</h3>
                            <div className="input-row">
                                <span>Potraviny:</span>
                                <div className="inputs">
                                    <textarea name="favorite_foods" onChange={this.onChange} value={account.favorite_foods}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Recepty:</span>
                                <div className="inputs">
                                    <textarea name="favorite_recipes" onChange={this.onChange} value={account.favorite_recipes}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Kuchyně:</span>
                                <div className="inputs">
                                    <textarea name="favorite_cuisine" onChange={this.onChange} value={account.favorite_cuisine}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Kuchařky:</span>
                                <div className="inputs">
                                    <textarea name="favorite_cookbooks" onChange={this.onChange} value={account.favorite_cookbooks}></textarea>
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Restaurace:</span>
                                <div className="inputs">
                                    <textarea name="favorite_restaurants" onChange={this.onChange} value={account.favorite_restaurants}></textarea>
                                </div>
                            </div>
                            <h3>Vzdělání</h3>
                            <div className="input-row">
                                <span>Škola:</span>
                                <div className="inputs">
                                    <input type="text" name="education_school" onChange={this.onChange} value={account.education_school} />
                                </div>
                            </div>
                            <div className="input-row">
                                <span>Kurzy:</span>
                                <div className="inputs">
                                    <input type="text" name="education_courses" onChange={this.onChange} value={account.education_courses} />
                                </div>
                            </div>
                            <h3>Adresa veřejného profilu</h3>
                            <div className="input-row">
                                {publicProfileInput}
                            </div>
                        </div>
                    </div>
                    {loginData}                    
                </React.Fragment>
            );
        }

        //

        return (
            <div className="page">
                <div className="page-header">
                    <h1>Nastavení</h1>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em'}}>
                        {content}
                        <div className="form-submit">
                            <button onClick={this.saveProfile}>Uložit profil</button>
                        </div>
                        {saving}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
