import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from 'components/Loading/index';
import api from 'api';

import { 
    showMessage, 
    closeModal,
    setLectureTaskCompleteSaved,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: data => dispatch(showMessage(data)),
        closeModal: data => dispatch(closeModal(data)),
        setLectureTaskCompleteSaved: data => dispatch(setLectureTaskCompleteSaved(data)),
    };
};

class DialogSaveLectureTask extends Component { 

    constructor(props) {
        super(props);

        let defaultTitle = this.props.defaultTitle;
        if(typeof this.props.data.lectureTaskData.lastLoadedTitle === 'string') {
            defaultTitle = this.props.data.lectureTaskData.lastLoadedTitle;
        }

        this.state = {
            title: defaultTitle,
            saveNewSelected: false,
            comment: '',
            tasks: {},
            overwriteId: 0,
            loading: true,
        };
        this.loadTasks = this.loadTasks.bind(this);
        this.onChange = this.onChange.bind(this);
        this.overwriteTask = this.overwriteTask.bind(this);
        this.doSave = this.doSave.bind(this);
    }

    componentDidMount() {
        this.loadTasks();
    }

    loadTasks() {
        const lectureId = this.props.data.appModeData.lectureId;
        api('lecturetask/list/' + lectureId).then(result => {
            this.setState({
                tasks: result.data.tasks,
                loading: false
            });
        });            
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    overwriteTask(id, e) {
        this.setState({
            overwriteId: id
        }, () => {
            this.doSave();
        });
    }

    doSave() {
        const { data } = this.props;
        const title = this.state.title;
        const comment = this.state.comment;
        const overwriteId = this.state.overwriteId;

        const isComplete = this.props.data.lectureTaskComplete;

        let saveData = {};
        saveData.lecture_id = this.props.data.appModeData.lectureId;
        saveData.title = JSON.stringify(title);
        saveData.comment = JSON.stringify(comment);
        saveData.meals = JSON.stringify(data.meals);
        saveData.groups = JSON.stringify(data.foodGroups);
        saveData.foods = JSON.stringify(data.foods);
        saveData.modifications = JSON.stringify(data.modifications);
        saveData.trainings = JSON.stringify(data.trainings);
        saveData.overwriteId = JSON.stringify(overwriteId);
        saveData.isComplete = JSON.stringify(isComplete);
        saveData = JSON.stringify(saveData);

        let msg;

        if(overwriteId === 0) {
            if(title.trim() == '') {
                alert("Zadejte prosím název úkolu");
                return;
            }

            // Saved task name must be unique

            let titleIsUnique = true;
            this.state.tasks.map(task => {
                if(task.title === title) {
                    titleIsUnique = false;
                }
            });

            if(!titleIsUnique) {
                alert("Úkol se zadaným názvem je již uložen. Přepište jej, nebo zvolte jiný název.");
                return;
            }

            msg = 'Úkol byl uložen.';
        } else {
            msg = 'Úkol byl přepsán.';
        }

        api('save/lecturetask', {post: true, data: saveData}).then(result => {
            this.props.closeModal();
            this.props.showMessage({
                show: true,
                type: 'success',
                message: msg
            });
            this.props.setLectureTaskCompleteSaved(isComplete);
        });
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = data;

        if(state.loading) return <Loading />;

        let taskList = [];
        state.tasks.map(task => {
            let comment = false;
            if(task.comment != '') {
                comment = <p className="comment">Poznámka: {task.comment}</p>;
            }
            taskList.push(
                <div className="lecturetask-item">
                    <article>
                        <h2>{task.title}</h2>
                        <p class="info">
                            {task.course_title} / {task.lecture_title}
                        </p>
                        {comment}
                    </article>
                    <aside>
                        <a onClick={(e) => this.overwriteTask(task.id, e)} className="rename">Přepsat</a>
                    </aside>
                </div>
            );
        });

        let saveNewTask = (
            <div className="lecturetask-item-new">
                <span className="input-label">Název nového úkolu:</span>
                <input type="text" name="title" defaultValue={state.title} onChange={this.onChange} placeholder="Uložit jako... (zadejte název)" />
                <button style={{padding:'.5em 1em',margin:'0'}} data-id="0" type="button" onClick={this.doSave}>Uložit nový úkol</button>
            </div>
        );
    
        let content = (
            <>
                {saveNewTask}
                {taskList}
            </>
        );

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogSaveLectureTask));
