import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import api from 'api';
import Util from 'utility';

import EvaluationPanelContents from 'components/EvaluationPanel/EvaluationPanelContents';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

class EvaluationPanel extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            evaluationProfileId: Util.getFirstProfileId(),
            selectedDDDAttributeKey: '',
            selectedGlossaryItem: {},
            glossaryModalIsOpen: false
        };    
        this.openGlossaryModal = this.openGlossaryModal.bind(this);
        this.closeGlossaryModal = this.closeGlossaryModal.bind(this);
        this.showEvaluationGlossary = this.showEvaluationGlossary.bind(this);
        this.onRecommendedFoodAdded = this.onRecommendedFoodAdded.bind(this);
    }


    componentDidUpdate(prevProps) {
        // Update whole day evaluation - only if wholeDay mode is set
        // In single recipe, do nothing
        if(prevProps.wholeDay === true) {
            let currentProfileId = this.state.evaluationProfileId;
            let newProfileId = this.props.data.profileId;
            if(currentProfileId != newProfileId) {
                this.setState({
                    evaluationProfileId: newProfileId
                }, () => {
                    Util.doLoadDayEvaluation(this.state.evaluationProfileId);
                });
            }
        }


    }

    xxxxxxxxxxxcomponentWillReceiveProps(newProps) {
        // Update whole day evaluation - only if wholeDay mode is set
        // In single recipe, do nothing
        if(this.props.wholeDay === true) {
            let currentProfileId = this.state.evaluationProfileId;
            let newProfileId = newProps.data.profileId;
            if(currentProfileId != newProfileId) {
                this.setState({
                    evaluationProfileId: newProfileId
                }, () => {
                    Util.doLoadDayEvaluation(this.state.evaluationProfileId);
                });
            }
        }
    }

    openGlossaryModal() {
        this.setState({ glossaryModalIsOpen: true });
    }

    closeGlossaryModal() {
        this.setState({ glossaryModalIsOpen: false });
    }    

    showEvaluationGlossary(id) {
        api('glossary/item/' + id).then(result => {
            result = result.data;
            if(result.error) return false;
            this.setState({
                selectedGlossaryItem: result
            });
            this.openGlossaryModal();
        });
    }

    onRecommendedFoodAdded() {
        this.props.onRecommendedFoodAdded();
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        return (
            <>
                <EvaluationPanelContents 
                    beforeContent={this.props.beforeContent}
                    wholeDay={this.props.wholeDay} 
                    results={data.evaluationResults.results} 
                    ddd={data.evaluationResults.ddd} 
                    style={{order:3}} 
                    mainStyle={this.props.mainStyle}
                    quickResults={this.props.quickResults} 
                    evaluationCollapsedAtStart={this.props.evaluationCollapsedAtStart}
                    onRecommendedFoodAdded={this.onRecommendedFoodAdded}
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationPanel);

