import React, { Component } from 'react';
import { connect } from "react-redux";
import { 
    sortFood, 
    setFoodAmount, 
    setFoodAsDrink, 
    addFoodModification,
    removeFoodModification,
    removeFood, 
    moveFoodToPlate,
    setModalContent, 
    openModal, 
    closeModal } from 'actions/index';
import Util from 'utility';
import { Scrollbars } from 'react-custom-scrollbars';
import UnitConvert from 'UnitConverter';
import shortid from 'shortid';
import api from 'api';

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        sortFood: data => dispatch(sortFood(data)),
        setFoodAmount: data => dispatch(setFoodAmount(data)),
        setFoodAsDrink: data => dispatch(setFoodAsDrink(data)),
        addFoodModification: data => dispatch(addFoodModification(data)),
        removeFoodModification: data => dispatch(removeFoodModification(data)),
        removeFood: data => dispatch(removeFood(data)),
        moveFoodToPlate: data => dispatch(moveFoodToPlate(data)),
        setModalContent: data => dispatch(setModalContent(data)),
        openModal: data => dispatch(openModal(data)),
        closeModal: data => dispatch(closeModal(data))
    };
};

class FoodRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemData: props.data.globals.itemData,
            foodWeight: props.food.weight,
            foodWeightString: props.food.weight.toString(),
            foodIsDrink: props.food.is_drink,
            focusedWeightInputId: '',
            normalizedUnit: '',
            invalidWeight: false,
            showModificationsPanel: false,
            uniqid: shortid(),

            selectedModificationHelpItem: false,
            modificationHelpModalIsOpen: false,

            moveToPlateFood: 0,
            moveFoodToPlateTargetId: 0,
            moveFoodToPlateDialogOpen: false
        };

        this.loadFoodDescription = this.loadFoodDescription.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.handleDrinkChange = this.handleDrinkChange.bind(this);
        this.sortFoodInRecipe = this.sortFoodInRecipe.bind(this);
        this.removeFoodFromRecipe = this.removeFoodFromRecipe.bind(this);
        this.handleWeightInputFocus = this.handleWeightInputFocus.bind(this);
        this.handleWeightInputBlur = this.handleWeightInputBlur.bind(this);
        this.focusWeightInput = this.focusWeightInput.bind(this);
        this.toggleModificationsPanel = this.toggleModificationsPanel.bind(this);
        this.modificationToggle = this.modificationToggle.bind(this);
        this.moveToPlate = this.moveToPlate.bind(this);
        this.closeMoveFoodToPlateDialog = this.closeMoveFoodToPlateDialog.bind(this);
        this.handleMoveFoodToPlateChange = this.handleMoveFoodToPlateChange.bind(this);
        this.doMoveFoodToPlate = this.doMoveFoodToPlate.bind(this);
        this.showModificationHelp = this.showModificationHelp.bind(this);
        this.openModificationPanelModal = this.openModificationPanelModal.bind(this);
        this.closeModificationPanelModal = this.closeModificationPanelModal.bind(this);

    }

    focusWeightInput() {
        this.weightInput.focus();
        this.weightInput.select();
    }

    handleWeightChange(e) {
        let f = e.target.value.replace(',', '.') * 1.0;
        this.setState({
            foodWeight: parseFloat(f),
            foodWeightString: e.target.value,
            invalidWeight: false
        });
    }

    handleWeightInputFocus(foodId) {
        this.setState({
            focusedWeightInputId: foodId
        })
    }

    moveToPlate(food) {
        this.setState({
            moveToPlateFood: food,
            moveFoodToPlateDialogOpen: true
        });
    }

    doMoveFoodToPlate(e) {
        if(this.state.moveFoodToPlateTargetId === '') {
            alert("Vyberte cílový talíř");
            return;
        }
        this.props.moveFoodToPlate({
            foodId: this.state.moveToPlateFood.id,
            foodGroupId: this.state.moveFoodToPlateTargetId
        });
        this.closeMoveFoodToPlateDialog();
    }

    closeMoveFoodToPlateDialog() {
        this.setState({
            moveFoodToPlateDialogOpen: false
        });
    }

    handleMoveFoodToPlateChange(e) {
        this.setState({
            moveFoodToPlateTargetId: e.target.value
        });
    }

    loadFoodDescription(foodId) {
        if (this.props.data.globals.accessType === 'demo') {
            alert("Zobrazovat popisy potravin je možná pouze v plnohodnotné verzi aplikace ZOF.");
            return;
        }

        api('food/description/' + foodId).then(result => {
            result = result.data;
            if (result.error) return false;
            this.props.closeModal();
            this.props.openModal({
                'title': result.title, 
                'class': 'dialog dialog-flexible',
                'content': (
                    <div className="zof-food-description">
                        <div dangerouslySetInnerHTML={{ __html: result.description }} />
                    </div>
                )
            });
        });
    }

    closeModal() {
        this.props.closeModal();
    }

    showModificationHelp(prepId) {
        api('modification-help/' + prepId).then(result => {
            result = result.data;
            if(result.error) return false;
            this.setState({
                selectedModificationHelpItem: result
            });
            this.openModificationPanelModal();
        });
    }

    openModificationPanelModal() {
        this.setState({ modificationHelpModalIsOpen: true });
    }

    closeModificationPanelModal() {
        this.setState({ modificationHelpModalIsOpen: false });
    }    


    handleWeightInputBlur(e, foodId) {
        let weight = e.target.value;
        weight = weight.replace(',', '.') * 1;
        let food_id = this.props.food.food_id;
        let food = this.props.food;
        let item = this.state.itemData[food.food_id];

        if(weight < item.weight_min || weight > item.weight_max) {
            alert("Neplatné množství potraviny - zadejte prosím množství od " + item.weight_min + " do " + item.weight_max + " " + item.weight_unit);
            weight = item.weight;
        }

        this.setState({
            foodWeight: weight,
            invalidWeight: false,
            focusedWeightInputId: ''
        }, () => {
            this.props.setFoodAmount({ 
                mealHash: this.props.data.activeMealHash, 
                foodId: this.props.food.id, 
                weight: weight
            });
            Util.doLoadEvaluation();
        });
    }

    handleDrinkChange(e) {
        this.props.setFoodAsDrink({
            mealHash: this.props.data.activeMealHash,
            foodId: this.props.food.id,
            is_drink: e.target.checked ? 1 : 0
        });
        Util.doLoadEvaluation();
    }

    removeFoodFromRecipe(id) {
        if (this.props.data.globals.accessType === 'demo') {
            alert("Mazat potarviny z receptu je možná pouze v plnohodnotné verzi aplikace ZOF.");
            return;
        }
        this.props.removeFood(id);
        Util.doLoadEvaluation();
    }
    
    sortFoodInRecipe(foodId, direction) {
        this.props.sortFood({foodId: foodId, direction: direction});
    }

    toggleModificationsPanel() {
        let showModPanel = this.state.showModificationsPanel;
        this.setState({
            showModificationsPanel: !showModPanel
        });
    }

    modificationToggle(e) {
        if(e.target.checked) {
            this.props.addFoodModification({
                foodId: this.props.food.id,
                modificationId: e.target.value
            });
        } else {
            this.props.removeFoodModification({
                foodId: this.props.food.id,
                modificationId: e.target.value
            });
        }
        Util.doLoadEvaluation();
    }


    render() {
        let food = this.props.food;
        let item = this.state.itemData[food.food_id];

        const { data } = this.props;
        const { state } = this;
        const { globals } = data;

        let modificationsNumber = 0;
        let modificationsList = [];
        if(typeof this.props.data.modifications[food.id] !== "undefined") {
            let mods = this.props.data.modifications[food.id];
            mods.forEach((key, val) => {
                modificationsNumber++;
                modificationsList.push(this.props.data.globals.preparation_types[key]);
            });
        }

        if(modificationsNumber === 0) {
            modificationsNumber = false;
        } else {
            modificationsNumber = <span>{modificationsNumber}</span>;
        }
        if(modificationsList.length > 0) {
            modificationsList = modificationsList.join(', ');
        } else {
            modificationsList = false;
        }


        let modificationHelpModal = false;
        if (state.modificationHelpModalIsOpen) {
            modificationHelpModal = (
                <div className="dialog dialog-save-recipe" onClick={this.closeModificationHelpModal}>
                    <div className="dialog-body">
                        <h2>{this.state.selectedModificationHelpItem.title} <a><i onClick={this.closeModificationPanelModal} className="fa fa-times"></i></a></h2>
                        <div className="dialog-content">
                            <div style={{ height: '250px' }}>
                            <Scrollbars heightrelativetoparent="1">
                                <div style={{ flex: '1', overflow: 'hidden', padding: '0 14px 0 0' }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.selectedModificationHelpItem.description }}></div>
                                    <p style={{ paddingTop: '1em', marginTop: '1em', borderTop: '1px solid #ddd' }}>Více se o nutriční kvalitě výživy dozvíte na <a style={{ color: '#4eb32d' }} href="https://www.kurzyatac.cz">www.kurzyatac.cz</a></p>
                                </div>
                            </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // Normalized unit info

        let foodItem = {
            id: food.food_id,
            selectedWeight: food.weight,
            weight: this.props.data.globals.itemData[food.food_id].weight,
            norm_unit_amount: this.props.data.globals.itemData[food.food_id].norm_unit_amount * 1.0,
            norm_unit_unit: this.props.data.globals.itemData[food.food_id].norm_unit_unit,
            norm_unit2_amount: this.props.data.globals.itemData[food.food_id].norm_unit2_amount * 1.0,
            norm_unit2_unit: this.props.data.globals.itemData[food.food_id].norm_unit2_unit
        };

        let uc = new UnitConvert;
        let normalizedUnitInfo = uc.convert(foodItem);
        let normalizedUnitInfoRaw = normalizedUnitInfo;
        if(normalizedUnitInfo !== null && normalizedUnitInfo != '' && normalizedUnitInfo != ' null') {
            normalizedUnitInfo = <div className="normalized-unit-info">{normalizedUnitInfo}</div>;
        } else {
            normalizedUnitInfo = false;
        }



        // Weight range info

        let weightRangeInfo = false;
        if(food.id === this.state.focusedWeightInputId) {
            weightRangeInfo = <div className="weight-range-info">
                                <p>{normalizedUnitInfo}</p>
                                <p>Rozpětí: {item.weight_min} - {item.weight_max} {item.weight_unit}</p>
                            </div>;
        }

        //


/*
        let mealProfiles = [];
        if(this.props.data.activeMealHash != '') {
            // ATTN !
            //mealProfiles = this.props.data.meals[this.props.data.activeMealHash].profiles;
        }

        let profilesAreEmpty = Object.keys(mealProfiles).every(k => !Object.keys(mealProfiles[k]).length)
        if(profilesAreEmpty) {
            mealProfiles = Object.keys(this.props.data.globals.profiles);
            mealProfiles = mealProfiles.map(Number);
        }
*/


        // mealProfiles = array of profile IDs
        let mealProfiles = Object.keys(this.props.data.globals.profiles).map(Number);

        // Is this food undesirable for current user's profile?

        let undesirableFoodForProfiles = [];
        for(let profileId in this.props.data.globals.undesirableFoods) {
            profileId = profileId * 1;
            if(!mealProfiles.includes(profileId)) continue;
            let arr = this.props.data.globals.undesirableFoods[profileId];
            if(arr.includes(food.food_id)) {
                undesirableFoodForProfiles.push(profileId);
            }
        }

        // Is this food undesirable for lecture task profile?

        if(false && typeof this.props.data.appModeData !== 'undefined') {
            // Lecture task undesirable foods override profile's undesirable food - reset the array
            undesirableFoodForProfiles = [];
            if(typeof this.props.data.appModeData.undesirableFoods !== 'undefined') {
                if(this.props.data.appModeData.undesirableFoods.includes(food.food_id)) {
                    if(typeof this.props.data.appModeData.profileId !== 'undefined') {
                        let profileId = this.props.data.appModeData.profileId * 1;
                        undesirableFoodForProfiles.filter(item => item !== profileId);
                        undesirableFoodForProfiles.push(profileId);
                    }
                }
            }
        }

        let itemRowClass = `zof-item-row`;
        if(undesirableFoodForProfiles.length > 0) {
            itemRowClass = `zof-item-row zof-item-row-undesirable`;
        }

        let undesirableFoodWarning = [];
        undesirableFoodForProfiles.map(profileId => {

            let profile = this.props.data.globals.profiles[profileId];
            let profileColor = profile.profile_color;
            if (profileColor === '' || !profileColor) profileColor = '999999';
            let profileStyle = {
                backgroundColor: '#' + profileColor,
                color: '#fff'
            };
            undesirableFoodWarning.push(
                <span key={profile.name} style={profileStyle} className="undesirable-food-warning-profile">{profile.name}</span>
            );
        });

        let foodWeightFmt = this.state.foodWeightString;
        //let foodWeightFmt = foodItem.selectedWeight; // this.state.foodWeightString;

        let modificationsButton = false;
        if (!Util.isReadOnly()) {
            modificationsButton = <div className="modifications" data-food-id={food.id} onClick={this.toggleModificationsPanel}>
                <a>Úpravy {modificationsNumber}</a>
            </div>;
        }

        let foodRowOptions = false;
        if(!Util.isReadOnly()) {
            foodRowOptions = <React.Fragment>
            <div className="buttons">
                <a className="item-move-to-plate" onClick={() => this.moveToPlate(food)}>
                    <i className="fa fa-bars"></i>
                </a>
                <a className="item-description" onClick={() => this.loadFoodDescription(food.food_id)}>
                    <i className="fa fa-question"></i>
                </a>
                <a className="item-sort" onClick={() => this.sortFoodInRecipe(food.id, -1)}>
                    <i className="fa fa-arrow-up"></i>
                </a>
                <a className="item-sort" onClick={() => this.sortFoodInRecipe(food.id, 1)}>
                    <i className="fa fa-arrow-down"></i>
                </a>
                <a className="item-remove" onClick={() => this.removeFoodFromRecipe(food.id)}>
                    <i className="fa fa-times"></i>
                </a>
            </div>
            </React.Fragment>;
        }

        if(this.props.action == 'product-create' || this.props.action == 'product-edit') {
            foodRowOptions = <React.Fragment>
            <div className="buttons">
                <a className="item-description" onClick={() => this.loadFoodDescription(food.food_id)}>
                    <i className="fa fa-question"></i>
                </a>
                <a className="item-sort" onClick={() => this.sortFoodInRecipe(food.id, -1)}>
                    <i className="fa fa-arrow-up"></i>
                </a>
                <a className="item-sort" onClick={() => this.sortFoodInRecipe(food.id, 1)}>
                    <i className="fa fa-arrow-down"></i>
                </a>
                <a className="item-remove" onClick={() => this.removeFoodFromRecipe(food.id)}>
                    <i className="fa fa-times"></i>
                </a>
            </div>
            </React.Fragment>;
        }

        let moveFoodToPlateDialog = false;
        if (this.state.moveFoodToPlateDialogOpen) {

            let foodGroupsOptions = [<option value="">Vyberte talíř...</option>];
            for(let g in data.foodGroups[data.activeMealHash]) {
                let group = data.foodGroups[data.activeMealHash][g];
                let unnamedTitle = '';
                if(group.type === 'plate') unnamedTitle = 'Nepojmenovaný talíř';
                if(group.type === 'bowl') unnamedTitle = 'Nepojmenovaná miska';
                if(group.type === 'cup') unnamedTitle = 'Nepojmenovaný šálek';
                if(group.type === 'glass') unnamedTitle = 'Nepojmenovaná sklenice';
                let fgTitle = group.title;
                if(fgTitle === '') fgTitle = unnamedTitle;
                foodGroupsOptions.push(
                    <option value={g}>{fgTitle}</option>
                );
            }

            moveFoodToPlateDialog = (
                <div className="dialog dialog-save-recipe">
                    <div className="dialog-body">
                        <h2>Přesunout potravinu „{this.state.moveToPlateFood.title}“ <a><i onClick={this.closeMoveFoodToPlateDialog} className="fa fa-times"></i></a></h2>
                        <div className="dialog-content">
                            <table><tbody>
                                <tr><td>Přesunout do:</td><td><select onChange={this.handleMoveFoodToPlateChange}>{foodGroupsOptions}</select></td></tr>
                                <tr><td></td><td><input type="button" onClick={(e) => this.doMoveFoodToPlate(e)} value="Přesunout" /></td></tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
            );
        }

        let foodRow = false;

        undesirableFoodWarning = [];
        undesirableFoodForProfiles.map(profileId => {  
            let profile = this.props.data.globals.profiles[profileId];
            let profileColor = profile.profile_color;
            if (profileColor == '' || !profileColor) profileColor = '999999';
            let profileStyle = {
                backgroundColor: '#' + profileColor,
                color: '#fff'
            };
            undesirableFoodWarning.push(
                <span key={profile.name} style={profileStyle} className="undesirable-food-warning-profile">{profile.name}</span>
            );
        });
        if(undesirableFoodWarning.length > 0) {
            undesirableFoodWarning = (
                <div className="item-info item-info-undesirable">
                    <div className="item-info-icon">
                        <i className="fa fa-exclamation-triangle"></i>
                    </div>
                    <div className="item-undesirable-profiles">
                        <span>Nevhodné pro:</span>{undesirableFoodWarning}
                    </div>
                </div>
            );
        } else {
            undesirableFoodWarning = false;
        }

        let alergenWarning = [];
        if(typeof this.props.data.globals.foodAlergens[food.food_id] === 'object') {
            this.props.data.globals.foodAlergens[food.food_id].map(id => {
                alergenWarning.push(this.props.data.globals.itemAlergens[id]);
            });
        }
        if(alergenWarning.length > 0) {
            console.log(typeof undesirableFoodWarning);
            if (typeof undesirableFoodWarning === 'object') {
                alergenWarning = <p style={{color:'#f00',fontWeight:'bold',fontSize:'.8em',margin:'7px 0 0'}}>Alergeny: {alergenWarning.join(', ')}</p>;
            } else {
                alergenWarning = false; //<p style={{fontSize:'.8em',margin:'7px 0 0'}}>Alergeny: {alergenWarning.join(', ')}</p>;
            }
        }


        let modList = [];
        if(typeof this.props.data.modifications[food.id] !== "undefined") {
            let mods = this.props.data.modifications[food.id];
            mods.forEach((key, val) => {
                modList.push(this.props.data.globals.preparation_types[key]);
            });
        }
        modList = modList.length > 0 ? modList.join(', ') : 'Bez úprav';

        let drinkModeSelection = false;
        if(item.is_drink == 1) {
            let cbName = 'is_drink_' + food.food_id;
            drinkModeSelection = (
                <label><input type="checkbox" name={cbName} onChange={this.handleDrinkChange} checked={food.is_drink == 1}/> pitný režim</label>
            );
        }

        itemRowClass = 'food-item-row';
        if(undesirableFoodForProfiles.length > 0) {
            itemRowClass = 'food-item-row food-item-row-undesirable';
        }

        let normalizedUnitInfoElement = false;
        if(normalizedUnitInfo != null && normalizedUnitInfo != "") {
            normalizedUnitInfoElement = <div className="item-weight-normalized">{normalizedUnitInfo}</div>;
        }

        let modificationsPanel =  <div className="item-modifications-list">{modList}</div>;
        if(this.state.showModificationsPanel) {
            let modList = [];

            let disableUnchecked = false;
            if (typeof data.modifications[food.id] !== 'undefined') {
                if(data.modifications[food.id].length >= 6) {
                    disableUnchecked = true;
                }
            }

            for (let prepKey in globals.preparation_type_ids_sorted) {
                let prepId = globals.preparation_type_ids_sorted[prepKey];
                if(prepId == 0) continue;
                let checked = false;
                if (typeof data.modifications[food.id] !== 'undefined') {
                    if (data.modifications[food.id].indexOf(prepId) > -1) {
                        checked = true;
                    }
                }

                let icon = <div className="icon"><img src={`https://www.zofapp.cz/data/zof/modifications/${prepId}.svg`} alt="" /></div>;

                let cbName = `cb_${this.state.uniqid}_[]`;
                let prepUniqId = `prep_${this.state.uniqid}_${prepId}`;

                if(disableUnchecked) {
                    if(checked) {
                        modList.push(
                            <div className="modification-option">
                                <input type="checkbox" name={cbName} id={prepUniqId} value={prepId} checked={checked} onChange={this.modificationToggle} />
                                <label htmlFor={prepUniqId}>
                                    {icon}
                                    <i className="fa fa-check"></i> 
                                    <span>{this.props.data.globals.preparation_types[prepId]}</span>
                                </label>
                            </div>
                        );
                    } else {
                        modList.push(
                            <div className="modification-option">
                                <input type="checkbox" name={cbName} id={prepUniqId} value={prepId} checked={checked} onChange={this.modificationToggle} />
                                <label htmlFor={prepUniqId} style={{ opacity: .4 }}>
                                    {icon}
                                    <i className="fa fa-check"></i> 
                                    <span>{this.props.data.globals.preparation_types[prepId]}</span>
                                </label>
                            </div>
                        );
                    }
                } else {
                    modList.push(
                        <div className="modification-option">
                            <input type="checkbox" name={cbName} id={prepUniqId} value={prepId} checked={checked} onChange={this.modificationToggle} />
                            <label htmlFor={prepUniqId}>
                                {icon}
                                <i className="fa fa-check"></i> 
                                <span>{this.props.data.globals.preparation_types[prepId]}</span>
                            </label>
                            <a className="link-help" onClick={() => this.showModificationHelp(prepId)}>
                            </a>
                        </div>
                    );
                }
            }

            modificationsPanel = <div className="item-modifications-list">{modList}</div>;
        }

        let amountChange = (
            <div className="item-info item-info-weight" onClick={this.focusWeightInput}>
                <div className="item-info-icon">
                    <i className="fa fa-balance-scale"></i>
                </div>
                <div className="item-weight-input">
                    <input ref={(input) => { this.weightInput = input; }} className={this.state.invalidWeight ? 'invalid' : ''} type="text" name="weight" onFocus={() => this.handleWeightInputFocus(food.id)} onBlur={(e) => this.handleWeightInputBlur(e, food.id)} onChange={this.handleWeightChange} value={foodWeightFmt} />
                    <span>{item.weight_unit}</span>
                </div>
                {normalizedUnitInfoElement}
            </div>
        );

        let modifications = (
            <div className="item-info item-info-modifications">
                <div className="item-info-icon modifications" data-food-id={food.id} onClick={this.toggleModificationsPanel}>
                    <i className="fa fa-tasks"></i>
                </div>
                {modificationsPanel}
            </div>
        );


        if(this.props.data.plannerEditMode == 'GASTRO_DAILY_MENU') {
            amountChange = (
                <div className="item-info item-info-weight">
                    <div className="item-info-icon">
                        <i className="fa fa-balance-scale"></i>
                    </div>
                    <div className="item-weight-input">
                        <input type="text" readOnly={true} name="weight" value={foodWeightFmt} style={{ border: 'none' }} />
                        <span style={{ border: 'none' }}>{item.weight_unit}</span>
                    </div>
                    {normalizedUnitInfoElement}
                </div>               
            );
            modifications = (
                <div className="item-info item-info-modifications">
                    <div style={{ padding: '0' }} className="item-modifications-list">{modList}</div>
                </div>
            );
        }

        // Read-only mode

        if(this.props.readOnly === true) {
            amountChange = (
                <div className="item-info item-info-weight">
                    <div className="item-info-icon">
                        <i className="fa fa-balance-scale"></i>
                    </div>
                    <div className="item-weight-input">
                        <span style={{display:'inline-block',padding:'4px 2px',border:'none'}}>
                            {foodWeightFmt}
                            {'\u00A0'}
                            {item.weight_unit}
                        </span>
                    </div>
                    {normalizedUnitInfoElement}
                </div>
            );
            modifications = (
                <div className="item-info item-info-modifications">
                    <div style={{ padding: '0' }} className="item-modifications-list">{modList}</div>
                </div>
            );
            if(state.foodIsDrink == 1) {
                drinkModeSelection = (
                    <span style={{margin:'0 0 0 1em',fontSize:'.75em',color:'#555'}}>
                        <i className="fa fa-check"></i>{'\u00A0'}pitný režim
                    </span>
                );
            } else {
                drinkModeSelection = false;
            }
            foodRowOptions = false;
        }

        if(this.props.data.appMode === 'RECIPE_EDITOR') {
            return (
                <div className={itemRowClass} key={food.id}>
                    <div className="icon">
                        <img src={food.image} alt="" />
                    </div>
                    <div className="title">
                        <p style={{fontSize:'1.1em'}}><strong>{food.title}</strong></p>
                        <p>{parseFloat(this.state.foodWeightString)}{'\u00A0'}{item.weight_unit}</p>
                        <p>{modList}</p>
                    </div>
                </div>
            );    
        }

        return (
            <div className={itemRowClass} key={food.id}>
                <div className="icon">
                    <img src={food.image} alt="" />
                </div>
                <div className="title">
                    <h4>
                        <strong>{food.title}</strong> 
                        {drinkModeSelection}
                    </h4>
                    {amountChange}
                    {modifications}
                    {alergenWarning}
                    {undesirableFoodWarning}
                </div>
                {foodRowOptions}
                {moveFoodToPlateDialog}
                {modificationHelpModal}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodRow);
