import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import store from 'store/index';
import api from 'api';
import ReactGA from 'react-ga';

// Generic app mode components

import Loading from 'components/Loading/index';
import Header from 'components/Header';
import Index from 'components/Index';
import RecipeList from 'components/Recipe/RecipeList';
import RecipeEditor from 'components/Recipe/RecipeEditor/RecipeEditor';
import RecipeShare from 'components/RecipeShare/index';
import RecipeSaveToMyRecipes from 'components/RecipeSaveToMyRecipes/index';
import Plan from 'components/Plan/index';
import Generator from 'components/Generator/index';
import FoodList from 'components/FoodList/index';
import MenuList from 'components/MenuList/index';
import MenuInsert from 'components/MenuInsert/index';
import MenuShare from 'components/MenuShare/index';
import MyFood from 'components/MyFood/index';
import User from 'components/User/index';
import Profile from 'components/Profile/index';
import News from 'components/News/index';
import Support from 'components/Support/index';
import GastroProfilePanel from 'components/GastroProfilePanel/index';
import Welcome from 'components/Welcome/index';
import Clients from 'components/Clients/index';
import ClientPage from 'components/ClientPage/index';
import ClientChat from 'components/ClientChat/index';
import ClientData from 'components/ClientData/index';
import ProfileCreate from 'components/Profiles/ProfileCreate/index';
import CookbookBuy from 'components/CookbookBuy/index';

// LECTURE app mode components

import LectureIntro from 'components/LectureIntro/index';
import LectureTaskLoad from 'components/LectureTaskLoad/index';

//

import { 
    loginUser, 
    setAppMode,
    setAppModeData,
    clearAppModeData,
    loadGlobals, 
    showMessage, 
    closeModal,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        loginUser: data => dispatch(loginUser(data)),
        setAppMode: data => dispatch(setAppMode(data)),
        setAppModeData: data => dispatch(setAppModeData(data)),
        clearAppModeData: data => dispatch(clearAppModeData(data)),
        loadGlobals: data => dispatch(loadGlobals(data)),
        showMessage: data => dispatch(showMessage(data)),
        closeModal: data => dispatch(closeModal(data)),
    };
};

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'default',
            modalTarget: null,
            initialized: false
        };
        this.closeMessage = this.closeMessage.bind(this);
        this.saveModalTarget = this.saveModalTarget.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        // If the current user has not yet created a profile,
        // redirect him to the profile creation page

        ReactGA.initialize('UA-56472407-8');
        ReactGA.pageview(window.location.pathname + window.location.search);       

        if(typeof prevProps.data.globals.profiles == 'object') {
            const currentUrl = window.location.pathname;
            if(Object.keys(prevProps.data.globals.profiles).length == 0 && currentUrl != '/profiles/create') {
                this.props.history.push('/profiles/create');
                return;
            }
        }
    }

    async componentDidMount() {
        const state = store.getState();

        let appModeSet = false;

        // Set mode based on host (app-dev)
        // ATTN: / TODO: change hostname to something better later
        if(window.location.hostname == 'x_localhost' ||
           window.location.hostname == 'x_app-dev.zofapp.cz'
        ) {
            this.setState({mode: 'RECIPE_EDITOR'});
            this.props.setAppMode('RECIPE_EDITOR');
            this.props.setAppModeData({'recipeId': 200});
            appModeSet = true;
        }

        // Check app mode, if not set already
        const mode = localStorage.getItem('zofapp_mode');

        if(!appModeSet && mode) {
            const modeData = localStorage.getItem('zofapp_mode_data');
            if(mode == 'LECTURE') {
                const lectureId = modeData * 1;
                if(lectureId > 0) {
                    this.setState({mode: 'LECTURE'});
                    this.props.setAppMode('LECTURE');
                    this.props.setAppModeData({lectureId: lectureId});
                }
            }
            if(mode == 'RECIPE_EDITOR') {
                const recipeId = modeData * 1;
                if(recipeId > 0) {
                    this.setState({mode: 'RECIPE_EDITOR'});
                    this.props.setAppMode('RECIPE_EDITOR');
                    this.props.setAppModeData({'recipeId': recipeId});
                }
            }
        }

        // If user is not logged in...
        if(state.user === false) {

            if(mode !== 'RECIPE_EDITOR') {

                // ...check stored JWT token
                const jwtToken = localStorage.getItem('jwt_token');

                // If no JWT token stored, redirect to login
                if(!jwtToken || jwtToken === null || jwtToken === '') {  
                    this.props.history.push('/login');
                    return;
                }

                // Check if stored token is valid
                let result = await api('check-token');
                if(result.data.valid === 0) {
                    this.props.history.push('/login');
                    return;
                }

            }


            let startupDataUrl = 'load/startup-data';
            if(mode === 'RECIPE_EDITOR') startupDataUrl = 'load/startup-data-public';


            // Load startup data and finish initializing
            api(startupDataUrl).then((result) => {
                this.props.loadGlobals(result.data);
                this.props.loginUser({
                    user: result.data.user,
                    callback: () => {
                        this.setState({
                            initialized: true
                        });
                    }
                });
            });
        } 
    }

    closeMessage() {
        this.props.showMessage({show:false});        
    }

    saveModalTarget(e) {
        this.setState({
          modalTarget: e.target
        });
      };


    closeModal(e) {
        e.stopPropagation();
        if (this.state.modalTarget === e.currentTarget) {
        //if(e.currentTarget == e.target) {
            if(typeof this.props.data.modalOnClose === 'function') {
                this.props.data.modalOnClose();
            }
            this.props.closeModal();
        }
    }

    render() {
        let content = <Loading />;

        if(this.state.initialized) {
            const state = this.props.data;
            let message = false;
            if(this.props.data.message.show) {
                message = <div className={`message message-${state.message.type}`}>
                    {state.message.type == 'info' && <i className="fa fa-info-circle"></i>}
                    {state.message.type == 'error' && <i className="fa fa-exclamation-triangle"></i>}
                    {state.message.type == 'success' && <i className="fa fa-check-circle"></i>}
                    <p>{state.message.message}</p>
                    <a onClick={this.closeMessage}><i class="fa fa-times"></i></a>
                </div>;
            }

            let modal = false;
            if(this.props.data.modalOpened) {
                let dialogClass = ['dialog'];
                if(this.props.data.modalClass != '') dialogClass.push(this.props.data.modalClass);

                let closeButton = false;
                if(this.props.data.closeButton != '') {
                    closeButton = <p className="dialog-close-button"><a onClick={this.closeModal}>{this.props.data.closeButton}</a></p>;
                }

                modal = (
                    <div className={dialogClass.join(' ') } onMouseDown={this.saveModalTarget} onClick={this.closeModal}>
                        <div className="dialog-body">
                            <h2>{this.props.data.modalTitle} <a><i onClick={this.closeModal} className="fa fa-times"></i></a></h2>
                            <div className="dialog-content">
                                {this.props.data.modalContent}
                                {closeButton}
                            </div>
                        </div>
                    </div>
                );
            }

            // Default app mode

            if(this.state.mode === 'default') {
                content = (
                    <>
                        <Header />
                        <main role="main">
                            <Switch>
                                <Route path="/" exact={true} component={Index} />
                                <Route path="/recipe" exact={true} component={RecipeList} />
                                <Route path="/recipe/open/:id" render={(props) => <RecipeEditor {...props} />} />
                                <Route path="/recipe/edit/meal/:hash" render={(props) => <RecipeEditor {...props} action="meal-edit" />} />
                                <Route path="/recipe/create" exact={true} render={(props) => <RecipeEditor {...props} action="recipe-new" />} />   
                                <Route path="/recipe/share/:id/:profile" component={RecipeShare} />
                                <Route path="/recipe/save-to-my-recipes/:hash" component={RecipeSaveToMyRecipes} />
                                <Route path="/recipe/:action" component={RecipeList} />
                                <Route path="/plan" exact={true} component={Plan} />
                                <Route path="/plan/:date/:action" component={Plan} />
                                <Route path="/plan/:date" component={Plan} />
                                <Route path="/generator" exact={true} component={Generator} />
                                <Route path="/food" exact={true} component={FoodList} />
                                <Route path="/food/:action" component={FoodList} />
                                <Route path="/menu/detail/:id" component={MenuList} />
                                <Route path="/menu/insert/:id/:date" component={MenuInsert} />
                                <Route path="/menu/share/:id" component={MenuShare} />
                                <Route path="/menu" exact={true} component={MenuList} />
                                <Route path="/myfood" component={MyFood} />
                                <Route path="/user" component={User} />
                                <Route path="/profile" exact={true} component={Profile} />
                                <Route path="/profiles/create" exact={true} component={ProfileCreate} />
                                <Route path="/news" exact={true} component={News} />
                                <Route path="/support" exact={true} component={Support} />
                                <Route path="/gastro/profile" exact={true} component={GastroProfilePanel} />
                                <Route path="/welcome" exact={true} component={Welcome} />
                                <Route path="/clients" exact={true} component={Clients} />
                                <Route path="/clients/:id" exact={true} component={ClientPage} />
                                <Route path="/clients/chat/:id" component={ClientChat} />
                                <Route path="/clients/data/:id" component={ClientData} />
                                <Route path="/clients/:id/:page" exact={true} component={ClientPage} />
                                <Route path="/cookbook/buy" exact={true} component={CookbookBuy} />
                                <Route path="/cookbook/buy/cookbook" exact={true} component={CookbookBuy} />
                            </Switch>
                        </main>
                        {message}
                        {modal}
                    </>
                );
            }


            // LECTURE app mode

            if(this.state.mode === 'LECTURE') {

                content = (
                    <>
                        <Header />
                        <main role="main">
                            <Switch>
                                <Route path="/" exact={true} component={LectureIntro} />
                                <Route path="/plan" exact={true} component={Plan} />
                                <Route path="/plan/task" component={Plan} />
                                <Route path="/load/task/:id/:lectureId" component={LectureTaskLoad} />
                                <Route path="/recipe/edit/meal/:hash" render={(props) => <RecipeEditor {...props} action="lecture-task" />} />
                                <Route path="/user" component={User} />
                                <Route path="/support" exact={true} component={Support} />
                                <Route path="/recipe" exact={true} component={RecipeList} />
                                <Route path="/recipe/open/:id" render={(props) => <RecipeEditor {...props} />} />
                                <Route path="/recipe/edit/meal/:hash" render={(props) => <RecipeEditor {...props} action="meal-edit" />} />
                                <Route path="/recipe/create" exact={true} render={(props) => <RecipeEditor {...props} action="recipe-new" />} />   
                                <Route path="/recipe/share/:id/:profile" component={RecipeShare} />
                                <Route path="/recipe/save-to-my-recipes/:hash" component={RecipeSaveToMyRecipes} />
                                <Route path="/recipe/:action" component={RecipeList} />
                                <Route path="/profiles/create" exact={true} component={ProfileCreate} />
                            </Switch>
                        </main>
                        {message}
                        {modal}
                    </>
                );
            }


            // RECIPE_EDITOR app mode

            if(this.state.mode === 'RECIPE_EDITOR') {

                content = (
                    <>
                        <main role="main">
                            <Switch>
                                <Route path="/" render={(props) => <RecipeEditor {...props} action="recipe-editor" />} />
                            </Switch>
                        </main>
                        {message}
                        {modal}
                    </>
                );
            }
            

        } 

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
