export const SET_DATA = "SET_DATA";
export const LOADING_ON = "LOADING_ON";
export const LOADING_OFF = "LOADING_OFF";
export const SET_MENU_CHANGED = "SET_MENU_CHANGED";
export const SET_RECIPE_CHANGED = "SET_RECIPE_CHANGED";
export const SET_USER = "SET_USER";
export const SET_APP_MODE = "SET_APP_MODE";
export const SET_APP_MODE_DATA = "SET_APP_MODE_DATA";
export const CLEAR_APP_MODE_DATA = "CLEAR_APP_MODE_DATA";
export const SET_APP_SUBMODE = "SET_APP_SUBMODE";
export const SET_GLOBAL = "SET_GLOBAL";
export const LOAD_GLOBALS = "LOAD_GLOBALS";
export const MERGE_GLOBALS = "MERGE_GLOBALS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SET_LECTURETASK_DATA = "SET_LECTURETASK_DATA";
export const SET_LECTURETASK_COMPLETE = "SET_LECTURETASK_COMPLETE";
export const SET_LECTURETASK_COMPLETE_SAVED = "SET_LECTURETASK_COMPLETE_SAVED";

export const RESET_RECIPE = "RESET_RECIPE";

let messageTimeout = false;

export function setData(data) {
    return { type: SET_DATA, data }
}

export function loadingOn(data) {
    return { type: LOADING_ON, data }
}

export function loadingOff(data) {
    return { type: LOADING_OFF, data }
}

export function setUser(data) {
    return { type: SET_USER, data }
}

export function setAppMode(data) {
    return { type: SET_APP_MODE, data }
}

export function setAppModeData(data) {
    return { type: SET_APP_MODE_DATA, data }
}

export function clearAppModeData(data) {
    return { type: CLEAR_APP_MODE_DATA, data }
}

export function setAppSubMode(data) {
    return { type: SET_APP_SUBMODE, data }
}

export function setLectureTaskData(data) {
    return { type: SET_LECTURETASK_DATA, data }
}

export function setLectureTaskComplete(data) {
    return { type: SET_LECTURETASK_COMPLETE, data }
}

export function setLectureTaskCompleteSaved(data) {
    return { type: SET_LECTURETASK_COMPLETE_SAVED, data }
}

export function setMenuChanged(data) {
    return { type: SET_MENU_CHANGED, data }
}

export function setRecipeChanged(data) {
    return { type: SET_RECIPE_CHANGED, data }
}

export function loginUser(data) {
    return (dispatch) => {
        dispatch(setUser(data.user));
        data.callback();
    }
}

export function logoutUser(data) {
    return (dispatch) => {
        dispatch(setUser(false));
        data.callback();
    }
}

export function loadGlobals(data) {
    return { type: LOAD_GLOBALS, data }
}

export function mergeGlobals(data) {
    return { type: MERGE_GLOBALS, data }
}

export function setGlobal(data) {
    return { type: SET_GLOBAL, data }
}

export function showMessageFunc(data) {
    return { type: SHOW_MESSAGE, data }
}

export function hideMessage() {
    const data = {show: false, type: 'info', message: ''};
    return { type: SHOW_MESSAGE, data};
}

export function showMessage(data) {
    return (dispatch) => {
        dispatch(showMessageFunc(data));
        if(data.show === true) {
            if(messageTimeout) clearTimeout(messageTimeout);
            messageTimeout = setTimeout(() => {
                dispatch(hideMessage());
            }, 4000);
        }
    }
}

export function loadDayPlanCallback(data) {
    return (dispatch) => {
        dispatch(loadDayPlan(data));
        data.callback();
    }
}

export function resetRecipe(data) {
    return { type: RESET_RECIPE, data }
}

export function resetRecipeCallback(data) {
    return (dispatch) => {
        dispatch(resetRecipe(data));
        data.callback();
    }
}


export const SET_PRODUCT_DESCRIPTION = 'SET_PRODUCT_DESCRIPTION';

export function setProductDescription(data) {
    return { type: SET_PRODUCT_DESCRIPTION, data }
}



// ==============================================================
// OLD actions
// ==============================================================







export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';

export function addActivity(data) {
    return { type: ADD_ACTIVITY, data }
}

export function removeActivity(data) {
    return { type: REMOVE_ACTIVITY, data }
}

export function updateActivity(data) {
    return { type: UPDATE_ACTIVITY, data }
}

// Trainings

export const SET_TRAINING = 'SET_TRAINING';
export const ADD_TRAINING = 'ADD_TRAINING';
export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const SET_TRAINING_START = 'SET_TRAINING_START';
export const SET_TRAINING_LENGTH = 'SET_TRAINING_LENGTH';
export const SET_TRAINING_DESCRIPTION = 'SET_TRAINING_DESCRIPTION';
export const REMOVE_TRAINING = 'REMOVE_TRAINING';

export function setTraining(data) {
    return { type: SET_TRAINING, data }
}

export function addTraining(data) {
    return { type: ADD_TRAINING, data }
}

export function updateTraining(data) {
    return { type: UPDATE_TRAINING, data }
}

export function setTrainingStart(data) {
    return { type: SET_TRAINING_START, data }
}

export function setTrainingLength(data) {
    return { type: SET_TRAINING_LENGTH, data }
}

export function setTrainingDescription(data) {
    return { type: SET_TRAINING_DESCRIPTION, data }
}

export function removeTraining(data) {
    return { type: REMOVE_TRAINING, data }
}

//Food

export const ADD_FOOD_GROUP = 'ADD_FOOD_GROUP';
export const SET_FOOD_GROUP_TITLE = 'SET_FOOD_GROUP_TITLE';
export const SET_FOOD_GROUP_DESCRIPTION = 'SET_FOOD_GROUP_DESCRIPTION';
export const DELETE_EMPTY_FOOD_GROUPS = 'DELETE_EMPTY_FOOD_GROUPS';
export const CHANGE_FOOD_GROUP_TYPE = 'CHANGE_FOOD_GROUP_TYPE';
export const OPEN_FOOD_GROUP = 'OPEN_FOOD_GROUP';
export const CLOSE_FOOD_GROUP = 'CLOSE_FOOD_GROUP';
export const MOVE_FOOD_GROUP = 'MOVE_FOOD_GROUP';
export const REMOVE_FOOD_GROUP = 'REMOVE_FOOD_GROUP';
export const MODIFY_FOOD_GROUP_AMOUNTS = 'MODIFY_FOOD_GROUP_AMOUNTS';
export const MOVE_FOODS_TO_GROUP = 'MOVE_FOODS_TO_GROUP';
export const ADD_FOOD = 'ADD_FOOD';
export const REMOVE_FOOD = 'REMOVE_FOOD';
export const SORT_FOOD = 'SORT_FOOD';
export const MOVE_FOOD_TO_PLATE = 'MOVE_FOOD_TO_PLATE';
export const SET_FOOD_AMOUNT = 'SET_FOOD_AMOUNT';
export const SET_FOOD_AS_DRINK = 'SET_FOOD_AS_DRINK';
export const ADD_FOOD_MODIFICATION = 'ADD_FOOD_MODIFICATION';
export const REMOVE_FOOD_MODIFICATION = 'REMOVE_FOOD_MODIFICATION';

export function addFoodGroup(data) {
    return { type: ADD_FOOD_GROUP, data }
}

export function setFoodGroupTitle(data) {
    return { type: SET_FOOD_GROUP_TITLE, data }
}

export function setFoodGroupDescription(data) {
    return { type: SET_FOOD_GROUP_DESCRIPTION, data }
}

export function deleteEmptyFoodGroups(data) {
    return { type: DELETE_EMPTY_FOOD_GROUPS, data }
}

export function changeFoodGroupType(data) {
    return { type: CHANGE_FOOD_GROUP_TYPE, data }
}

export function openFoodGroup(data) {
    return { type: OPEN_FOOD_GROUP, data }
}

export function closeFoodGroup(data) {
    return { type: CLOSE_FOOD_GROUP, data }
}

export function moveFoodGroup(data) {
    return { type: MOVE_FOOD_GROUP, data }
}

export function removeFoodGroup(data) {
    return { type: REMOVE_FOOD_GROUP, data }
}

export function modifyFoodGroupAmounts(data) {
    return { type: MODIFY_FOOD_GROUP_AMOUNTS, data }
}

export function addFood(data) {
    return { type: ADD_FOOD, data }
}

export function removeFood(id_to_remove) {
    return { type: REMOVE_FOOD, id_to_remove }
}

export function moveFoodsToGroup(data) {
    return { type: MOVE_FOODS_TO_GROUP, data }
}

export function sortFood(data) {
    return { type: SORT_FOOD, data }
}

export function moveFoodToPlate(data) {
    return { type: MOVE_FOOD_TO_PLATE, data }
}

export function setFoodAmount(data) {
    return { type: SET_FOOD_AMOUNT, data }
}

export function setFoodAsDrink(data) {
    return { type: SET_FOOD_AS_DRINK, data }
}

export function addFoodModification(data) {
    return { type: ADD_FOOD_MODIFICATION, data }
}

export function removeFoodModification(data) {
    return { type: REMOVE_FOOD_MODIFICATION, data }
}

// Tags

export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const SET_TAGS = 'SET_TAGS';

export function addTag(data) {
    return { type: ADD_TAG, data }
}

export function removeTag(data) {
    return { type: REMOVE_TAG, data }
}

export function setTags(data) {
    return { type: SET_TAGS, data }
}


// Generic

export const ASSIGN_STATE = 'ASSIGN_STATE';

export const LOAD_DAYPLAN = 'LOAD_DAYPLAN';
export const SET_PLANNER_EDIT_MODE = 'SET_PLANNER_EDIT_MODE';
export const SET_ACTIVE_TAB = ' SET_ACTIVE_TAB';
export const SET_PLANNER_VIEW_MODE = 'SET_PLANNER_VIEW_MODE';

export const SHOW_FOOD_DESCRIPTION = 'SHOW_FOOD_DESCRIPTION';

export const SET_EVALUATION_RESULTS = 'SET_EVALUATION_RESULTS';
export const SET_DAY_EVALUATION_RESULTS = 'SET_DAY_EVALUATION_RESULTS';
export const CLEAR_EVALUATION_RESULTS = 'CLEAR_EVALUATION_RESULTS';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';

export function assignState(data) {
    return { type: ASSIGN_STATE, data }
}

export function loadDayPlan(data) {
    return { type: LOAD_DAYPLAN, data }
}

export function setPlannerEditMode(data) {
    return { type: SET_PLANNER_EDIT_MODE, data }
}

export function setActiveTab(data) {
    return { type: SET_ACTIVE_TAB, data }
}

export function setPlannerViewMode(data) {
    return { type: SET_PLANNER_VIEW_MODE, data }
}

export function setEvaluationResults(data) {
    return { type: SET_EVALUATION_RESULTS, data }
}

export function clearEvaluationResults(data) {
    return { type: CLEAR_EVALUATION_RESULTS, data }
}

export function setDayEvaluationResults(data) {
    return { type: SET_DAY_EVALUATION_RESULTS, data }
}

export function openModal(data) {
    return { type: OPEN_MODAL, data }
}

export function closeModal(data) {
    return { type: CLOSE_MODAL, data }
}

export function setModalContent(data) {
    return { type: SET_MODAL_CONTENT, data }
}


// Meals

export const SET_ACTIVE_MEAL_HASH = 'SET_ACTIVE_MEAL_HASH';
export const SET_RECIPE_TITLE = 'SET_RECIPE_TITLE';
export const CLEAR_MEAL = 'CLEAR_MEAL';
export const ADD_MEAL = 'ADD_MEAL';
export const REMOVE_MEAL = 'REMOVE_MEAL';
export const UPDATE_MEAL = 'UPDATE_MEAL';
export const SET_MEAL_DESCRIPTION = 'SET_MEAL_DESCRIPTION';

export function setActiveMealHash(data) {
    return { type: SET_ACTIVE_MEAL_HASH, data }
}

export function setRecipeTitle(data) {
    return { type: SET_RECIPE_TITLE, data }
}

export function clearMeal(data) {
    return { type: CLEAR_MEAL, data }
}

export function addMeal(data) {
    return { type: ADD_MEAL, data }
}

export function removeMeal(data) {
    return { type: REMOVE_MEAL, data }
}

export function updateMeal(data) {
    return { type: UPDATE_MEAL, data }
}

export function setMealDescription(data) {
    return { type: SET_MEAL_DESCRIPTION, data }
}

// Day settings

export const COPY_DAY_CONTENTS = 'COPY_DAY_CONTENTS';
export const CLEAR_DAY_CONTENTS = 'CLEAR_DAY_CONTENTS';

export function copyDayContents(data) {
    return { type: COPY_DAY_CONTENTS, data }
}

export function clearDayContents(data) {
    return { type: CLEAR_DAY_CONTENTS, data }
}

// Energy output

export const SET_DAY_ENERGY_OUTPUT_COEFFICIENT = 'SET_DAY_ENERGY_OUTPUT_COEFFICIENT';
export const SET_DAY_ENERGY_OUTPUT_AMOUNT = 'SET_DAY_ENERGY_OUTPUT_AMOUNT';
export const SET_DAY_ENERGY_OUTPUT_AMOUNT_UNIT = 'SET_DAY_ENERGY_OUTPUT_AMOUNT_UNIT';

export function setDayEnergyOutputCoefficient(data) {
    return { type: SET_DAY_ENERGY_OUTPUT_COEFFICIENT, data }
}

export function setDayEnergyOutputAmount(data) {
    return { type: SET_DAY_ENERGY_OUTPUT_AMOUNT, data }
}

export function setDayEnergyOutputAmountUnit(data) {
    return { type: SET_DAY_ENERGY_OUTPUT_AMOUNT_UNIT, data }
}

// Session

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_ID = 'SET_PROFILE_ID';
export const SET_PLANNER_DATE = 'SET_PLANNER_DATE';

export function setProfileData(data) {
    return { type: SET_PROFILE_DATA, data }
}

export function setProfileId(data) {
    return { type: SET_PROFILE_ID, data }
}

export function setPlannerDate(data) {
    return { type: SET_PLANNER_DATE, data }
}

// Profi

export const PROFI_SET_CLIENT_ID = 'PROFI_SET_CLIENT_ID';

export function profiSetClientId(data) {
    return { type: PROFI_SET_CLIENT_ID, data }
}


export function profiSetClientIdCallback(data) {
    return (dispatch) => {
        dispatch(profiSetClientId(data.id));
        data.callback();
    }
}
