import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Util from 'utility';
import api from 'api';

import {
    setAppSubMode,
    setLectureTaskComplete,
    openModal,
    closeModal
} from 'actions/index';

import TaskEvaluator from './TaskEvaluator';
import EvaluationPanel from 'components/EvaluationPanel';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setAppSubMode: data => dispatch(setAppSubMode(data)),
        setLectureTaskComplete: data => dispatch(setLectureTaskComplete(data)),
        openModal: (data) => dispatch(openModal(data)),
        closeModal: (data) => dispatch(closeModal(data)),
    };
};

class LectureTaskPlan extends Component {

    constructor(props) {
        super(props);

        this.openLectureTaskSampleMenu = this.openLectureTaskSampleMenu.bind(this);
        this.showTaskListDDDInfoFunc = this.showTaskListDDDInfoFunc.bind(this);
    }

    openLectureTaskSampleMenu() {
        const sampleLectureTaskId = this.props.data.appModeData.lectureInfoData.lecture_data.sample_lecturetask_id;

        let id = sampleLectureTaskId;
        id = 8800000 + id * 7;      // TODO: this is shitty as fuck, at least make a has or something
                                    // (update in lecturetask/load API call too)

        this.props.setAppSubMode('LECTURETASK_SAMPLE');
        this.props.history.push('/load/task/' + id + '/' + this.props.data.appModeData.lectureId);
    }

    showTaskListDDDInfoFunc(info, id, type) {
        let content = [];
        let mealsParts = {};

        let data = this.props.data;

        if(type === 'category') {
            if(typeof info['categories'][id] !== 'undefined') {
                let tmp = {};
                info['categories'][id].map(f => {
                    const mk = f.meal + '|' + f.meal_id;
                    if(typeof tmp[mk] === 'undefined') tmp[mk] = {};
                    if(typeof tmp[mk][f.baseFood.title] === 'undefined') tmp[mk][f.baseFood.title] = [];                   
                    if(f.baseFood.title !== f.food.title) {
                        tmp[mk][f.baseFood.title].push(f.food.title);
                    }
                });

                console.log("1: ", data.globals.mealTypes);
                console.log("2: ", tmp);

                Object.keys(data.globals.mealTypes).map(m => {
                    const mt = data.globals.mealTypes[m].title;
                    const mk = mt + '|' + m;
                    if(typeof tmp[mk] !== 'undefined') {
                        content.push(<p><b>{mt}</b></p>);
                        let subresult = [];
                        Object.keys(tmp[mk]).map(fd => {
                            const subFoods = tmp[mk][fd];
                            if(subFoods.length > 0) {
                                subresult.push(<p>{fd}: {subFoods.join(', ')}</p>);
                            } else {
                                subresult.push(<p>{fd}</p>);
                            }
                        });
                        content.push(<div>{subresult}</div>);
                    }
                });

                content = <div className="lecturetask-foodslist">{content}</div>;
            }
        
            this.props.closeModal();
            this.props.openModal({
                title: 'Chody/potraviny obsahující "' + this.props.data.globals.foodCategories[id].title + '"',
                closeButton: "Zavřít",
                class: 'dialog-flexible',
                content: content
            });
        }


        if(type === 'tag') {

            if(typeof info['tags'][id] !== 'undefined') {
                let tmp = {};
                info['tags'][id].map(f => {
                    const mk = f.meal + '|' + f.meal_id;
                    if(typeof tmp[mk] === 'undefined') tmp[mk] = {};
                    if(typeof tmp[mk][f.baseFood.title] === 'undefined') tmp[mk][f.baseFood.title] = [];                   
                    if(f.baseFood.title !== f.food.title) {
                        tmp[mk][f.baseFood.title].push(f.food.title);
                    }
                });

                Object.keys(data.globals.mealTypes).map(m => {
                    const mt = data.globals.mealTypes[m].title;
                    const mk = mt + '|' + m;
                    if(typeof tmp[mk] !== 'undefined') {
                        content.push(<p><b>{mt}</b></p>);
                        let subresult = [];
                        Object.keys(tmp[mk]).map(fd => {
                            const subFoods = tmp[mk][fd];
                            if(subFoods.length > 0) {
                                subresult.push(<p>{fd}: {subFoods.join(', ')}</p>);
                            } else {
                                subresult.push(<p>{fd}</p>);
                            }
                        });
                        content.push(<div>{subresult}</div>);
                    }
                });

                content = <div className="lecturetask-foodslist">{content}</div>;
            }
        
            this.props.closeModal();
            this.props.openModal({
                title: 'Chody/potraviny obsahujicí "' + this.props.data.globals.foodTags[id] + '"',
                closeButton: "Zavřít",
                class: 'dialog-flexible',
                content: content
            });
        }
        
    }

    render() {
        const { data } = this.props;
        const { globals } = data;
        const taskData = data.lectureTaskData;

        let evaluationPanelBeforeContent = false;

        let profileId = Util.getActiveProfileId();
        if(typeof data.appModeData.profileId === 'number') {
            profileId = data.appModeData.profileId;
        }

        let evalResults = false;
        let mealsResults = false;
        if(typeof data.evaluationResults === 'object') {
            evalResults = data.evaluationResults.results;
            mealsResults = data.mealsEvaluationResults;
        }

        let tasksList = [];
        let taskCompletedInfo = false;

        if(typeof taskData.lecture_data === 'object') {

            const evaluator = new TaskEvaluator({
                'dddInfo': this.showTaskListDDDInfoFunc
            });
            const res = evaluator.evaluate({lectureInfo: this.props.lectureInfo});

            let taskListMeals = res.taskListMeals;
            let taskListMealsOk = res.taskListMealsOk;
            let taskListUndesirableFoodsUsed = res.taskListUndesirableFoodsUsed;
            let taskListNumberOfFoods = res.taskListNumberOfFoods;
            let taskListNumberOfFoodsOk = res.taskListNumberOfFoodsOk;
            let taskListDDD = res.taskListDDD;
            let taskListDDDOk = res.taskListDDDOk;
            let taskListDDDInfo = res.taskListDDDInfo;

            if(taskListMeals.length > 0 || taskListMealsOk.length > 0) {

                let box = (
                    <div className="lecturetask-eval-box">
                        <div class="lt-heading">
                            <h3>Chody / chuť</h3>
                            <label htmlFor="lt-cb1">?</label>
                            <input type="checkbox" id="lt-cb1" />
                            <div class="lt-help">
                                <p>Vyvořte u každého chodu recept/y odpovídající zadané chuti.</p>
                            </div>
                        </div>
                        {taskListMeals}
                        {taskListMealsOk}
                    </div>
                );

                tasksList.push(box);
            }

            if(taskListNumberOfFoods.length > 0 || taskListNumberOfFoodsOk.length > 0) {

                let box = (
                    <div className="lecturetask-eval-box">
                        <div class="lt-heading">
                            <h3>Pestrost</h3>
                            <label htmlFor="lt-cb2">?</label>
                            <input type="checkbox" id="lt-cb2" />
                            <div class="lt-help">
                                <p>Vámi vytvořený jídelníček musí obsahovat požadované počty zástupců potravin z jednotlivých kategorií.</p>
                            </div>
                        </div>
                        {taskListNumberOfFoods}
                        {taskListUndesirableFoodsUsed}
                        {taskListNumberOfFoodsOk}
                    </div>
                );

                tasksList.push(box);
            }


            if(taskListDDD.length > 0 || taskListDDDOk.length > 0) {

                let box = (
                    <div className="lecturetask-eval-box">
                        <div class="lt-heading">
                            <h3>Nutriční hodnoty</h3>
                            <label htmlFor="lt-cb3">?</label>
                            <input type="checkbox" id="lt-cb3" />
                            <div class="lt-help">
                                <p>Vámi vytvořený jídelníček musí splňovat požadované nutriční hodnoty.</p>
                            </div>
                        </div>
                        {taskListDDD}
                        {taskListDDDOk}
                    </div>
                );

                tasksList.push(box);
            }

            // Is task completed?

            const isCurrentlyCompleted = this.props.data.lectureTaskComplete;

            if(
                taskListMeals.length === 0 && 
                taskListNumberOfFoods.length === 0 && 
                taskListDDD.length === 0 &&
                taskListUndesirableFoodsUsed.length === 0
                ) {
                if(isCurrentlyCompleted !== true) {
                    this.props.setLectureTaskComplete(true);
                }
            } else {
                if(isCurrentlyCompleted !== false) {
                    this.props.setLectureTaskComplete(false);
                }
            }

            // let completedInfoText = <p style={{margin:0}}>Nezapomeňte jídelníček <b>uložit</b>!</p>;
            //if(true || this.props.data.lectureTaskCompleteSaved) {
            let completedInfoText = (
                <>
                <p style={{fontWeight:'bold',color:'#f00',margin:'-.25em 0 1.5em 0'}}>Nezapomeňte úkol uložit<br />tlačítkem "Uložit úkol"!</p>
                <p><a style={{background:'#fff',color:'#000',padding:'.5em 1em',textDecoration:'none',fontWeight:'bold',borderRadius:'5px'}} onClick={() => this.openLectureTaskSampleMenu()}>Otevřít vzorový jídelníček</a></p>
                </>
            );
            //}


            if(this.props.data.appSubMode !== 'LECTURETASK_SAMPLE') {
                if(isCurrentlyCompleted) {
                    taskCompletedInfo = (
                        <div className="lecturetask-complete">
                            <p><i className="fa fa-check"></i></p>
                            <p class="cg"><b>Výborně! Váš úkol je splněn.</b></p>
                            {completedInfoText}
                        </div>
                    );
                }
            }

            // Watch ZOF attributs ("Sledujte" pri zadavani ukolu u lekce)

            let watchAttribs = [];
            let graph = false;

            let showRI = [
                'potassium', 'calcium', 'magnesium', 'sodium', 'iodine', 'selenium', 'zinc', 'iron', 'iron_hem', 'phosphorus', 'zinc',
                'vitamin_c', 'vitamin_d', 'vitamin_e', 'vitamin_d2', 'vitamin_d3', 'vitamin_a', 'vitamin_b1', 'vitamin_b2', 'vitamin_b3',
                'vitamin_b5', 'vitamin_b6', 'vitamin_b9', 'vitamin_b12', 'vitamin_k'
            ];

            // Show ratio, if both of relevant values are shown before (in "Nutricni hodnoty" part)

            let showRatios = {
                'proteins_ratio': {
                    'after': 'proteins_plant',
                    'depends_on': ['proteins_animal', 'proteins_plant'],
                },
                'fats_saturated_unsaturated_ratio': {
                    'after': 'fats_unsaturated_total',
                    'depends_on': ['fats_saturated', 'fats_unsaturated_total'],
                },
                'fats_monounsaturated_polyunsaturated_ratio': {
                    'after': 'fats_polyunsaturated',
                    'depends_on': ['fats_monounsaturated', 'fats_polyunsaturated'],
                },
                'fats_omega3_omega6_ratio': {
                    'after': 'fats_omega6',
                    'depends_on': ['fats_omega3', 'fats_omega6'],
                },
                'fats_omega3_dha_epa_omega6_ratio': {
                    'after': 'fats_omega6',
                    'depends_on': ['fats_omega3_dha_epa', 'fats_omega6'],
                },
                'fiber_ratio': {
                    'after': 'fiber_soluble',
                    'depends_on': ['fiber_soluble', 'fiber_insoluble'],
                },
                'calcium_magnesium_ratio': {
                    'after': 'magnesium',
                    'depends_on': ['calcium', 'magnesium'],
                },
                'potassium_sodium_ratio': {
                    'after': 'sodium',
                    'depends_on': ['potassium', 'sodium'],
                },
                'calcium_phosphorus_ratio': {
                    'after': 'phosphorus',
                    'depends_on': ['calcium', 'phosphorus'],
                },
            };

            // First check which ratios should be filled

            if(typeof evalResults !== 'undefined') {
                if(Array.isArray(Object.keys(taskData.ddd_limits))) {
                    Object.keys(taskData.ddd_limits).map(attr => {
                        if(attr == '') return;
                        if(attr === 'fats_unsaturated') attr = 'fats_unsaturated_total';
                        Object.keys(showRatios).map(r => {
                            if(showRatios[r].depends_on.includes(attr)) {
                                showRatios[r].depends_on = showRatios[r].depends_on.filter(a => a !== attr);
                            }
                        });
                    });
                }
            }

            // Fill the watchAttribs array

            let watchAttribsAlreadyFilled = [];

            if(typeof data.evaluationResults !== 'undefined') {
                if(typeof data.evaluationResults.results !== 'undefined') {

                    const thisMealIsEmpty = data.evaluationResults.results.weight === 0 && data.evaluationResults.results.energy_kcal === 0;

                    Object.keys(data.evaluationResults.results).map(attr => {
                    //data.evaluationResults.results.map(attr => {
                        if(attr == '') return;
                        if(attr === 'fats_unsaturated') attr = 'fats_unsaturated_total';
                        if(attr === 'energy_balance_kcal') attr = 'kcal_kj';

                        if(typeof data.appModeData.watchAttributes === 'object') {
                            if(data.appModeData.watchAttributes.includes(attr)) {

                                if(attr == 'kcal_kj') {
                                    let kcal = data.evaluationResults.results['energy_balance_kcal'] + '';
                                    let kj = data.evaluationResults.results['energy_balance_kj'] + '';
                                    if(kcal > 0 && kcal.substr(0, 1) !== '+') kcal = '+' + kcal;
                                    if(kj > 0 && kj.substr(0, 1) !== '+') kj = '+' + kj;

                                    watchAttribs.push(
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>Množství energie:</p>
                                            <aside>
                                                {data.evaluationResults.results['energy_kcal'] * 1}
                                                {'\u00A0'}
                                                kcal
                                            </aside>
                                        </div>
                                        </>
                                    );
                                    watchAttribs.push(
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>Množství energie:</p>
                                            <aside>
                                                {data.evaluationResults.results['energy_kj']}
                                                {'\u00A0'}
                                                kJ
                                            </aside>
                                        </div>
                                        </>
                                    );

                                    watchAttribs.push(
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes['energy_balance_kcal']}:</p>
                                            <aside>
                                                {kcal}
                                                {'\u00A0'}
                                                {data.globals.attribute_units['energy_balance_kcal']}
                                            </aside>
                                        </div>
                                        </>
                                    );
                                    watchAttribs.push(
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes['energy_balance_kj']}:</p>
                                            <aside>
                                                {kj}
                                                {'\u00A0'}
                                                {data.globals.attribute_units['energy_balance_kj']}
                                            </aside>
                                        </div>
                                        </>
                                    );

                                } else {

                                    let resval, textEval;

                                    switch(attr) {
                                        
                                        case 'gi':

                                            resval = data.evaluationResults.results[attr]; 
                                            textEval = 'nízký';
                                            if(resval > 50) textEval = 'střední';
                                            if(resval > 70) textEval = 'vysoký';

                                            if(thisMealIsEmpty) textEval = '-';

                                            watchAttribs.push(
                                                <div className="watch-attribute-row">
                                                    <p>{data.globals.attributes[attr]}:</p>
                                                    <aside>
                                                        {textEval}
                                                    </aside>
                                                </div>
                                            );
                                            watchAttribsAlreadyFilled.push(attr);
                                            break;

                                        case 'gn':

                                            // Toto je celodenni vyhodnoceni 
                                            // tj. limity pro GN jsou jine nez pro mod jednoho receptu

                                            resval = data.evaluationResults.results[attr]; 
                                            textEval = 'nízká';
                                            if(resval > 80) textEval = 'střední';
                                            if(resval > 119) textEval = 'vysoká';

                                            if(thisMealIsEmpty) textEval = '-';

                                            watchAttribs.push(
                                                <div className="watch-attribute-row">
                                                    <p>{data.globals.attributes[attr]}:</p>
                                                    <aside>
                                                        {textEval}
                                                    </aside>
                                                </div>
                                            );
                                            watchAttribsAlreadyFilled.push(attr);
                                            break;

                                        default:


                                            if(['acidobasicity','digestibility','energy','thermiceffect'].includes(attr)) {

                                                let attrFinal = attr;
                                                if(attrFinal === 'energy') attrFinal = 'energytendency';

                                                let opts = data.globals.evaluationTexts[attrFinal];

                                                let text = opts[Math.round(data.evaluationResults.results[attr + '_n'])];
                                            
                                                if(thisMealIsEmpty) text = '-';


                                                watchAttribs.push(
                                                    <div className="watch-attribute-row">
                                                        <p>{data.globals.attributes[attr]}:</p>
                                                        <aside>
                                                            {text}
                                                            {'\u00A0'}
                                                            {data.globals.attribute_units[attr]}
                                                        </aside>
                                                    </div>
                                                );
                                                watchAttribsAlreadyFilled.push(attr);

                                            } else {

                                                if(showRI.includes(attr)) {
                                                    let RIpct = data.evaluationResults.ddd[attr].percent;
                                                    watchAttribs.push(
                                                        <div className="watch-attribute-row">
                                                            <p>{data.globals.attributes[attr]}:</p>
                                                            <aside>
                                                                {RIpct}
                                                                {'\u00A0'}
                                                                % RI
                                                            </aside>
                                                        </div>
                                                    );
                                                    watchAttribsAlreadyFilled.push(attr);

                                                } else {

                                                    watchAttribs.push(
                                                        <div className="watch-attribute-row">
                                                            <p>{data.globals.attributes[attr]}:</p>
                                                            <aside>
                                                                {data.evaluationResults.results[attr]}
                                                                {'\u00A0'}
                                                                {data.globals.attribute_units[attr]}
                                                            </aside>
                                                        </div>
                                                    );
                                                    watchAttribsAlreadyFilled.push(attr);
                
                                                    if(attr == 'added_sugars') {
                                                        let sugarCubes = data.evaluationResults.results[attr] / 4;
                                                        sugarCubes = Math.ceil(sugarCubes * 10) / 10;
                                                        watchAttribs.push(
                                                            <div className="watch-attribute-row">
                                                                <p>- odpovídá kostkám cukru:</p>
                                                                <aside>
                                                                    {sugarCubes}
                                                                </aside>
                                                            </div>
                                                        );
                                                    }

                                                }

                                            }
                                            break;
                                    }

                                }
                            }
                        }

                        Object.keys(showRatios).map(r => {
                            if(data.appModeData.watchAttributes.includes(r)) return;
                            if(showRatios[r].after === attr && showRatios[r].depends_on.length === 0) {
                                watchAttribs.push(
                                    <>
                                    <div className="watch-attribute-row">
                                        <p>{data.globals.attributes[r]}:</p>
                                        <aside>
                                            {data.evaluationResults.results[r]}
                                        </aside>
                                    </div>
                                    </>
                                );
                                watchAttribsAlreadyFilled.push(r);
                            }
                        });

                    });

                    // Energy ratio chart

                    if(watchAttribs.length > 0) {

                        let results = data.evaluationResults.results;
                        let chartColours = ["#96c999", "#eb714a", "#eae869", "#8bcbce", "#c694be"];

                        let enProt = results["proteins"] * 4.18;
                        let enCarb = results["carbohydrates"] * 4.18;
                        let enFats = results["fats"] * 9;
                        let enFibr = results["fiber"] * 2;

                        let total = enProt + enCarb + enFats + enFibr;
                        if (total == 0) total = 0.00001;

                        enProt = Math.round((enProt / total) * 100);
                        enCarb = Math.round((enCarb / total) * 100);
                        enFats = Math.round((enFats / total) * 100);
                        enFibr = 100 - enProt - enCarb - enFats; //(enFibr / total) * 100;

                        let chart2 = Util.pieChart(
                            [enProt, enCarb, enFats, enFibr],
                            100,
                            100,
                            95,
                            chartColours
                        );

                        graph = (
                            <>
                            <div className="lecturetask-eval-box">
                                <div class="lt-heading">
                                    <h3>Energetické poměry makroživin</h3>
                                    <label htmlFor="lt-cb5">?</label>
                                    <input type="checkbox" id="lt-cb5" />
                                    <div class="lt-help">
                                        <p>Ve vytvořeném jídelníčku sledujte poměry makroživin hodnocení v jejich procentuálním podílu z celkového příjmu energie.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="eval-item">
                            <div className="svg-wrap">
                                <svg viewBox="0 0 400 200" width="100%" height="100%">
                                {chart2}
                                <rect
                                    x="220"
                                    y="40"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[0], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="55" fill="#444">
                                    Bílkoviny ({parseFloat(enProt)} %)
                                </text>
                                <rect
                                    x="220"
                                    y="70"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[1], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="85" fill="#444">
                                    Sacharidy ({parseFloat(enCarb)} %)
                                </text>
                                <rect
                                    x="220"
                                    y="100"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[2], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="115" fill="#444">
                                    Tuky ({parseFloat(enFats)} %)
                                </text>
                                <rect
                                    x="220"
                                    y="130"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[3], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="145" fill="#444">
                                    Vláknina ({parseFloat(enFibr)} %)
                                </text>
                                </svg>
                            </div>
                            </div>
                            </>
                        );
                    }
                }
            }

            evaluationPanelBeforeContent = (
                <>
                {taskCompletedInfo}
                <div style={{padding:'1rem',fontSize:'12px'}}>
                    <div className="lecturetask-taskslist">{tasksList}</div>
                </div>
                </>
            );

            if(watchAttribs.length > 0) {
                evaluationPanelBeforeContent = (
                    <>
                    {taskCompletedInfo}
                    <div style={{padding:'1rem',fontSize:'12px'}}>
                        <div className="lecturetask-taskslist">{tasksList}</div>
                        <div className="lecturetask-eval-box">
                            <div class="lt-heading">
                                <h3>Sledujte</h3>
                                <label htmlFor="lt-cb4">?</label>
                                <input type="checkbox" id="lt-cb4" />
                                <div class="lt-help">
                                    <p>Ve vytvořeném jídelníčku sledujte vybrané nutriční hodnoty. Tyto slouží jako informační doplněk k hodnocení jeho celkové kvality.</p>
                                </div>
                            </div>
                            {watchAttribs}
                            {graph}
                        </div>
                    </div>
                    </>
                );

            }
        }

        let profileInfoContent = 'Cílový profil: Váš profil';
        if(typeof data.appModeData.profileId === 'number') {
            let fpid = data.appModeData.profileId;
            profileInfoContent = 'Cílový profil: ' + globals.profiles[fpid].name;
        }


        //

        let lectureTaskSampleTextButton = false;
        let topNavButtons = (
            <>
                <a onClick={this.props.openSaveLectureTaskPanel}><i className="fa fa-file-import"></i> Uložit úkol</a>
                <a onClick={this.props.openLoadLectureTaskPanel}><i className="fa fa-file-export"></i> Načíst úkol</a>
            </>
        );

        if(this.props.data.appSubMode == 'LECTURETASK_SAMPLE') {
            topNavButtons = false;
            lectureTaskSampleTextButton = <a style={{background:'#fa4'}} onClick={this.props.openLectureTaskSampleText}><i className="fa fa-user-circle"></i> Komentář k vzorovému jídelníčku</a>
        }

//                                     <a onClick={this.props.openPanelEnergyOutput}><i className="fa fa-heartbeat"></i> Výdej energie</a>


        let content = (
            <div className="page page-bg page-plan">
                <div className="page-header" style={{padding:'.5em 0'}}>
                    <div className="container">
                        <div className="page-header-row">
                            <div className="header-group">
                                <h1>ÚKOL K LEKCI</h1>
                                <div className="header-group-buttons">
                                    {lectureTaskSampleTextButton}
                                    <a onClick={this.props.openProfileInfo}><i className="fa fa-user-circle"></i> Zadání, profil</a>
                                    {topNavButtons}
                                </div>
                            </div>
                            <aside>
                                <p style={{textAlign:'right',margin:'0'}}><b>{this.props.lectureInfo.course_title}</b><br />Lekce: {this.props.lectureInfo.title}</p>
                                <p style={{textAlign:'right',margin:'4px 0 0',fontSize:'.8em'}}>{profileInfoContent}</p>
                            </aside>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container" style={{paddingTop:'1em',paddingBottom:'1em'}}>
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-side" />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-main" defaultChecked={true} />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-evaluation" />
                        <div className="plan-mobile-nav">
                            <label htmlFor="cb-plan-side"><i className="fa fa-angle-down"></i> Kalendář</label>
                            <label htmlFor="cb-plan-main"><i className="fa fa-angle-down"></i> Rozpis</label>
                            <label htmlFor="cb-plan-evaluation"><i className="fa fa-angle-down"></i> Vyhodnocení</label>
                        </div>
                        <div className="plan">
                            <div className="plan-row">
                                <input checked="checked" className="radio-plan-mobile-nav" type="checkbox" id="cb-panel-evaluation" />
                                <div className="plan-main">
                                    <div className="planner-hours">
                                        <div className="planner-top-nav">
                                            {this.props.viewTypeSwitch2}
                                        </div>
                                        {this.props.plan}
                                    </div>
                                </div>
                                <EvaluationPanel 
                                    beforeContent={evaluationPanelBeforeContent} 
                                    wholeDay={true} 
                                    quickResults={false}
                                    evaluationCollapsedAtStart={true}
                                    onRecommendedFoodAdded={this.onRecommendedFoodAdded} 
                                    mainStyle={{background:'#e6eef2',border:'1px solid #bdc4c7',borderRadius:'4px'}}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LectureTaskPlan));
