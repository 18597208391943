import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import https from "https";
import Util from "utility";
import { loginUser } from "actions/index";

const mapStateToProps = (state) => {
    return { loading: state.loading };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (data) => dispatch(loginUser(data)),
    };
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            remember: false,
            errors: [],
        };
        this.onChange = this.onChange.bind(this);
        this.doLogin = this.doLogin.bind(this);
    }

    componentDidMount() {
        Util.setPageTitle("Přihlášení");
    }

    onChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === "remember") value = e.target.checked;

        this.setState({
            [name]: value,
        });
    };

    doLogin = async (e) => {
        e.preventDefault();

        const { username, password } = this.state;
        let errors = [];
        if (username === "") {
            errors.push("username");
        }
        if (password === "") {
            errors.push("password");
        }
        this.setState({
            errors: errors,
        });
        if (errors.length > 0) return false;

        const instance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
        });
        let result = await instance.get(
            "https://www.zofapp.cz/api2/login?username=" +
                encodeURIComponent(username) +
                "&password=" +
                encodeURIComponent(password)
        );
        if (typeof result.data !== "undefined" && result.data.success === true) {
            localStorage.setItem("jwt_token", result.data.data.auth_token);
            this.props.history.push("/");
        } else {
            errors.push("login_error");
            this.setState({
                errors: errors,
            });
        }
    };

    render() {
        const errors = this.state.errors;
        return (
            <div className="login">
                <div className="form">
                    <svg xmlns="http://www.w3.org/2000/svg" width="241" height="66.9" viewBox="0 0 63.8 17.7">
                        <g>
                            <path
                                d="M11.2 14.2H0v-1.8l7.2-9.6H.5V.2h10.7V2l-7 9.6h7zM18.3 14.4q-3 0-5-2-1.8-1.9-1.8-5 0-3.3 2-5.4 1.8-2 5-2 3 0 4.8 2 1.9 2 1.9 5.1 0 3.3-2 5.3-1.9 2-5 2zm.1-11.7q-1.7 0-2.6 1.2-1 1.3-1 3.3 0 2.1 1 3.3 1 1.2 2.5 1.2 1.6 0 2.6-1.2 1-1.1 1-3.2 0-2.2-1-3.4-.9-1.2-2.5-1.2zM34.6 2.8h-5v3.4h4.6v2.6h-4.5v5.4h-3.2V.2h8z"
                                fill="#333"
                            />
                            <path
                                d="M41.9 14.2v-2q-.5 1-1.4 1.6-1 .6-2 .6-1.5 0-2.3-.8-.8-.8-.8-2 0-2.6 3.4-3.1L42 8q0-3.2-2.4-3.2-1.7 0-3.2 1.3v-1q.5-.5 1.5-.8 1-.3 1.8-.3 1.5 0 2.3 1 .9.9.9 2.7v6.5zm-2.7-5q-1.6.2-2.2.8-.7.4-.7 1.6 0 .9.6 1.4.6.6 1.7.6 1.4 0 2.3-1 1-1.1 1-2.8v-1zM45.8 12.4v5.3h-1V4.2h1v2a4 4 0 011.5-1.6c.7-.4 1.4-.6 2.2-.6 1.3 0 2.3.4 3 1.3.7.9 1 2 1 3.5 0 1.7-.4 3-1.2 4-.8 1.1-1.9 1.6-3.2 1.6-1.5 0-2.6-.6-3.3-2zm0-3.7V10c0 1 .3 1.8.9 2.5.6.8 1.5 1.1 2.5 1.1a3 3 0 002.5-1.3c.6-.9 1-2 1-3.4 0-1.3-.4-2.3-1-3a2.8 2.8 0 00-2.3-1.1c-1.2 0-2 .4-2.7 1.2-.6.7-1 1.7-1 2.7zM56 12.4v5.3h-1V4.2h1v2a4 4 0 011.6-1.6c.6-.4 1.4-.6 2.2-.6 1.2 0 2.2.4 3 1.3.6.9 1 2 1 3.5 0 1.7-.4 3-1.2 4-.9 1.1-2 1.6-3.3 1.6-1.5 0-2.6-.6-3.3-2zm0-3.7V10c0 1 .3 1.8 1 2.5.6.8 1.4 1.1 2.4 1.1a3 3 0 002.5-1.3c.6-.9 1-2 1-3.4 0-1.3-.3-2.3-1-3a2.8 2.8 0 00-2.3-1.1c-1.1 0-2 .4-2.7 1.2-.6.7-1 1.7-1 2.7z"
                                fill="#4eb32d"
                            />
                        </g>
                    </svg>
                    {errors.includes("login_error") ? (
                        <p className="login-error">Neplatné přihlašovací údaje.</p>
                    ) : (
                        false
                    )}
                    <form onSubmit={this.doLogin}>
                        <div className="form-row">
                            <label className={errors.includes("username") ? "error" : ""}>
                                <span>E-mail</span>
                                <input type="text" name="username" onChange={this.onChange} />
                            </label>
                        </div>
                        <div className="form-row">
                            <label className={errors.includes("password") ? "error" : ""}>
                                <span>Heslo</span>
                                <input type="password" name="password" onChange={this.onChange} />
                            </label>
                        </div>
                        <div className="form-row" style={{ display: "none" }}>
                            <label>
                                <span></span>
                                <input type="checkbox" name="remember" onChange={this.onChange} />
                                <em>&#x2713;</em> zapamatovat na tomto počítači
                            </label>
                        </div>
                        <div className="form-row">
                            <label>
                                <button type="submit">Přihlásit</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
