import React, { Component } from 'react';
import { connect } from 'react-redux';
import Util from 'utility';

import { 
    setFoodGroupDescription,
} from 'actions/index';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setFoodGroupDescription: data => dispatch(setFoodGroupDescription(data)),
    };
};

class PanelRecipeSettings extends Component { 
    constructor(props) {
        super(props);

        const mealHash = props.data.activeMealHash;
        let firstFoodGroupId = false;
        let firstFoodGroupDescription = '';
        for(let g in props.data.foodGroups[mealHash]) {
            if(!firstFoodGroupId) {
                firstFoodGroupId = g;
                firstFoodGroupDescription = props.data.foodGroups[mealHash][g].description;
            }
        }

        this.state = {
            selectedFoodGroupId: firstFoodGroupId,
        };    
        this.selectFoodGroup = this.selectFoodGroup.bind(this);
        this.updateFoodGroupDescription = this.updateFoodGroupDescription.bind(this);
    }

    selectFoodGroup(e) {
        const fgId = e.target.value;
        this.setState({
            selectedFoodGroupId: fgId
        });
    }

    updateFoodGroupDescription(e) {
        this.props.setFoodGroupDescription({
            id: this.state.selectedFoodGroupId,
            description: e.target.value
        });
        Util.saveCurrentRecipeLocal();
    }

    render() {
        const { state } = this;
        const { data } = this.props;
        const { globals } = this.props.data;

        let foodGroupsOptions = [];
        for(let g in data.foodGroups[data.activeMealHash]) {
            const group = data.foodGroups[data.activeMealHash][g];
            if(group.title == '') group.title = '- beze jména -';

            const groupTypeTitle = globals.foodGroupTypes[group.type];

            foodGroupsOptions.push(
                <option value={g} key={g}>{groupTypeTitle}: {group.title}</option>
            );
        }

        let currentFoodGroupDescription = '';
        if(typeof data.foodGroups[data.activeMealHash] !== 'undefined') {
            if(typeof data.foodGroups[data.activeMealHash][state.selectedFoodGroupId] !== 'undefined') {
                currentFoodGroupDescription = data.foodGroups[data.activeMealHash][state.selectedFoodGroupId].description;
            }
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                    <div className="input-row">
                            <span style={{flexBasis:'10em'}}>Vyberte chod:</span>
                            <div className="inputs">
                                <select onChange={this.selectFoodGroup} defaultValue={this.state.selectedFoodGroupId}>{foodGroupsOptions}</select>
                            </div>
                        </div>
                        <div className="input-row">
                            <span style={{flexBasis:'10em'}}>Postup přípravy:</span>
                            <div className="inputs">
                                <textarea onChange={this.updateFoodGroupDescription} style={{ height: '9em' }} value={currentFoodGroupDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelRecipeSettings);
